import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Concept, { ConceptDto } from './Concept';

export enum EncounterDiagnosticStatusCode {
	PENDING = 'P',
	COMPLETE = 'C',
}

export enum EncounterDiagnosticStatusCodeUU {
	Pending = '5d4d92c0-64a0-4552-aec7-0b73f3f4c189',
	Complete = '6c5382b6-6f12-4115-bcea-68a573400296',
}

export interface EncounterDiagnosticDB extends BaseMetadataDB {}

export interface EncounterDiagnosticDto extends BaseMetadataDto {
	concept?: ConceptDto | null;
	status?: string;
	value?: string;
	lineNo?: number;
}

export default class EncounterDiagnostic extends BaseMetadata {
	concept?: Concept;
	status: string;
	value: string;
	lineNo: number;

	constructor(props?: Partial<EncounterDiagnostic | EncounterDiagnosticDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.concept = props.concept ? new Concept(props.concept) : undefined;
		this.status = props.status || '';
		this.value = props.value || '';
		this.lineNo = props.lineNo || 0;
	}
}
