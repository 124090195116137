import { Fragment } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	Ad_Ref_ListPatientTypesForVisitsQuery,
	Ad_Ref_ListReferralsForVisitsQuery,
	Ad_UserForVisitCliniciansQuery,
} from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';
import { VisitFormValues } from './VisitForm';

type VisitDetailsReviewProps = {
	clinicians?: Ad_UserForVisitCliniciansQuery['AD_UserGet']['Results'];
	patientTypes?: Ad_Ref_ListPatientTypesForVisitsQuery['AD_Ref_ListGet']['Results'];
	referrals?: Ad_Ref_ListReferralsForVisitsQuery['AD_Ref_ListGet']['Results'];
	loading: boolean;
};

const VisitDetailsReview = ({ clinicians, patientTypes, referrals, loading }: VisitDetailsReviewProps) => {
	const { t } = useTranslation();
	const { register, getValues } = useFormContext<VisitFormValues>();

	const visitUuid = getValues('UU');

	return (
		<fieldset disabled={true}>
			<Card className="bh-card">
				<Card.Header className="d-flex">
					<div className="fw-bold h5">{t(uiText.visit.DETAILS)}</div>
					{loading && (
						<div className="ms-auto fw-light">
							<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
							<Spinner animation="border" size="sm" className="align-middle" role="status" />
						</div>
					)}
				</Card.Header>
				<Card.Body>
					<Row className="gy-3">
						<Form.Group as={Fragment} controlId={`visitDate${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.DATE)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Controller<VisitFormValues, 'BH_VisitDate'>
									name="BH_VisitDate"
									render={({ field }) => (
										<DatePicker
											wrapperClassName="w-100"
											className="form-control"
											dateFormat="yyyy-MM-dd h:mm aa"
											{...field}
											value={undefined}
											selected={field.value}
											timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
											showTimeInput
										/>
									)}
								/>
							</Col>
						</Form.Group>
						<Col xs={4} />
						<Form.Group as={Fragment} controlId={`txReferral${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.patient.REFERRAL)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Select {...register('bh_referral.UU')}>
									<option>&nbsp;</option>
									{referrals?.map((referral) => (
										<option key={referral.UU} value={referral.UU}>
											{referral.Name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Form.Group>

						<Form.Group as={Fragment} controlId={`txPatientType${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.patient.TYPE)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Select {...register('BH_PatientType.UU')}>
									<option>&nbsp;</option>
									{patientTypes?.map((patientType) => (
										<option key={patientType.UU} value={patientType.UU}>
											{patientType.Name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Form.Group>
						<Form.Group as={Fragment} controlId={`txClinician${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.CLINICIAN)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Select {...register('BH_Clinician_User.UU')}>
									<option>&nbsp;</option>
									{clinicians?.map((clinician) => (
										<option key={clinician.UU} value={clinician.UU}>
											{clinician.Name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Form.Group>
						<Form.Group as={Fragment} controlId={`referredFromTo${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.patient.REFERRED_FROM_TO)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Control {...register('BH_ReferredFromTo')} />
							</Col>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</fieldset>
	);
};

export default VisitDetailsReview;
