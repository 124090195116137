import { ApolloClient, useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { Button, Card, Row, Spinner } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_EncounterInput,
	Bh_Encounter_DiagnosisInput,
	Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
	Bh_ObservationInput,
	Bh_VisitForEditingQuery,
	C_BPartnerForVisitsDocument,
} from '../../graphql/__generated__/graphql';
import { EncounterTypeWindowUU, fieldUuid } from '../../models';
import { uiText } from '../../utils/Language';
import DiagnosisTable, {
	constructDiagnosisTableFormDataToSave,
	convertToDiagnosisTableFormValues,
	DiagnosisTableFormValues,
} from './DiagnosisTable';
import ObservationFormGroup from './ObservationFormGroup';
import { VisitFormValues } from './VisitForm';
import VisitHistory, { primeVisitHistoryData } from './VisitHistory';

export type ClinicalDetailsFormValues = {
	clinicalDetailsEncounter: {
		UU: string;
		BH_Encounter_Date: Date;
		BH_Encounter_Type: { UU: string };
		BH_Observations: Array<{ UU: string; AD_Field: { UU: string }; BH_Value: string | null }>;
	};
} & DiagnosisTableFormValues;

export const convertToClinicalDetailsFormValues: (
	graphqlClient: ApolloClient<object>,
	t: TFunction<'translation', undefined>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
) => ClinicalDetailsFormValues = (graphqlClient, t, initialData) => {
	const clinicalDetailsEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.ClinicialDetails,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	});
	// If the user doesn't have access to this window (and the encounter type hasn't loaded), just return
	if (!clinicalDetailsEncounterTypeWindow) {
		return {
			clinicalDetailsEncounter: {
				UU: '',
				BH_Encounter_Date: new Date(),
				BH_Encounter_Type: { UU: '' },
				BH_Observations: [],
			},
			...convertToDiagnosisTableFormValues(graphqlClient, initialData),
		};
	}
	const sortedFields = sortBy(clinicalDetailsEncounterTypeWindow.AD_Window.AD_Tabs?.[0].AD_Fields || [], 'SeqNo');
	const emptyLabDiagnosticsEncounter: ClinicalDetailsFormValues['clinicalDetailsEncounter'] = {
		UU: v4(),
		BH_Encounter_Date: new Date(),
		BH_Encounter_Type: { UU: clinicalDetailsEncounterTypeWindow.BH_Encounter_Type.UU },
		BH_Observations:
			sortedFields.map((field) => ({
				UU: v4(),
				AD_Field: { UU: field.UU },
				BH_Value: field.UU === fieldUuid.CLINICAL_NOTES ? t(uiText.visit.DEFAULT_CLINICAL_NOTES) : null,
			})) || [],
	};
	if (!initialData) {
		return {
			clinicalDetailsEncounter: emptyLabDiagnosticsEncounter,
			...convertToDiagnosisTableFormValues(graphqlClient, initialData),
		};
	}
	let clinicalDetailsEncounter = initialData.BH_Encounters?.filter(
		(encounter) => encounter.BH_Encounter_Type.UU === clinicalDetailsEncounterTypeWindow.BH_Encounter_Type.UU,
	)[0];
	// get clinical notes observation
	let clinicalNotesObservation = clinicalDetailsEncounter?.BH_Observations?.find(
		(observation) => observation.AD_Field.UU === fieldUuid.CLINICAL_NOTES,
	);

	if (!clinicalNotesObservation) {
		clinicalNotesObservation = {
			UU: v4(),
			AD_Field: { UU: fieldUuid.CLINICAL_NOTES },
			BH_Value: t(uiText.visit.DEFAULT_CLINICAL_NOTES),
		};
	}

	return {
		clinicalDetailsEncounter: clinicalDetailsEncounter
			? {
					UU: clinicalDetailsEncounter.UU,
					BH_Encounter_Date: new Date(clinicalDetailsEncounter.BH_Encounter_Date),
					BH_Encounter_Type: { UU: clinicalDetailsEncounterTypeWindow.BH_Encounter_Type.UU },
					BH_Observations:
						[
							...sortedFields.map((field) => {
								const observationToUse = clinicalDetailsEncounter?.BH_Observations?.find(
									(observation) =>
										observation.AD_Field.UU === field.UU && observation.AD_Field.UU !== fieldUuid.CLINICAL_NOTES,
								);

								return {
									UU: observationToUse?.UU || v4(),
									AD_Field: { UU: field.UU },
									BH_Value: observationToUse?.BH_Value || null,
								};
							}),
							clinicalNotesObservation,
						] || [],
				}
			: emptyLabDiagnosticsEncounter,
		...convertToDiagnosisTableFormValues(graphqlClient, initialData),
	};
};

export const constructClinicalDetailsFormDataToSave = (
	data: VisitFormValues,
	t: TFunction<'translation', undefined>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
): [
	{ save: Bh_EncounterInput[]; ignore: string[] },
	{ save: Bh_ObservationInput[]; ignore: string[] },
	{ save: Bh_Encounter_DiagnosisInput[]; ignore: string[] },
] => {
	// If we didn't have access to the clinical diagnostics encounters, just return nulls
	if (data.clinicalDetailsEncounter.UU === '') {
		// Get the encounter we're dealing with in this "window" so we can ignore those
		let encountersToIgnore =
			initialData?.BH_Encounters?.filter(
				(encounter) => encounter.BH_Encounter_Type.UU === EncounterTypeWindowUU.ClinicialDetails,
			) || [];
		return [
			{ save: [], ignore: encountersToIgnore.map((encounter) => encounter.UU) },
			{
				save: [],
				ignore: encountersToIgnore.flatMap((encounter) =>
					encounter.BH_Observations?.length ? encounter.BH_Observations.map((observation) => observation.UU) : [],
				),
			},
			{
				save: [],
				ignore: encountersToIgnore.flatMap((encounter) =>
					encounter.BH_Encounter_DiagnosisList?.length
						? encounter.BH_Encounter_DiagnosisList.map((encounterDiagnosis) => encounterDiagnosis.UU)
						: [],
				),
			},
		];
	}
	const clinicalDetailsEncounter: Bh_EncounterInput = {
		UU: data.clinicalDetailsEncounter.UU,
		BH_Encounter_Date: data.clinicalDetailsEncounter.BH_Encounter_Date.getTime(),
		BH_Encounter_Type: data.clinicalDetailsEncounter.BH_Encounter_Type,
		BH_Visit: { UU: data.UU },
		IsActive: true,
	};
	const clinicalDetailsEncounterObservations: Bh_ObservationInput[] =
		data.clinicalDetailsEncounter.BH_Observations.filter(
			(observation) => !!observation.BH_Value && observation.BH_Value !== t(uiText.visit.DEFAULT_CLINICAL_NOTES),
		).map((observation) => ({
			UU: observation.UU,
			AD_Field: { UU: observation.AD_Field.UU },
			BH_Encounter: { UU: data.clinicalDetailsEncounter.UU },
			BH_Value: observation.BH_Value,
			IsActive: true,
		}));

	let encounterDiagnoses = constructDiagnosisTableFormDataToSave(data);
	return [
		{
			// If we're not saving anything on this encounter, don't save the encounter
			save: clinicalDetailsEncounterObservations.length || encounterDiagnoses.length ? [clinicalDetailsEncounter] : [],
			ignore: [],
		},
		{ save: clinicalDetailsEncounterObservations, ignore: [] },
		{ save: encounterDiagnoses, ignore: [] },
	];
};

export const primeClinicalDetailsData = (graphqlClient: ApolloClient<object>) => primeVisitHistoryData(graphqlClient);

type ClinicalDetailsProps = {
	isDataReadOnly?: boolean;
	reviewHistory?: boolean;
	loading?: boolean;
};

const ClinicalDetails = ({ isDataReadOnly, reviewHistory = false, loading = false }: ClinicalDetailsProps) => {
	const { t } = useTranslation();
	const { getValues } = useFormContext<VisitFormValues>();
	const [showHistory, setShowHistory] = useState(false);

	const patientUU = useWatch<VisitFormValues, 'Patient.UU'>({ name: 'Patient.UU' });
	const [fetchPatient, { data: patient }] = useLazyQuery(C_BPartnerForVisitsDocument, { fetchPolicy: 'cache-first' });
	useEffect(() => {
		if (patientUU) {
			fetchPatient({ variables: { UU: patientUU } });
		}
	}, [patientUU, fetchPatient]);
	const visitUuid = getValues('UU');

	return (
		<>
			<Card className="bh-card">
				<Card.Header className="d-flex align-items-center">
					<div className="fw-bold h5 mb-0">{t(uiText.visit.form.CLINICAL_DETAILS)}</div>
					{!reviewHistory &&
					!isDataReadOnly &&
					patient?.C_BPartner?.TotalVisits &&
					patient.C_BPartner.TotalVisits > 1 ? (
						<Button variant="info" className="ms-auto py-0" onClick={() => setShowHistory(true)}>
							{t(uiText.visit.HISTORY)}
						</Button>
					) : null}
					{loading ? (
						<div className="ms-auto fw-light">
							<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
							<Spinner animation="border" size="sm" className="align-middle" role="status" />
						</div>
					) : null}
				</Card.Header>
				<Card.Body className="p-3">
					<Row className="gy-3">
						<ObservationFormGroup visitUuid={visitUuid} fieldPrefix={'clinicalDetailsEncounter'} />

						<DiagnosisTable visitUuid={visitUuid} readOnly={isDataReadOnly || reviewHistory} />
					</Row>
				</Card.Body>
			</Card>
			{showHistory && <VisitHistory onClose={() => setShowHistory(false)} />}
		</>
	);
};

export default memo(ClinicalDetails);
