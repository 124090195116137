import { sortBy } from 'lodash';
import { Ad_Ref_ListTenderTypesQuery } from '../graphql/__generated__/graphql';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import { ReferenceDB } from './Reference';

export const REFERENCE_LIST_UUID_DATA_TYPE_LIST = '5be1018a-8aa1-4f9a-8ec9-a022fa3675b9';
export const REFERENCE_LIST_UUID_DATA_TYPE_TEXT = '30c39cd3-e132-4b80-811e-74c5e06f8fae';
export const referenceListUuids = {
	CASH_DRAWER: '72629357-494a-4cb3-aecf-807141f1968b',
	PHARMACY_SALES_TYPE: '6ba8938f-3337-4224-be0b-0cbcd3c606ad',
	productType: { SERVICE: '265e0369-47e4-4be9-b6d5-e344230f5588' },
} as const;

export const processStageUuid = {
	CASHIER: 'fed0d4f4-4eb2-478c-beb4-9570a8da06bf',
	CLINICIAN: 'e74d5f99-fd01-4d54-ab35-7a630c43f064',
	LAB: 'e3eace1e-ee22-409b-a7ae-09cee5350b91',
	PHARMACY: '24c32cc4-3fdb-4448-85a5-879eea7866ea',
} as const;

export enum ProductTypeValue {
	Asset = 'A',
	ExpenseType = 'E',
	Item = 'I',
	Online = 'O',
	Resource = 'R',
	Service = 'S',
}

export enum ProductTypeUU {
	Asset = 'a45dcc33-c973-4744-955e-dd24518ef099',
	ExpenseType = '6a973557-0387-4173-8b31-af735b9eeb75',
	Item = '59dcc5c9-ab37-4f5c-9987-6e2347f50093',
	Online = '8de4f3a8-057c-4540-96d8-38369776b2e7',
	Resource = '42694712-fdf0-42ec-aa93-bf19009c3a98',
	Service = '265e0369-47e4-4be9-b6d5-e344230f5588',
}

// TODO: Refactor this to add line numbers to the ad_ref_list table if this ever needs to be adjusted
const paymentTypeValueSorting: { [index: string]: number } = {
	X: 10, // Cash
	M: 20, // Mobile money
	C: 30, // Credit or Debit Card
	D: 40, // Bank Transfer
	K: 50, // Cheque
};

// Sort payment types in the appropriate order based on implementer requirements
export function sortPaymentTypesGraphQL(
	paymentTypes: Ad_Ref_ListTenderTypesQuery['AD_Ref_ListGet']['Results'],
): Ad_Ref_ListTenderTypesQuery['AD_Ref_ListGet']['Results'] {
	// Get the order or, if none is defined, put it last (100)
	return sortBy(paymentTypes, [(paymentType) => paymentTypeValueSorting[paymentType.Value] || 100]);
}

// Sort payment types in the appropriate order based on implementer requirements
export function sortPaymentTypes(paymentTypes: ReferenceList[]): ReferenceList[] {
	// Get the order or, if none is defined, put it last (100)
	return sortBy(paymentTypes, [(paymentType) => paymentTypeValueSorting[paymentType.value] || 100]);
}

export interface ReferenceListDB extends BaseEntityDB {
	ad_reference: ReferenceDB;
}

export interface ReferenceListDto extends BaseEntityDto {}

/**
 * The class holding list values associated with a reference
 */
export default class ReferenceList extends BaseEntity {}
