import { useApolloClient } from '@apollo/client';
import { Fragment, useContext, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import UserContext from '../../contexts/UserContext';
import {
	Ad_Ref_ListBusinessPartnerNeedsDocument,
	Bh_Payer_Info_FldAndBh_Payer_Info_Fld_ValSaveForInsurersAndDonorsDocument,
	Bh_Payer_Info_FldSaveForInsurersAndDonorsDocument,
	Bh_Payer_Info_FldSaveForInsurersAndDonorsMutationVariables,
	C_BPartnerForInsurerAndDonorEditingDocument,
	C_BPartnerForInsurerAndDonorEditingQuery,
	C_BPartnerInsurerOrDonorSaveDocument,
	C_BPartnerInsurerOrDonorSaveMutationVariables,
	C_Bp_GroupForInsurerAndDonorsDocument,
	C_LocationForOrganizationDocument,
	C_PaymentTermForBusinessPartnersDocument,
	M_PriceListsForBusinessPartnersDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	BaseEntityDB,
	businessPartnerGroupInsurerAndDonorFilter,
	businessPartnerGroupName,
	businessPartnerGroupSubType,
	DBFilter,
	PriceListDB,
	ReferenceListDB,
	referenceUuids,
} from '../../models';
import { LocationDB } from '../../models/Location';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { uiText } from '../../utils/Language';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import AdditionalInformationFromVisit, {
	AdditionalInformationFromVisitiFormFields,
	convertToAdditionalInformationFromVisitiFormFields,
} from './AdditionalInformationFromVisit';

type InsurerOrDonorFormProps = EntityFormProperties;

// export type InsurerOrDonorFormFields = Pick<
// 	BusinessPartner,
// 	'name' | 'description' | 'needAdditionalVisitInformation' | 'isActive' | 'uuid' | 'payerInformationFieldList'
// > & {
// 	subTypeUuid: string;
// 	hasInfoBeenModified: boolean;
// 	isCapitation: boolean;
// 	addNewPayerInformationField: { name: string };
// };
export type InsurerOrDonorFormFields = {
	UU: string;
	BH_NeedAdditionalVisitInfo: boolean;
	BH_SubTypeUU: string;
	Contact: { UU: string };
	Description: string;
	IsActive: boolean;
	Name: string;
	isCapitation: boolean;
} & AdditionalInformationFromVisitiFormFields;

const getTitle = (uuid?: string) => (uuid ? uiText.nonPatientPayment.title.UPDATE : uiText.nonPatientPayment.title.NEW);
const convertToFormFields: (
	initialData?: C_BPartnerForInsurerAndDonorEditingQuery['C_BPartner'],
) => InsurerOrDonorFormFields = (initialData) => {
	if (!initialData) {
		return {
			UU: v4(),
			BH_NeedAdditionalVisitInfo: false,
			BH_SubTypeUU: '',
			Contact: { UU: v4() },
			Description: '',
			IsActive: true,
			Name: '',
			isCapitation: false,
			...convertToAdditionalInformationFromVisitiFormFields(),
		};
	}
	return {
		UU: initialData.UU,
		BH_NeedAdditionalVisitInfo: initialData.BH_NeedAdditionalVisitInfo,
		BH_SubTypeUU: initialData.C_BP_Group.BH_SubType?.UU || '',
		Contact: { UU: initialData.Contacts?.[0]?.UU || v4() },
		Description: initialData.Description || '',
		IsActive: initialData.IsActive,
		Name: initialData.Name,
		isCapitation: initialData.C_BP_Group.Name === businessPartnerGroupName.INSURANCE_CAPTITATION,
		...convertToAdditionalInformationFromVisitiFormFields(initialData),
	};
};

/**
 * This is the component for adding and editing non-patient payment values.
 * @param props The props for this component
 */
const InsurerOrDonorForm = ({ uuid, onFinish, renderAsModal }: InsurerOrDonorFormProps) => {
	const graphqlClient = useApolloClient();
	const { organization } = useContext(UserContext);
	const {
		data: [
			selectedData,
			businessPartnerGroups,
			locationForOrganization,
			businessPartnerReferenceLists,
			paymentTerms,
			priceLists,
		] = [],
	} = useSuspenseAsync(uuid || 'add-business-partner', async () =>
		Promise.all([
			uuid
				? graphqlClient.query({
						query: C_BPartnerForInsurerAndDonorEditingDocument,
						variables: { UU: uuid },
						fetchPolicy: 'network-only',
					})
				: undefined,
			graphqlClient.query({
				query: C_Bp_GroupForInsurerAndDonorsDocument,
				variables: { Filter: businessPartnerGroupInsurerAndDonorFilter().toString() },
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: C_LocationForOrganizationDocument,
				variables: {
					Filter: DBFilter<LocationDB>()
						.nested('ad_orginfo')
						.nested('ad_org')
						.property('ad_org_uu')
						.equals(organization.uuid)
						.up()
						.up()
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: Ad_Ref_ListBusinessPartnerNeedsDocument,
				variables: {
					Filter: DBFilter<ReferenceListDB>()
						.nested('ad_reference')
						.property('ad_reference_uu')
						.isIn([
							referenceUuids.BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS,
							referenceUuids.ORDER_INVOICE_RULES,
							referenceUuids.PAYMENT_TYPES,
							referenceUuids.USER_NOTIFICATION_TYPES,
						])
						.up()
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: C_PaymentTermForBusinessPartnersDocument,
				variables: { Size: 1, Filter: DBFilter<BaseEntityDB>().property('name').equals('Immediate').toString() },
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: M_PriceListsForBusinessPartnersDocument,
				variables: {
					Sort: JSON.stringify([['created', 'desc']]),
					Filter: DBFilter<PriceListDB>()
						.property('isdefault')
						.equals(true)
						.property('isactive')
						.equals(true)
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
		]),
	);

	const { t } = useTranslation();
	const [initialData, setInitialData] = useState(selectedData?.data.C_BPartner);
	const title = getTitle(initialData ? uuid : undefined);
	const formMethods = useForm<InsurerOrDonorFormFields>({
		defaultValues: convertToFormFields(initialData),
	});
	const { disableWrite, disableDeactivate } = useActionPrivileges(pageUuid.INSURERS_AND_DONORS);

	// Get the data needed for the form
	const subTypes = (
		[
			...new Map(
				businessPartnerGroups?.data.C_BP_GroupGet.Results.filter(
					(businessPartnerGroup) => !!businessPartnerGroup.BH_SubType?.UU,
				).map((businessPartnerGroup) => [businessPartnerGroup.BH_SubType?.UU, businessPartnerGroup.BH_SubType]),
			).values(),
		] as Array<{
			__typename?: 'AD_Ref_List' | undefined;
			UU: string;
			Name: string;
		}>
	).sort((referenceListA, referenceListB) =>
		referenceListA.Name < referenceListB.Name ? -1 : referenceListA.Name > referenceListB.Name ? 1 : 0,
	);

	const { dataWasSaved, wasDataSaved, savedData } = useContext(FormModalContext);

	const [, onSubmit] = useAsyncFn<SubmitHandler<InsurerOrDonorFormFields>>(
		async (data) => {
			const matchedBusinessPartnerGroups = businessPartnerGroups?.data.C_BP_GroupGet.Results.filter(
				(businessPartnerGroup) => businessPartnerGroup.BH_SubType?.UU === data.BH_SubTypeUU,
			);
			let businessPartnerGroupToUse = matchedBusinessPartnerGroups?.[0];
			if (businessPartnerGroupToUse?.BH_SubType?.Value === businessPartnerGroupSubType.Insurance) {
				businessPartnerGroupToUse = matchedBusinessPartnerGroups?.find((businessPartnerGroup) =>
					data.isCapitation
						? businessPartnerGroup.Name === businessPartnerGroupName.INSURANCE_CAPTITATION
						: businessPartnerGroup.Name !== businessPartnerGroupName.INSURANCE_CAPTITATION,
				);
			}
			let immediateOrderInvoiceRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.ORDER_INVOICE_RULES && referenceList.Value === 'I', // Immediate
			);
			let directDepositPaymentRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'T', // Direct Deposit
			);
			let onCreditPaymentRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'P', // On Credit
			);
			let salesOrderNoCreditCheck = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS &&
					referenceList.Value === 'X', // No Credit Check
			);
			let noNotification = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
				(referenceList) =>
					referenceList.AD_Reference.UU === referenceUuids.USER_NOTIFICATION_TYPES && referenceList.Value === 'X', // No Notification
			);
			let purchasePriceListUU =
				businessPartnerGroupToUse?.PO_PriceList?.UU ||
				priceLists?.data.M_PriceListGet.Results.find((priceList) => !priceList.IsSOPriceList)?.UU;
			let salesPriceListUU =
				businessPartnerGroupToUse?.M_PriceList?.UU ||
				priceLists?.data.M_PriceListGet.Results.find((priceList) => priceList.IsSOPriceList)?.UU;

			let locationUU =
				initialData?.C_BPartner_Locations?.[0].C_Location.UU ||
				locationForOrganization?.data.C_LocationGet.Results[0]?.UU;
			let location: C_BPartnerInsurerOrDonorSaveMutationVariables['C_Location'] = locationUU
				? { UU: locationUU }
				: { UU: v4() };
			// If we don't have a location UUID by now, we need to get or create one
			if (!locationUU) {
				let locationFilter = DBFilter<LocationDB>();
				let organizationLocation = locationForOrganization?.data.C_LocationGet.Results[0];
				if (organizationLocation?.C_Country.UU) {
					locationFilter = locationFilter
						.nested('c_country')
						.property('c_country_uu')
						.equals(organizationLocation.C_Country.UU)
						.up();
				}
				if (organizationLocation?.C_Region?.UU) {
					locationFilter = locationFilter
						.nested('c_region')
						.property('c_region_uu')
						.equals(organizationLocation.C_Region.UU)
						.up();
				}
				let searchedLocationUU = (
					await graphqlClient.query({
						query: C_LocationForOrganizationDocument,
						variables: { Size: 1, Filter: locationFilter.toString() },
					})
				).data.C_LocationGet.Results[0]?.UU;
				// If we found something, use it
				if (searchedLocationUU) {
					location.UU = searchedLocationUU;
				} else {
					// Otherwise, make our own
					location.C_Country = organizationLocation?.C_Country.UU
						? { UU: organizationLocation.C_Country.UU }
						: undefined;
					location.C_Region = organizationLocation?.C_Region?.UU ? { UU: organizationLocation.C_Region.UU } : undefined;
				}
			}

			try {
				await graphqlClient.mutate({
					mutation: C_BPartnerInsurerOrDonorSaveDocument,
					variables: {
						C_BPartner: {
							UU: data.UU,
							BH_NeedAdditionalVisitInfo: data.BH_NeedAdditionalVisitInfo,
							C_BP_Group: businessPartnerGroupToUse?.UU ? { UU: businessPartnerGroupToUse.UU } : undefined,
							C_PaymentTerm: paymentTerms?.data.C_PaymentTermGet.Results[0]?.UU
								? { UU: paymentTerms.data.C_PaymentTermGet.Results[0].UU }
								: undefined,
							Description: data.Description || null,
							InvoiceRule: immediateOrderInvoiceRule ? { UU: immediateOrderInvoiceRule.UU } : undefined,
							IsActive: data.IsActive,
							IsCustomer: true,
							IsVendor: false,
							M_PriceList: salesPriceListUU ? { UU: salesPriceListUU } : undefined,
							Name: data.Name,
							PaymentRule: onCreditPaymentRule ? { UU: onCreditPaymentRule.UU } : undefined,
							PaymentRulePO: directDepositPaymentRule ? { UU: directDepositPaymentRule.UU } : undefined,
							PO_PaymentTerm: paymentTerms?.data.C_PaymentTermGet.Results[0]?.UU
								? { UU: paymentTerms.data.C_PaymentTermGet.Results[0].UU }
								: undefined,
							PO_PriceList: purchasePriceListUU ? { UU: purchasePriceListUU } : undefined,
							SOCreditStatus: salesOrderNoCreditCheck ? { UU: salesOrderNoCreditCheck.UU } : undefined,
						},
						C_Location: location,
						C_BPartner_Location: initialData?.C_BPartner_Locations?.[0]?.UU
							? { UU: initialData.C_BPartner_Locations[0].UU }
							: {
									C_BPartner: { UU: data.UU },
									C_Location: { UU: location.UU! },
									Name: data.Name + ' Location',
								},
						AD_User: {
							UU: data.Contact.UU,
							C_BPartner: { UU: data.UU },
							IsFullBPAccess: false,
							IsActive: true,
							Name: data.Name,
							NotificationType: noNotification?.UU ? { UU: noNotification.UU } : undefined,
						},
					},
				});
				if (data.BH_Payer_Info_FldList.length) {
					let payerInformationFieldListToSave: Bh_Payer_Info_FldSaveForInsurersAndDonorsMutationVariables['BH_Payer_Info_FldList'] =
						data.BH_Payer_Info_FldList.map((payerInformationField, index) => ({
							UU: payerInformationField.UU,
							BH_FillFromPatient: payerInformationField.BH_FillFromPatient,
							BH_Payer: { UU: data.UU },
							BH_PayerInfoFieldDataType: { UU: payerInformationField.BH_PayerInfoFieldDataTypeUU },
							IsActive: payerInformationField.IsActive,
							Line: index * 10,
							Name: payerInformationField.Name,
						}));
					if (
						data.BH_Payer_Info_FldList.some(
							(payerInformationField) => payerInformationField.BH_Payer_Info_Fld_ValList.length > 0,
						)
					) {
						await graphqlClient.mutate({
							mutation: Bh_Payer_Info_FldAndBh_Payer_Info_Fld_ValSaveForInsurersAndDonorsDocument,
							variables: {
								BH_Payer_Info_FldList: payerInformationFieldListToSave,
								BH_Payer_Info_Fld_ValList: data.BH_Payer_Info_FldList.flatMap((payerInformationField) =>
									payerInformationField.BH_Payer_Info_Fld_ValList.map((payerInformationFieldValue, index) => ({
										UU: payerInformationFieldValue.UU,
										BH_Payer_Info_Fld: { UU: payerInformationField.UU },
										IsActive: payerInformationFieldValue.IsActive,
										Line: index * 10,
										Name: payerInformationFieldValue.Name,
									})),
								).filter((payerInformationFieldValue) => !!payerInformationFieldValue),
							},
						});
					} else {
						await graphqlClient.mutate({
							mutation: Bh_Payer_Info_FldSaveForInsurersAndDonorsDocument,
							variables: { BH_Payer_Info_FldList: payerInformationFieldListToSave },
						});
					}
				}
				const savedData = (
					await graphqlClient.query({
						query: C_BPartnerForInsurerAndDonorEditingDocument,
						variables: { UU: data.UU },
						fetchPolicy: 'network-only',
					})
				).data.C_BPartner;
				if (!savedData) {
					throw new Error('no saved BP found');
				}
				toast.success(t(uiText.nonPatientPayment.success.UPDATE));
				formMethods.reset({
					...convertToFormFields(savedData),
					hasInfoBeenModified: 'true',
				});
				setInitialData(savedData);
				dataWasSaved(savedData.UU);
			} catch (error) {
				exception({ description: `Non-Patient Payment save error: ${error}` });
				toast.error(t(uiText.nonPatientPayment.error.COULD_NOT_SAVE, { error }));
			}
		},
		[
			businessPartnerGroups,
			setInitialData,
			dataWasSaved,
			locationForOrganization,
			graphqlClient,
			initialData,
			businessPartnerReferenceLists,
			paymentTerms,
			priceLists,
		],
	);

	const selectedSubTypeUuid = formMethods.watch('BH_SubTypeUU');

	useConfirmRefresh(formMethods.formState?.isDirty);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off" className="px-0">
				<input type="hidden" {...formMethods.register('UU')} />
				<input type="hidden" {...formMethods.register('Contact.UU')} />
				<fieldset disabled={disableWrite || initialData?.BH_Locked}>
					<Card className="bh-card">
						<Card.Body>
							<Row className="gy-3">
								<Form.Group as={Fragment} controlId="name">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.nonPatientPayment.name.LABEL)}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<Form.Control
											placeholder={t(uiText.nonPatientPayment.label.ENTER_NAME)}
											{...formMethods.register('Name', { required: true })}
										/>
										{formMethods.formState.errors.Name && (
											<span className="text-danger">{t(uiText.nonPatientPayment.validationMessages.REQUIRE_NAME)}</span>
										)}
									</Col>
								</Form.Group>
								<Col xs={3} />

								<Form.Group as={Fragment} controlId="description">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.nonPatientPayment.description.LABEL)}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<Form.Control
											as="textarea"
											rows={3}
											placeholder={t(uiText.nonPatientPayment.description.ENTER)}
											{...formMethods.register('Description')}
										/>
									</Col>
								</Form.Group>
								<Col xs={3} />

								<Form.Group as={Fragment} controlId="subTypeUuid">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.nonPatientPayment.category.LABEL)}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<Form.Select {...formMethods.register('BH_SubTypeUU', { required: true })}>
											<option value="">{t(uiText.nonPatientPayment.category.SELECT)}</option>
											{subTypes.map((referenceList) => (
												<option key={referenceList.UU} value={referenceList.UU}>
													{referenceList.Name}
												</option>
											))}
										</Form.Select>
										{formMethods.formState.errors.BH_SubTypeUU && (
											<span className="text-danger">
												{t(uiText.nonPatientPayment.validationMessages.REQUIRE_CATEGORY)}
											</span>
										)}
									</Col>
								</Form.Group>
								<Col xs={3} />

								{businessPartnerGroups?.data.C_BP_GroupGet.Results.filter(
									(businessPartnerGroup) =>
										businessPartnerGroup.BH_SubType?.Value === businessPartnerGroupSubType.Insurance,
								)
									.map((businessPartnerGroup) => businessPartnerGroup.BH_SubType?.UU)
									.includes(selectedSubTypeUuid) && (
									<Form.Group as={Col} controlId="isActive" xs={{ span: 11, offset: 1 }}>
										<Form.Check {...formMethods.register('isCapitation')} label={'Capitation'} />
									</Form.Group>
								)}

								<Form.Group as={Col} controlId="isActive" xs={{ span: 2, offset: 1 }}>
									<fieldset disabled={disableDeactivate} className="w-100">
										<Form.Check
											{...formMethods.register('IsActive')}
											label={t(uiText.nonPatientPayment.button.ACTIVE)}
										/>
									</fieldset>
								</Form.Group>
								<Form.Group as={Col} controlId="needAdditionalVisitInformation" xs={5}>
									<Form.Check
										{...formMethods.register('BH_NeedAdditionalVisitInfo')}
										label={t(uiText.nonPatientPayment.button.NEED_ADDITIONAL_VISIT_INFORMATION)}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
					<AdditionalInformationFromVisit />
				</fieldset>
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
			<Col xs="auto" className="me-auto">
				<Button
					type="button"
					variant="danger"
					onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
				>
					{!formMethods.formState.isDirty
						? t(uiText.nonPatientPayment.button.BACK)
						: t(uiText.nonPatientPayment.button.CANCEL)}
				</Button>
			</Col>
			{!disableWrite && (
				<Col xs="auto">
					<Button type="submit" variant="success" onClick={() => formMethods.handleSubmit(onSubmit)()}>
						{t(uiText.nonPatientPayment.button.SAVE)}
					</Button>
				</Col>
			)}
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="bg-white pb-0_5 me-n2_5">
					{inputs}
					{buttons}
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<InsurerOrDonorFormProps>({
	loadingLabel: uiText.nonPatientPayment.LOADING,
	getTitle,
})(InsurerOrDonorForm);
