import { ApolloClient, useApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { v4 } from 'uuid';
import {
	Ad_Ref_ListNonPatientPayerCategoriesForVisitsDocument,
	Ad_Ref_ListTenderTypesDocument,
	Ad_Ref_ListTenderTypesForVisitsDocument,
	Ad_Ref_ListTenderTypesForVisitsQueryVariables,
	Ad_Ref_ListTenderTypesQueryVariables,
	Bh_Bp_Payer_InfoGetForVisitPatientDocument,
	Bh_Bp_Specific_Payer_InfoInput,
	Bh_Payer_Info_FldForPaymentSavingFragmentDoc,
	Bh_VisitForEditingQuery,
	C_AcctSchemaForCurrencyUseDocument,
	C_BankAccountForPaymentsDocument,
	C_BPartnerForVisitsInsurersAndDonorsDocument,
	C_BPartnerForVisitsInsurersAndDonorsQueryVariables,
	C_ChargeForVisitsWaiversDocument,
	C_ChargeForVisitsWaiversQueryVariables,
	C_DocTypeForFormsDocument,
	C_InvoiceInput,
	C_InvoiceLineInput,
	C_OrderInput,
	C_OrderLineInput,
	C_PaymentInput,
} from '../../graphql/__generated__/graphql';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import {
	BaseMetadataDB,
	BusinessPartnerDB,
	businessPartnerGroupInsurerAndDonorFilter,
	businessPartnerGroupSubType,
	BusinessPartnerPayerInformationDB,
	ChargeDB,
	chargeSubType,
	documentBaseType,
	referenceUuids,
} from '../../models';
import DBFilter from '../../models/DBFilter';
import Organization from '../../models/Organization';
import Paging from '../../models/Paging';
import { ReferenceListDB, sortPaymentTypesGraphQL } from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import { isEntityCompleted, isEntityReactivated, isEntityVoided } from '../../utils/StatusUtil';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import PaymentInformationRow from './PaymentInformationRow';
import PaymentLineItemTableFooter from './PaymentLineItemTableFooter';
import { VisitFormValues } from './VisitForm';

export type PaymentLineItemTableFormValues = {
	addPaymentInformation: { UU: string; Description: string | null; PayAmt: number | null };
	paymentInformationList: Array<{
		UU: string;
		BH_BP_Specific_Payer_InfoList: Array<{ UU: string; BH_Payer_Info_Fld: { UU: string }; Name: string | null }>;
		C_Charge: { UU: string };
		Description: string | null;
		PayAmt: number | null;
		Payer: { UU: string };
		PaymentType: { UU: string };
		isPayment: 'true' | 'false';
		isWaiver: 'true' | 'false';
	}>;
};

export const convertToPaymentLineItemTableFormValues: (
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
) => PaymentLineItemTableFormValues = (initialData) => {
	let commonData: Pick<PaymentLineItemTableFormValues, 'addPaymentInformation'> = {
		addPaymentInformation: { UU: '', Description: null, PayAmt: null },
	};
	if (!initialData) {
		return {
			paymentInformationList: [],
			...commonData,
		};
	}
	const invoicesThatCanBeDisplayed =
		initialData.C_Invoices?.filter((invoice) => !isEntityVoided(invoice) && !isEntityReactivated(invoice)) || [];
	return {
		paymentInformationList: [
			...((
				invoicesThatCanBeDisplayed
					.filter((invoice) => invoice.C_BPartner.UU === initialData.Patient.UU)
					.map((invoice) =>
						invoice.C_InvoiceLines?.filter(
							(invoiceLine) =>
								!!invoiceLine.C_Charge?.UU &&
								invoiceLine.C_Charge.BH_SubType?.Value === chargeSubType.Waiver &&
								invoiceLine.LineNetAmt < 0,
						).map((invoiceLine) => ({
							UU: invoiceLine.UU,
							// Wiavers won't have any payer information
							BH_BP_Specific_Payer_InfoList: [],
							C_Charge: { UU: invoiceLine.C_Charge?.UU! },
							Description: invoiceLine.Description,
							PayAmt: invoiceLine.LineNetAmt * -1,
							Payer: { UU: invoice.C_BPartner.UU },
							PaymentType: { UU: invoiceLine.C_Charge?.BH_SubType?.UU! },
							isPayment: 'false',
							isWaiver: 'true',
						})),
					)
					.flatMap((invoiceLines) => invoiceLines) || []
			).filter((invoiceLine) => !!invoiceLine) as PaymentLineItemTableFormValues['paymentInformationList']),
			...((
				invoicesThatCanBeDisplayed
					.filter((invoice) => invoice.C_BPartner.UU !== initialData.Patient.UU)
					.map((invoice) =>
						invoice.C_InvoiceLines?.filter(
							(invoiceLine) =>
								!!invoiceLine.C_Charge?.UU && invoiceLine.C_Charge.BH_SubType?.Value !== chargeSubType.Waiver,
						).map((invoiceLine) => {
							const patientInvoiceLineMatchingThisPayerInvoiceLine = invoicesThatCanBeDisplayed
								.find((invoice) => invoice.C_BPartner.UU === initialData.Patient.UU)
								?.C_InvoiceLines?.find(
									(patientInvoiceLine) =>
										patientInvoiceLine.C_Charge?.UU === invoiceLine.C_Charge?.UU &&
										patientInvoiceLine.LineNetAmt === invoiceLine.LineNetAmt * -1,
								);
							return {
								UU: invoiceLine.UU,
								BH_BP_Specific_Payer_InfoList:
									sortBy(
										patientInvoiceLineMatchingThisPayerInvoiceLine?.BH_BP_Specific_Payer_InfoList?.[0].BH_Payer_Info_Fld
											.BH_Payer.BH_Payer_Info_FldList || [],
										'Line',
									).map((payerInformationField) => {
										const businessPartnerSpecificPayerInformation =
											patientInvoiceLineMatchingThisPayerInvoiceLine?.BH_BP_Specific_Payer_InfoList?.find(
												(businessPartnerSpecificPayerInformation) =>
													businessPartnerSpecificPayerInformation.BH_Payer_Info_Fld.UU === payerInformationField.UU,
											);
										return {
											UU: businessPartnerSpecificPayerInformation?.UU || v4(),
											BH_Payer_Info_Fld: { UU: payerInformationField.UU },
											Name: businessPartnerSpecificPayerInformation?.Name || null,
										};
									}) || [],
								C_Charge: { UU: invoiceLine.C_Charge?.UU! },
								Description: invoiceLine.Description,
								PayAmt: invoiceLine.LineNetAmt,
								Payer: { UU: invoice.C_BPartner.UU },
								PaymentType: { UU: invoice.C_BPartner.C_BP_Group.BH_SubType?.UU! },
								isPayment: 'false',
								isWaiver: 'false',
							};
						}),
					)
					.flatMap((invoiceLines) => invoiceLines) || []
			).filter((invoiceLine) => !!invoiceLine) as PaymentLineItemTableFormValues['paymentInformationList']),
			...((
				initialData.C_Payments?.filter((payment) => !isEntityVoided(payment) && !isEntityReactivated(payment)).map(
					(payment) => ({
						UU: payment.UU,
						BH_BP_Specific_Payer_InfoList: [],
						C_Charge: { UU: '' },
						Description: payment.Description,
						PayAmt: payment.BH_tender_amount,
						Payer: { UU: '' },
						PaymentType: { UU: payment.TenderType.UU },
						isPayment: 'true',
						isWaiver: 'false',
					}),
				) || []
			).filter((payment) => !!payment) as PaymentLineItemTableFormValues['paymentInformationList']),
		],
		...commonData,
	};
};

export const constructPaymentLineItemTableFormDataToSave = async (
	data: VisitFormValues,
	initialData: Bh_VisitForEditingQuery['BH_Visit'],
	graphqlClient: ApolloClient<object>,
	orderToSave: C_OrderInput,
	orderLinesToSave: C_OrderLineInput[],
	organization: Organization,
): Promise<
	[
		{ save: C_InvoiceInput[]; delete: string[] },
		{ save: C_InvoiceLineInput[]; delete: string[] },
		{ save: Bh_Bp_Specific_Payer_InfoInput[]; delete: string[] },
		{ save: C_PaymentInput[]; delete: string[] },
	]
> => {
	let arInvoiceDocumentType = (
		await graphqlClient.query({
			query: C_DocTypeForFormsDocument,
			variables: { Filter: getDocumentBaseTypeFilter(...fetchARInvoiceDocumentTypeArguments).toString() },
			fetchPolicy: 'cache-first',
		})
	).data.C_DocTypeGet.Results[0];
	let receiptDocumentType = (
		await graphqlClient.query({
			query: C_DocTypeForFormsDocument,
			variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
			fetchPolicy: 'cache-first',
		})
	).data.C_DocTypeGet.Results[0];
	let bankAccounts = (
		await graphqlClient.query({
			query: C_BankAccountForPaymentsDocument,
			variables: {
				Sort: JSON.stringify([['created', 'asc']]),
				Filter: getBankAccountFilterArguments(organization),
			},
			fetchPolicy: 'cache-first',
		})
	).data.C_BankAccountGet.Results;
	let accountSchema = (
		await graphqlClient.query({ query: C_AcctSchemaForCurrencyUseDocument, fetchPolicy: 'cache-first' })
	).data.C_AcctSchemaGet.Results[0];

	// Get the default or first bank account to use
	let bankAccountUU = bankAccounts.filter((bankAccount) => bankAccount.IsDefault)[0]?.UU;
	if (!bankAccountUU) {
		bankAccountUU = bankAccounts[0]?.UU;
	}

	// Only invoices/payments that aren't completed/voided/reactivated can be saved/processed/deleted
	let invoicesThatCanBeSavedOrDeleted =
		initialData?.C_Invoices?.filter(
			(invoice) => !isEntityVoided(invoice) && !isEntityReactivated(invoice) && !isEntityCompleted(invoice),
		) || [];
	let paymentsThatCanBeSavedOrDeleted =
		initialData?.C_Payments?.filter(
			(invoice) => !isEntityVoided(invoice) && !isEntityReactivated(invoice) && !isEntityCompleted(invoice),
		) || [];

	// Every visit (we're saving) should have at least one invoice: the patient's. Use or create it
	// Each visit could additionally have other invoices for all insurers/donors being charged
	let patientInvoice = invoicesThatCanBeSavedOrDeleted.find((invoice) => invoice.C_Order?.UU === orderToSave.UU);
	let patientInvoiceToSave: C_InvoiceInput = {
		UU: patientInvoice?.UU || v4(),
		BH_Visit: { UU: data.UU },
		C_BPartner: { UU: data.Patient.UU! },
		C_DocTypeTarget: { UU: arInvoiceDocumentType.UU },
		C_Order: { UU: orderToSave.UU! },
		DateInvoiced: data.BH_VisitDate.getTime(),
		IsSOTrx: arInvoiceDocumentType.IsSOTrx,
	};
	let invoicesToSave = [patientInvoiceToSave];
	let patientInvoiceLines = patientInvoice?.C_InvoiceLines || [];

	// If something has broken, there may be duplicate invoices for an order - remove them
	let businessPartnerSpecificPayerInformationUUsToDelete: string[] = [];
	let invoiceLineUUsToDelete: string[] = [];
	let invoiceUUsToDelete: string[] = [];
	// Remove any invoice lines relating to order lines that were deleted
	const orderLineUuids = orderLinesToSave.map((orderLine) => orderLine.UU!);
	invoiceLineUUsToDelete.push(
		...(patientInvoiceLines
			?.filter((invoiceLine) => invoiceLine.C_OrderLine?.UU && !orderLineUuids?.includes(invoiceLine.C_OrderLine.UU))
			.map((invoiceLine) => {
				// If we're deleting the invoice line, we need to delete the specific payer information
				businessPartnerSpecificPayerInformationUUsToDelete.push(
					...(invoiceLine.BH_BP_Specific_Payer_InfoList?.map(
						(businessPartnerSpecificPayerInformation) => businessPartnerSpecificPayerInformation.UU,
					) || []),
				);
				return invoiceLine.UU;
			}) || []),
	);
	patientInvoiceLines = patientInvoiceLines?.filter((invoiceLine) => !invoiceLineUUsToDelete.includes(invoiceLine.UU));

	// Make sure every order line maps to an invoice line so that the patient is invoiced for what was ordered
	let invoiceLinesToSave: C_InvoiceLineInput[] = orderLinesToSave.map((orderLine) => {
		let invoiceLineToUse = patientInvoiceLines?.find((invoiceLine) => invoiceLine.C_OrderLine?.UU === orderLine.UU);
		return {
			UU: invoiceLineToUse?.UU || v4(),
			C_Charge: null,
			C_Invoice: { UU: patientInvoiceToSave.UU! },
			C_OrderLine: { UU: orderLine.UU! },
			M_Product: { UU: orderLine.M_Product!.UU! },
			Qty: orderLine.Qty,
			Price: orderLine.Price,
		};
	});

	let paymentsToSave: C_PaymentInput[] = [];
	let paymentUUsToDelete: string[] = [];
	let businessPartnerSpecificPayerInformationToSave: Bh_Bp_Specific_Payer_InfoInput[] = [];

	// Add non-patient payments to invoice lines
	const arePaymentsPresent = data.paymentInformationList?.length > 0;
	if (arePaymentsPresent) {
		// Get the total charged for this visit
		let runningTotal = orderLinesToSave.reduce(
			(runningTotal, orderLine) => runningTotal + (orderLine.Price || 0) * (orderLine.Qty || 0),
			0,
		);
		// Hold invoice liens that will exist on the insurer/donor invoice
		let insurerOrDonorInvoiceLines: { [payerUuid: string]: C_InvoiceLineInput[] } = {};
		// We need to map the payments to payments or invoice lines
		// Non patient payments are anything that we've specified isn't a payment
		const nonPatientPayments =
			data.paymentInformationList?.filter((paymentInformation) => paymentInformation.isPayment !== 'true') || [];
		const previousPatientDeductionInvoiceLines = patientInvoiceLines.filter(
			(invoiceLine) => !!invoiceLine.C_Charge?.UU,
		);
		nonPatientPayments.forEach((paymentInformation) => {
			let priceToUse = paymentInformation.PayAmt || 0;
			// If the running total is smaller than the entered price, we'll take the remaining total
			if (runningTotal - priceToUse < 0) {
				priceToUse = runningTotal;
			}
			// If there's no price to use, we'll not save the amount
			if (priceToUse === 0) {
				return undefined;
			}
			runningTotal -= priceToUse;
			// If this non-patient payment is not a waiver, we need to create an invoice line for the insurer/donor
			if (paymentInformation.isWaiver !== 'true') {
				insurerOrDonorInvoiceLines[paymentInformation.Payer.UU] =
					insurerOrDonorInvoiceLines[paymentInformation.Payer.UU] || [];
				insurerOrDonorInvoiceLines[paymentInformation.Payer.UU].push({
					UU: paymentInformation.UU,
					C_Charge: { UU: paymentInformation.C_Charge.UU },
					// We don't have the insurer/donor invoice this line goes to yet, so we won't assign it yet (will happen later)
					Description: paymentInformation.Description,
					M_Product: null,
					Price: priceToUse,
					Qty: 1,
				});
			}
			// We'll have to use a roundabout way to identify what the original invoice line was on the patient's invoice
			// If we have a match on the charge & amount, we'll assume it's the same. Otherwise, we'll just create a new
			// line (and the old invoice line will just get deleted)
			let existingButCurrentlyRemovedPatientInvoiceLine = previousPatientDeductionInvoiceLines?.find(
				(invoiceLine) =>
					invoiceLine.C_Charge?.UU === paymentInformation.C_Charge.UU && invoiceLine.LineNetAmt === priceToUse * -1,
			);
			// Make sure the patient deduction invoice line gets saved
			const patientInvoiceLineUU = existingButCurrentlyRemovedPatientInvoiceLine?.UU || v4();
			invoiceLinesToSave.push({
				UU: patientInvoiceLineUU,
				C_Charge: { UU: paymentInformation.C_Charge.UU },
				C_Invoice: { UU: patientInvoiceToSave.UU! },
				Description: paymentInformation.Description || null,
				M_Product: null,
				Price: priceToUse * -1,
				Qty: 1,
			});
			// If the visit is being saved, don't save things coming from the patient's data
			businessPartnerSpecificPayerInformationToSave.push(
				...paymentInformation.BH_BP_Specific_Payer_InfoList.filter(
					(businessPartnerSpecificPayerInformation) =>
						(data.submitEvent !== 'save' ||
							!graphqlClient.readFragment({
								id: businessPartnerSpecificPayerInformation.BH_Payer_Info_Fld.UU,
								fragment: Bh_Payer_Info_FldForPaymentSavingFragmentDoc,
							})?.BH_FillFromPatient) &&
						!!businessPartnerSpecificPayerInformation.Name,
				).map((businessPartnerSpecificPayerInformation) => ({
					UU: businessPartnerSpecificPayerInformation.UU,
					BH_Payer_Info_Fld: { UU: businessPartnerSpecificPayerInformation.BH_Payer_Info_Fld.UU },
					C_InvoiceLine: { UU: patientInvoiceLineUU },
					Name: businessPartnerSpecificPayerInformation.Name,
				})),
			);
		});

		let paymentUUsThatCanNotBeSavedOrDeleted =
			initialData?.C_Payments?.filter(
				(invoice) => isEntityVoided(invoice) || isEntityReactivated(invoice) || isEntityCompleted(invoice),
			).map((payment) => payment.UU) || [];
		paymentsToSave =
			(data.paymentInformationList
				?.filter(
					(paymentInformation) =>
						paymentInformation.isPayment === 'true' &&
						!paymentUUsThatCanNotBeSavedOrDeleted.includes(paymentInformation.UU),
				)
				.map((paymentInformation) => {
					let priceToUse = paymentInformation.PayAmt || 0;
					// If the running total is smaller than the entered price, we'll take the remaining total
					if (runningTotal - priceToUse < 0) {
						priceToUse = runningTotal;
					}
					// If there's no price to use, we'll not save the amount
					if (priceToUse === 0) {
						return undefined;
					}
					runningTotal -= priceToUse;
					return {
						UU: paymentInformation.UU,
						BH_tender_amount: paymentInformation.PayAmt,
						BH_Visit: { UU: data.UU },
						C_BPartner: { UU: data.Patient.UU },
						C_BankAccount: bankAccountUU ? { UU: bankAccountUU } : undefined,
						C_Currency: { UU: accountSchema.C_Currency.UU },
						C_DocType: { UU: receiptDocumentType.UU },
						DateAcct: data.BH_VisitDate.getTime(),
						DateTrx: data.BH_VisitDate.getTime(),
						Description: paymentInformation.Description || null,
						IsReceipt: receiptDocumentType.IsSOTrx,
						PayAmt: priceToUse,
						TenderType: { UU: paymentInformation.PaymentType.UU },
					};
				})
				.filter((payment) => !!payment) as C_PaymentInput[]) || [];

		// For any payers, we need to submit separate invoices for them
		const payerUuidList = [
			...new Set(
				data.paymentInformationList
					?.filter(
						(paymentInformation) => paymentInformation.isPayment !== 'true' && paymentInformation.isWaiver !== 'true',
					)
					.map((paymentInformation) => paymentInformation.Payer.UU),
			),
		];
		payerUuidList.forEach((payerUuid) => {
			let payerInvoice = invoicesThatCanBeSavedOrDeleted.find((invoice) => invoice.C_BPartner.UU === payerUuid);
			let payerInvoiceToSave: C_InvoiceInput = {
				UU: payerInvoice?.UU || v4(),
				BH_Visit: { UU: data.UU },
				C_BPartner: { UU: payerUuid },
				C_DocTypeTarget: { UU: arInvoiceDocumentType.UU },
				DateInvoiced: data.BH_VisitDate.getTime(),
				Description: 'Auto-generated insurer/donor invoice',
				IsSOTrx: arInvoiceDocumentType.IsSOTrx,
			};
			invoicesToSave.push(payerInvoiceToSave);
			invoiceLinesToSave.push(
				...insurerOrDonorInvoiceLines[payerUuid].map((invoiceLine) => {
					invoiceLine.C_Invoice = { UU: payerInvoiceToSave.UU! };
					return invoiceLine;
				}),
			);
		});
		let paymentUUsToSave = paymentsToSave.map((payment) => payment.UU || v4());
		paymentUUsToDelete.push(
			...paymentsThatCanBeSavedOrDeleted
				.filter((payment) => !paymentUUsToSave.includes(payment.UU))
				.map((payment) => payment.UU),
		);
	}

	// We'll remove any invoices, invoice lines, and payer information that we're not saving
	let invoiceUUsToSave = invoicesToSave.map((invoice) => invoice.UU || v4());
	let invoiceLineUUsToSave = invoiceLinesToSave.map((invoiceLine) => invoiceLine.UU || v4());
	let businessPartnerSpecificPayerInformationUUsToSave = businessPartnerSpecificPayerInformationToSave.map(
		(businessPartnerSpecificPayerInformation) => businessPartnerSpecificPayerInformation.UU || v4(),
	);
	invoiceUUsToDelete.push(
		...invoicesThatCanBeSavedOrDeleted
			.filter((invoice) => !invoiceUUsToSave.includes(invoice.UU))
			.map((invoice) => {
				// If we're deleting the invoice, we need to delete the invoice lines
				invoiceLineUUsToDelete.push(
					...(invoice.C_InvoiceLines?.filter((invoiceLine) => !invoiceLineUUsToSave.includes(invoiceLine.UU)).map(
						(invoiceLine) => {
							// If we're deleting the invoice line, we need to delete the specific payer information
							businessPartnerSpecificPayerInformationUUsToDelete.push(
								...(invoiceLine.BH_BP_Specific_Payer_InfoList?.filter(
									(businessPartnerSpecificPayerInformation) =>
										!businessPartnerSpecificPayerInformationUUsToSave.includes(
											businessPartnerSpecificPayerInformation.UU,
										),
								).map((businessPartnerSpecificPayerInformation) => businessPartnerSpecificPayerInformation.UU) || []),
							);
							return invoiceLine.UU;
						},
					) || []),
				);
				return invoice.UU;
			}),
	);
	invoiceLineUUsToDelete.push(
		...(invoicesThatCanBeSavedOrDeleted
			.flatMap((invoice) => invoice.C_InvoiceLines || [])
			.filter((invoiceLine) => !invoiceLineUUsToSave.includes(invoiceLine.UU))
			.map((invoiceLine) => {
				// If we're deleting the invoice line, we need to delete the specific payer information
				businessPartnerSpecificPayerInformationUUsToDelete.push(
					...(invoiceLine.BH_BP_Specific_Payer_InfoList?.filter(
						(businessPartnerSpecificPayerInformation) =>
							!businessPartnerSpecificPayerInformationUUsToSave.includes(businessPartnerSpecificPayerInformation.UU),
					).map((businessPartnerSpecificPayerInformation) => businessPartnerSpecificPayerInformation.UU) || []),
				);
				return invoiceLine.UU;
			}) || []),
	);
	businessPartnerSpecificPayerInformationUUsToDelete.push(
		...(invoicesThatCanBeSavedOrDeleted
			.flatMap((invoice) => invoice.C_InvoiceLines || [])
			.flatMap((invoiceLine) => invoiceLine.BH_BP_Specific_Payer_InfoList || [])
			.filter(
				(businessPartnerSpecificPayerInformation) =>
					!businessPartnerSpecificPayerInformationUUsToSave.includes(businessPartnerSpecificPayerInformation.UU),
			)
			.map((businessPartnerSpecificPayerInformation) => businessPartnerSpecificPayerInformation.UU) || []),
	);
	// Make sure the UUs to delete are unique
	invoiceUUsToDelete = [...new Set(invoiceUUsToDelete)];
	invoiceLineUUsToDelete = [...new Set(invoiceLineUUsToDelete)];
	businessPartnerSpecificPayerInformationUUsToDelete = [...new Set(businessPartnerSpecificPayerInformationUUsToDelete)];

	return [
		{ save: invoicesToSave, delete: invoiceUUsToDelete },
		{ save: invoiceLinesToSave, delete: invoiceLineUUsToDelete },
		{ save: businessPartnerSpecificPayerInformationToSave, delete: businessPartnerSpecificPayerInformationUUsToDelete },
		{ save: paymentsToSave, delete: paymentUUsToDelete },
	];
};

const tenderTypesVariables: Ad_Ref_ListTenderTypesQueryVariables = {
	Sort: JSON.stringify([['name', 'asc']]),
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.TENDER_TYPES)
		.up()
		.toString(),
};
const nonPatientPayerCategoryVariables: Ad_Ref_ListTenderTypesForVisitsQueryVariables = {
	Sort: JSON.stringify([['name', 'asc']]),
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.NON_PATIENT_PAYER_CATEGORY)
		.up()
		.toString(),
};
const visitInsurersAndDonorsVariables: C_BPartnerForVisitsInsurersAndDonorsQueryVariables = {
	Page: Paging.ALL.page,
	Size: Paging.ALL.size,
	Sort: JSON.stringify([['name', 'asc']]),
	Filter: DBFilter<BusinessPartnerDB>()
		.nested('c_bp_group')
		.and(businessPartnerGroupInsurerAndDonorFilter())
		.up()
		.property('isactive')
		.equals(true)
		.toString(),
};
const chargeWaiverVariables: C_ChargeForVisitsWaiversQueryVariables = {
	Size: 1,
	Sort: JSON.stringify([['created', 'asc']]),
	Filter: DBFilter<ChargeDB>()
		.property('isactive')
		.equals(true)
		.property('bh_subtype')
		.equals(chargeSubType.Waiver)
		.toString(),
};
const fetchARInvoiceDocumentTypeArguments = [documentBaseType.ARInvoice, null, null, true, false, false] as const;
const fetchReceiptDocumentTypeArguments = [documentBaseType.ARReceipt, null, null, true, false, false] as const;
const getBankAccountFilterArguments = (organization: Organization) =>
	DBFilter<BaseMetadataDB>().nested('ad_org').property('ad_org_uu').equals(organization.uuid).up().toString();
export const primePaymentLineItemTableData = (graphqlClient: ApolloClient<object>, organization: Organization) =>
	Promise.all([
		graphqlClient.query({
			query: Ad_Ref_ListTenderTypesForVisitsDocument,
			variables: tenderTypesVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({
			query: Ad_Ref_ListNonPatientPayerCategoriesForVisitsDocument,
			variables: nonPatientPayerCategoryVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({
			query: C_BPartnerForVisitsInsurersAndDonorsDocument,
			variables: visitInsurersAndDonorsVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({
			query: C_ChargeForVisitsWaiversDocument,
			variables: chargeWaiverVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient
			.query({
				query: C_DocTypeForFormsDocument,
				variables: { Filter: getDocumentBaseTypeFilter(...fetchARInvoiceDocumentTypeArguments).toString() },
				fetchPolicy: 'cache-first',
			})
			.then((response) => response.data.C_DocTypeGet.Results[0]),
		graphqlClient
			.query({
				query: C_DocTypeForFormsDocument,
				variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
				fetchPolicy: 'cache-first',
			})
			.then((response) => response.data.C_DocTypeGet.Results[0]),
		graphqlClient.query({
			query: C_BankAccountForPaymentsDocument,
			variables: {
				Sort: JSON.stringify([['created', 'asc']]),
				Filter: getBankAccountFilterArguments(organization),
			},
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({ query: C_AcctSchemaForCurrencyUseDocument, fetchPolicy: 'cache-first' }),
	]);

type PaymentLineItemTableProps = {
	readOnly: boolean;
	isVisitFinished: boolean;
};

const PaymentLineItemTable = ({ readOnly, isVisitFinished }: PaymentLineItemTableProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<VisitFormValues>();
	const addPaymentInformationUuid = useWatch<PaymentLineItemTableFormValues, 'addPaymentInformation.UU'>({
		name: 'addPaymentInformation.UU',
	});
	const { fields, append, remove } = useFieldArray<PaymentLineItemTableFormValues, 'paymentInformationList', 'UU'>({
		name: 'paymentInformationList',
		keyName: 'UU',
	});
	const patientUU = useWatch<VisitFormValues, 'Patient.UU'>({ name: 'Patient.UU' });
	const { triggerUpdate, willTrigger } = useTriggerUpdate();
	const shouldDisplayTheDeleteColumn = !readOnly;

	const paymentTypes = graphqlClient.readQuery({
		query: Ad_Ref_ListTenderTypesDocument,
		variables: tenderTypesVariables,
	})?.AD_Ref_ListGet.Results;
	const allSubTypes = graphqlClient.readQuery({
		query: Ad_Ref_ListNonPatientPayerCategoriesForVisitsDocument,
		variables: nonPatientPayerCategoryVariables,
	})?.AD_Ref_ListGet.Results;
	const insuranceAndDonorList = graphqlClient.readQuery({
		query: C_BPartnerForVisitsInsurersAndDonorsDocument,
		variables: visitInsurersAndDonorsVariables,
	})?.C_BPartnerGet.Results;
	const waiver = graphqlClient.readQuery({
		query: C_ChargeForVisitsWaiversDocument,
		variables: chargeWaiverVariables,
	})?.C_ChargeGet.Results[0];
	// We need a way to trigger charge refreshes, so the chargeUpdateCount is here to help that (the condition should
	// always evaluate to true)
	const { value: patientCharges } = useAsync(async () => {
		if (!isVisitFinished && patientUU) {
			return (
				await graphqlClient.query({
					query: Bh_Bp_Payer_InfoGetForVisitPatientDocument,
					variables: {
						Filter: DBFilter<BusinessPartnerPayerInformationDB>()
							.nested('c_bpartner::c_bpartner_id->c_bpartner_id')
							.property('c_bpartner_uu')
							.equals(patientUU)
							.up()
							.toString(),
					},
					fetchPolicy: 'network-only',
				})
			).data.BH_BP_Payer_InfoGet.Results;
		}
		return undefined;
	}, [patientUU, willTrigger, isVisitFinished, graphqlClient]);

	const subTypes = useMemo(
		() =>
			allSubTypes?.filter(
				(subType) =>
					subType.Value === businessPartnerGroupSubType.Waiver ||
					insuranceAndDonorList?.some((businessPartner) => businessPartner.C_BP_Group.BH_SubType?.UU === subType.UU),
			),

		[allSubTypes, insuranceAndDonorList],
	);

	// This handles adding a new payment if the user selected a type
	useEffect(() => {
		if (addPaymentInformationUuid && waiver) {
			// Add a new row
			let paymentTypeOrSubType = paymentTypes?.find((paymentType) => paymentType.UU === addPaymentInformationUuid);
			let isPayment = true;
			let isWaiver = false;
			let charge = { UU: '' };
			// If we didn't find it in the payment types, check the sub types
			if (!paymentTypeOrSubType) {
				isPayment = false;
				paymentTypeOrSubType = subTypes?.find((subType) => subType.UU === addPaymentInformationUuid);
				// If we now have a match, it must be a waiver
				if (paymentTypeOrSubType?.Value === businessPartnerGroupSubType.Waiver) {
					charge = waiver;
					isWaiver = true;
				}
			}
			append({
				UU: v4(),
				BH_BP_Specific_Payer_InfoList: [],
				C_Charge: { UU: charge.UU },
				Description: getValues('addPaymentInformation.Description'),
				PayAmt: getValues('addPaymentInformation.PayAmt'),
				Payer: { UU: '' },
				PaymentType: { UU: paymentTypeOrSubType?.UU || '' },
				isPayment: isPayment ? 'true' : 'false',
				isWaiver: isWaiver ? 'true' : 'false',
			});
			setValue('addPaymentInformation', { UU: '', Description: null, PayAmt: null });
		}
	}, [addPaymentInformationUuid, append, setValue, subTypes, paymentTypes, getValues, insuranceAndDonorList, waiver]);

	return (
		<fieldset disabled={readOnly}>
			<table className="bh-table--form">
				<thead>
					<tr>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.TYPE)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.payment.table.AMOUNT_PAID)}</th>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.DESCRIPTION)}</th>
						{shouldDisplayTheDeleteColumn ? (
							<th className="data-type-action print__d-none">{t(uiText.visit.button.DELETE)}</th>
						) : null}
					</tr>
				</thead>
				<tbody>
					{fields.map((field, index) => (
						<PaymentInformationRow
							key={field.UU}
							field={field}
							index={index}
							remove={remove}
							subTypes={subTypes}
							paymentTypes={paymentTypes}
							insuranceAndDonorList={insuranceAndDonorList}
							waiver={waiver}
							patientPayerInformationList={patientCharges}
							onChargesUpdated={() => triggerUpdate()}
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isVisitFinished={isVisitFinished}
						/>
					))}
				</tbody>
				{readOnly && !fields.length && (
					<tbody>
						<tr>
							<td>
								<Form.Select aria-label={t(uiText.visit.form.payment.table.TYPE)} defaultValue={''}>
									<option value=""></option>
								</Form.Select>
							</td>
							<td>
								<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} />
							</td>

							<td>
								<Form.Control defaultValue={''} />
							</td>
						</tr>
					</tbody>
				)}
				{!readOnly && (
					<tbody>
						<tr>
							<td>
								<Form.Select
									aria-label={t(uiText.visit.form.payment.table.TYPE)}
									{...register('addPaymentInformation.UU')}
								>
									<option value=""></option>
									{sortPaymentTypesGraphQL(paymentTypes?.filter((paymentType) => paymentType.IsActive) || []).map(
										(paymentType) => (
											<option key={paymentType.UU} value={paymentType.UU}>
												{paymentType.Name}
											</option>
										),
									)}
									{subTypes
										?.filter((paymentType) => paymentType.IsActive)
										.map((subType) => (
											<option key={subType.UU} value={subType.UU}>
												{subType.Name}
											</option>
										))}
								</Form.Select>
							</td>
							<td>
								<Controller
									name={'addPaymentInformation.PayAmt'}
									render={({ field }) => (
										<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} {...field} />
									)}
								/>
							</td>

							<td>
								<Form.Control {...register('addPaymentInformation.Description')} />
							</td>
						</tr>
					</tbody>
				)}
				<tfoot>
					<PaymentLineItemTableFooter shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn} />
				</tfoot>
			</table>
			{errors.paymentInformationList?.some((paymentInformation) => !!paymentInformation?.PayAmt) && (
				<div className="text-danger">{t(uiText.payment.error.MISSING_AMOUNT)}</div>
			)}
			{errors.paymentInformationList?.some((paymentInformation) => !!paymentInformation?.Payer?.UU) && (
				<div className="text-danger">{t(uiText.payment.error.MISSING_INSURER_OR_DONOR)}</div>
			)}
		</fieldset>
	);
};

export default PaymentLineItemTable;
