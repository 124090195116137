import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { C_BPartnerForVisitInfoDocument } from '../../graphql/__generated__/graphql';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { getGraphQLAgeDisplay } from '../../utils/ModelUtils';
import { formatNumber } from '../../utils/NumberUtil';
import { VisitFormValues } from './VisitForm';

const VisitInfo = () => {
	const { t } = useTranslation();
	const patientUU = useWatch<VisitFormValues, 'Patient.UU'>({ name: 'Patient.UU' });
	const [fetchPatientData, { data: patient }] = useLazyQuery(C_BPartnerForVisitInfoDocument, {
		fetchPolicy: 'cache-and-network',
	});
	useEffect(() => {
		if (patientUU) {
			fetchPatientData({ variables: { UU: patientUU } });
		}
	}, [fetchPatientData, patientUU]);
	return (
		<div className="info-ribbon sticky-top print__position-relative">
			{patientUU && patient?.C_BPartner?.UU === patientUU && (
				<Row>
					<Col xs={12}>
						<span className="info-ribbon__entity-prop font-size">
							<strong>{patient.C_BPartner.Name}</strong>
						</span>
						<span>
							(
							<span className="info-ribbon__entity-prop">
								<strong>{t(uiText.visit.info.PATIENT_NUMBER_ON_RIBBON)}</strong>
							</span>{' '}
							{patient.C_BPartner.BH_PatientID || '-'})
						</span>
						<span> - {getGraphQLAgeDisplay(patient.C_BPartner.BH_Birthday)}</span>
					</Col>
					<Col>
						<span className="info-ribbon__entity-prop">
							<strong>{t(uiText.visit.info.TOTAL_VISITS)}</strong>
						</span>
						<span> {patient.C_BPartner.TotalVisits || '-'}</span>
					</Col>
					<Col>
						<span className="info-ribbon__entity-prop">
							<strong>{t(uiText.visit.info.LAST_VISIT)}</strong>
						</span>
						<span>
							{' '}
							{patient.C_BPartner.LastVisitDate ? formatDate(new Date(patient.C_BPartner.LastVisitDate)) : '-'}
						</span>
					</Col>
					<Col>
						<span className="info-ribbon__entity-prop">
							<strong>{t(uiText.visit.info.OPEN_BALANCE)}</strong>
						</span>
						<span>
							{' '}
							{patient.C_BPartner.TotalOpenBalance !== undefined
								? formatNumber(patient.C_BPartner.TotalOpenBalance)
								: '-'}
						</span>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default VisitInfo;
