import { useApolloClient } from '@apollo/client';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Bh_VisitDocumentNumberFragmentDoc } from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { PaymentLineItemTableFormValues } from './PaymentLineItemTable';
import { VisitFormValues } from './VisitForm';

type PaymentLineItemTableFooterProps = {
	shouldDisplayTheDeleteColumn: boolean;
};

const PaymentLineItemTableFooter = ({ shouldDisplayTheDeleteColumn }: PaymentLineItemTableFooterProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const visitOrderLines =
		useWatch<VisitFormValues, 'C_Orders.0.C_OrderLines'>({ name: 'C_Orders.0.C_OrderLines' }) || [];
	const addOrderLine = useWatch<VisitFormValues, 'addNewOrderLine'>({ name: 'addNewOrderLine' });
	const paymentInformationList =
		useWatch<PaymentLineItemTableFormValues, 'paymentInformationList'>({ name: 'paymentInformationList' }) || [];
	const addPaymentInformation = useWatch<PaymentLineItemTableFormValues, 'addPaymentInformation'>({
		name: 'addPaymentInformation',
	});
	const orderLineTotal = [...visitOrderLines, addOrderLine].reduce(
		(runningTotal, orderLine) => runningTotal + (orderLine.QtyEntered || 0) * (orderLine.PriceEntered || 0),
		0,
	);
	const paymentLineTotal = [...paymentInformationList, addPaymentInformation].reduce(
		(runningTotal, paymentInformation) => runningTotal + (paymentInformation.PayAmt || 0),
		0,
	);
	const visitNumber = graphqlClient.readFragment({
		id: useWatch<VisitFormValues, 'UU'>({ name: 'UU' }),
		fragment: Bh_VisitDocumentNumberFragmentDoc,
	})?.DocumentNo;

	const billBalance = orderLineTotal - paymentLineTotal;

	return (
		<>
			<tr>
				<td className="align-middle text-end pe-2">
					<strong>{t(uiText.visit.BALANCE_DUE)}</strong>
				</td>
				<td>
					<FormatNumberInput
						name="payAmount"
						readOnly={true}
						value={billBalance < 0 ? 0 : billBalance}
						tabIndex={-1}
						aria-label={t(uiText.visit.BALANCE_DUE)}
					/>
				</td>
				<td className="d-none"></td>
				{shouldDisplayTheDeleteColumn ? <td className="d-none"></td> : null}
			</tr>
			<tr>
				<td className="align-middle text-end pe-2">
					<strong>{t(uiText.visit.CHANGE_DUE)}</strong>
				</td>
				<td>
					<FormatNumberInput
						name="changeDue"
						readOnly={true}
						value={billBalance < 0 ? billBalance * -1 : 0}
						tabIndex={-1}
					/>
				</td>
				<td className="align-middle text-end pe-2" colSpan={shouldDisplayTheDeleteColumn ? 2 : 1}>
					<strong>{`${t(uiText.visit.NUMBER)} ${visitNumber || '-'}`}</strong>
				</td>
			</tr>
		</>
	);
};

export default PaymentLineItemTableFooter;
