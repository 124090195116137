import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { PharmacySalesFormValues } from './PharmacySalesForm';
import { PharmacySalesPaymentLineItemTableFormValues } from './PharmacySalesPaymentLineItemTable';

const PharmacySalesPaymentTableFooter = () => {
	const { t } = useTranslation();
	const { setValue } = useFormContext<PharmacySalesPaymentLineItemTableFormValues>();
	const visitOrderLines =
		useWatch<PharmacySalesFormValues, 'C_Orders.0.C_OrderLines'>({
			name: 'C_Orders.0.C_OrderLines',
		}) || [];
	const addOrderLine = useWatch<PharmacySalesFormValues, 'addNewOrderLine'>({ name: 'addNewOrderLine' });
	const payments = useWatch<PharmacySalesPaymentLineItemTableFormValues, 'C_Payments'>({ name: 'C_Payments' }) || [];
	const addPaymentInformation = useWatch<PharmacySalesPaymentLineItemTableFormValues, 'addPaymentInformation'>({
		name: 'addPaymentInformation',
	});
	const orderLineTotal = [...visitOrderLines, addOrderLine].reduce(
		(runningTotal, orderLine) => runningTotal + (orderLine.QtyEntered || 0) * (orderLine.PriceEntered || 0),
		0,
	);
	const paymentLineTotal = [...payments, addPaymentInformation].reduce(
		(runningTotal, paymentInformation) => runningTotal + (paymentInformation.PayAmt || 0),
		0,
	);

	const billBalance = orderLineTotal - paymentLineTotal;
	useEffect(() => {
		setValue('billBalance', billBalance);
	}, [setValue, billBalance]);

	return (
		<>
			<tr>
				<td className="align-middle text-end pe-2">
					<strong>{t(uiText.visit.BALANCE_DUE)}</strong>
				</td>
				<td>
					<FormatNumberInput
						name="payAmount"
						readOnly={true}
						value={billBalance < 0 ? 0 : billBalance}
						tabIndex={-1}
						aria-label={t(uiText.visit.BALANCE_DUE)}
					/>
				</td>
				<td className="d-none" />
				<td className="d-none" />
			</tr>
			<tr>
				<td className="align-middle text-end pe-2">
					<strong>{t(uiText.visit.CHANGE_DUE)}</strong>
				</td>
				<td>
					<FormatNumberInput
						name="changeDue"
						readOnly={true}
						value={billBalance < 0 ? billBalance * -1 : 0}
						tabIndex={-1}
					/>
				</td>
				<td className="align-middle text-end pe-2" colSpan={2} />
			</tr>
		</>
	);
};

export default PharmacySalesPaymentTableFooter;
