import { Fragment, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Ad_Ref_ListProcessStagesForVisitsQuery } from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';

type SaveAndSendToModalProps = {
	processStageList: Ad_Ref_ListProcessStagesForVisitsQuery['AD_Ref_ListGet']['Results'];
	onHandleClose: () => void;
	processStageUU: string | null;
	onHandleSubmit: (newProcessStageUU: string | null) => void;
};

const SaveAndSendToModal = ({
	processStageList,
	onHandleClose,
	processStageUU,
	onHandleSubmit,
}: SaveAndSendToModalProps) => {
	const { t } = useTranslation();
	const [selectedProcessStageUU, setSelectedProcessStageUU] = useState(processStageUU);

	return (
		<Modal show>
			<Modal.Header>{t(uiText.visit.form.patient.SEND_PATIENT_TO)}</Modal.Header>
			<Modal.Body>
				<Form.Group as={Fragment}>
					<Col xs="auto" className="d-flex align-items-center">
						<Form.Select
							aria-label={t(uiText.visit.form.patient.SEND_PATIENT_TO)}
							onChange={(e) => setSelectedProcessStageUU(e.target.value)}
							defaultValue={selectedProcessStageUU || ''}
						>
							<option value="">&nbsp;</option>
							{processStageList.map((processStage) => (
								<option key={processStage.UU} value={processStage.UU}>
									{processStage.Name}
								</option>
							))}
						</Form.Select>
					</Col>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button
					type="button"
					variant="danger"
					onClick={() => {
						onHandleClose();
					}}
				>
					{t(uiText.modal.button.CANCEL)}
				</Button>
				<Button
					type="button"
					variant="success"
					className="ms-auto"
					onClick={() => onHandleSubmit(selectedProcessStageUU)}
				>
					{t(uiText.visit.button.SAVE_BILL)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SaveAndSendToModal;
