import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import UserContext from '../../contexts/UserContext';
import {
	MovementCreatorsDocument,
	M_MovementsListPageGetDocument,
	M_MovementsListPageGetQuery,
	M_WarehousesForMovementsPageDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import DBFilter from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import { MovementDB } from '../../models/Movement';
import { UserDB } from '../../models/User';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { SEARCH } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { getWarehouseByOrg } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import GraphQLStatusBadge from '../StatusBadge/GraphQLStatusBadge';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import TransferInventoryForm from './TransferInventoryForm';

export type MovementLocationState = { toView?: boolean; uuid?: string };

const TransferInventory = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	// General list page state
	const { disableWrite } = useActionPrivileges(pageUuid.TRANSFER_INVENTORY);
	const { state } = useLocation<MovementLocationState | undefined>();
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<M_MovementsListPageGetQuery['M_MovementGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(await graphqlClient.query({ query: M_MovementsListPageGetDocument, variables, fetchPolicy: 'network-only' }))
						.data.M_MovementGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.nonPatientPayment.error.COULD_NOT_LOAD));
					}
					exception({ description: `Transfer Inventory fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			viewState: state?.toView ? ListPageState.ADD_EDIT : undefined,
		},
	);
	const { organization } = useContext(UserContext);

	// Filter and search state
	const [searchText, setSearchText] = useState('');
	const [userFilter, setUserFilter, { reset: resetUserFilter }] = useStateWithReset('');
	const [fromFilter, setFromFilter, { reset: resetFromFilter }] = useStateWithReset('');
	const [toFilter, setToFilter, { reset: resetToFilter }] = useStateWithReset('');
	const { willTrigger, triggerUpdate } = useTriggerUpdate();

	// Filter data
	const { data: warehouseQueryResult, refetch: refetchWarehouses } = useQuery(M_WarehousesForMovementsPageDocument, {
		variables: {
			Page: SEARCH.DEFAULT_PAGE_NUMBER,
			Size: SEARCH.DEFAULT_PAGE_SIZE,
			Filter: getWarehouseByOrg(organization.uuid).toString(),
		},
		fetchPolicy: 'cache-first',
	});
	useUpdateEffect(() => {
		refetchWarehouses();
	}, [organization]);
	const { data: userQueryResult, refetch: refetchUsers } = useQuery(MovementCreatorsDocument, {
		variables: {
			Page: SEARCH.DEFAULT_PAGE_NUMBER,
			Size: SEARCH.DEFAULT_PAGE_SIZE,
			Filter: DBFilter<UserDB>()
				.nested('m_movement::ad_user_id->createdby')
				.property('m_movement_uu')
				.isNotNull()
				.up()
				.toString(),
		},
		fetchPolicy: 'no-cache',
	});
	useUpdateEffect(() => {
		refetchUsers();
	}, [willTrigger, refetchUsers]);

	// Handle searching and filtering
	useEffect(() => {
		let defaultFilter = DBFilter<MovementDB>().and(DBFilter<MovementDB>().property('isactive').equals(true));
		if (userFilter) {
			defaultFilter.and(
				DBFilter<MovementDB>().nested('ad_user::createdby->ad_user_id').property('ad_user_uu').equals(userFilter).up(),
			);
		}

		if (fromFilter) {
			defaultFilter.and(
				DBFilter<MovementDB>().property('m_warehouse::bh_from_warehouse_id.m_warehouse_uu').equals(fromFilter),
			);
		}

		if (toFilter) {
			defaultFilter.and(
				DBFilter<MovementDB>().property('m_warehouse::bh_to_warehouse_id.m_warehouse_uu').equals(toFilter),
			);
		}

		if (searchText) {
			defaultFilter.and(
				DBFilter<MovementDB>()
					.or(
						DBFilter<MovementDB>().nested('ad_user::createdby->ad_user_id').property('name').contains(searchText).up(),
					)
					.or(
						DBFilter<MovementDB>()
							.nested('m_movementline')
							.nested('m_product')
							.property('name')
							.contains(searchText)
							.up()
							.up(),
					),
			);
		}
		onFilterUpdate(defaultFilter.toString());
	}, [searchText, userFilter, fromFilter, toFilter, onFilterUpdate]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetUserFilter();
		resetFromFilter();
		resetToFilter();
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.transferInventory.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="userFilter">
									<Form.Label column>{t(uiText.transferInventory.filter.USER)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={userFilter}
										onChange={(e) => setUserFilter(e.target.value)}
									>
										<option value={''}>{t(uiText.transferInventory.filter.ALL)}</option>
										{userQueryResult?.AD_UserGet.Results?.map((user) => (
											<option key={user.UU} value={user.UU}>
												{user.Name}
											</option>
										))}
									</Form.Select>
								</Form.Group>
								<Form.Group controlId="fromWarehouseFilter">
									<Form.Label column>{t(uiText.transferInventory.filter.FROM)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={fromFilter}
										onChange={(e) => setFromFilter(e.target.value)}
									>
										<option value={''}>{t(uiText.transferInventory.filter.ALL)}</option>
										{warehouseQueryResult?.M_WarehouseGet.Results.map((warehouse) => (
											<option key={warehouse.UU} value={warehouse.UU}>
												{warehouse.Name}
											</option>
										))}
									</Form.Select>
								</Form.Group>
								<Form.Group controlId="toWarehouseFilter">
									<Form.Label column>{t(uiText.transferInventory.filter.TO)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={toFilter}
										onChange={(e) => setToFilter(e.target.value)}
									>
										<option value={''}>{t(uiText.transferInventory.filter.ALL)}</option>
										{warehouseQueryResult?.M_WarehouseGet.Results.map((warehouse) => (
											<option key={warehouse.UU} value={warehouse.UU}>
												{warehouse.Name}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<M_MovementsListPageGetQuery['M_MovementGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'movementDate',
										Header: () => <div className={'React-table-header'}>{t(uiText.transferInventory.labels.DATE)}</div>,
										accessor: (m) => formatDate(new Date(m.MovementDate)),
									},
									{
										id: 'user',
										Header: () => (
											<div className={'React-table-header'}>
												{t(uiText.transferInventory.labels.USER_MAKING_TRANSFER)}
											</div>
										),
										accessor: (m) => m.CreatedBy.Name,
										disableSortBy: true,
									},
									{
										id: 'bh_from_warehouse_id',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.transferInventory.labels.FROM_STOREROOM)}</div>
										),
										accessor: (m) => m.BH_From_Warehouse?.Name,
									},
									{
										id: 'bh_to_warehouse_id',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.transferInventory.labels.TO_STOREROOM)}</div>
										),
										accessor: (m) => m.BH_To_Warehouse?.Name,
									},
									{
										id: 'docStatus',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.transferInventory.labels.STATUS)}</div>
										),
										accessor: (d) => <GraphQLStatusBadge documentStatus={d.DocStatus} />,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => <LoadSpinner show={isLoading} title={t(uiText.transferInventory.LOADING)} />}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<TransferInventoryForm
					uuid={state?.uuid || selectedUuid}
					onFinish={(refreshData?: boolean) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
							triggerUpdate();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default TransferInventory;
