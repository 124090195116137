import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import ReferenceList, { ReferenceListDto } from './ReferenceList';
import Window, { WindowDB, WindowDto } from './Window';

export enum EncounterTypeWindowUU {
	ClinicialDetails = '2f613d49-d52b-4672-87ed-a30ef9f16c89',
	ChiefComplaint = 'cc62f9eb-2d59-48da-839e-d093a3f2c0e3',
	LabDiagnosticDetails = 'cc1987df-8c35-4970-bde8-a3410a99b683',
	TriageDetails = 'cf6781bd-5ed0-4cd1-9bbf-97758f40c83b',
}

export interface EncounterTypeWindowDto extends BaseMetadataDto {
	encounterType?: ReferenceListDto | null;
	window?: WindowDto | null;
}

export interface EncounterTypeWindowDB extends BaseMetadataDB {
	ad_window: WindowDB;
}

export default class EncounterTypeWindow extends BaseMetadata {
	encounterType?: ReferenceList;
	window?: Window;

	constructor(props: Partial<EncounterTypeWindowDto | EncounterTypeWindow> = {}) {
		props = cloneDeep(props);
		super(props);

		this.encounterType = props.encounterType ? new ReferenceList(props.encounterType) : undefined;
		this.window = props.window ? new Window(props.window) : undefined;
	}
}
