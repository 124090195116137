import { ApolloClient } from '@apollo/client';
import {
	Ad_FieldForObservationsFragment,
	Ad_FieldForObservationsFragmentDoc,
} from '../../graphql/__generated__/graphql';
import { ObservationTableFormValues } from './ObservationTable';

type ObservationDisplay = ObservationIndex | ObservationGroupIndex;
export type ObservationIndex = {
	index: number;
	observation: ObservationTableFormValues['vitalsEncounters'][0]['BH_Observations'][0];
	field: Ad_FieldForObservationsFragment;
};
type ObservationGroupIndex = { index: number; observations: ObservationIndex[] };

export function isFieldGroup(observationDisplay: any): observationDisplay is ObservationGroupIndex {
	return observationDisplay?.observations?.length > 0;
}

export function getObservationFields(
	graphqlClient: ApolloClient<object>,
	observations?: ObservationTableFormValues['vitalsEncounters'][0]['BH_Observations'],
) {
	return Object.values(
		observations?.reduce(
			(observationGroups, observation, observationIndex) => {
				const field = graphqlClient.readFragment({
					id: observation.AD_Field.UU,
					fragment: Ad_FieldForObservationsFragmentDoc,
				});
				if (field?.AD_FieldGroup?.UU) {
					if (!observationGroups[field.AD_FieldGroup.UU]) {
						observationGroups[field.AD_FieldGroup.UU] = { index: observationIndex, observations: [] };
					}
					(observationGroups[field.AD_FieldGroup.UU] as unknown as ObservationGroupIndex).observations.push({
						index: observationIndex,
						observation,
						field,
					});
				} else if (field?.UU) {
					observationGroups[field?.UU] = { index: observationIndex, observation, field };
				}
				return observationGroups;
			},
			{} as { [key: string]: ObservationDisplay },
		) || {},
	).sort((observationDisplayA, observationDisplayB) =>
		observationDisplayA.index < observationDisplayB.index
			? -1
			: observationDisplayA.index > observationDisplayB.index
				? 1
				: 0,
	);
}
