import { startOfDay } from 'date-fns';
import {
	AttributeSetInstanceDB,
	BaseEntityDB,
	BusinessPartnerDB,
	businessPartnerGroupName,
	CodedDiagnosisDB,
	ConceptClass,
	ConceptDB,
	ConceptExtraKey,
	ConceptMappingMapType,
	DBFilter,
	Locator,
	ProductDB,
	ProductTypeValue,
	StorageOnHandDB,
	WarehouseDB,
} from '../models';

export function getCodedDiagnosisDefaultSearchFilter(query: string) {
	return (
		DBFilter<CodedDiagnosisDB>()
			.property('isactive')
			.equals(true)
			.or(
				DBFilter<CodedDiagnosisDB>()
					.nested('bh_coded_diagnosis_mapping')
					.or(DBFilter<CodedDiagnosisDB['bh_coded_diagnosis_mapping']>().property('bh_concept_code').contains(query))
					.or(
						DBFilter<CodedDiagnosisDB['bh_coded_diagnosis_mapping']>()
							.property('bh_concept_name_resolved')
							.contains(query),
					)
					.up(),
			)
			.or(DBFilter<CodedDiagnosisDB>().property('bh_cielname').contains(query))
			.or(DBFilter<CodedDiagnosisDB>().property('bh_icd10who').contains(query))
			.or(DBFilter<CodedDiagnosisDB>().property('bh_synonyms').contains(query))
			// .or(DBFilter<CodedDiagnosisDB>().property('bh_ciel_id').contains(parseInt(query, 10)))
			.or(DBFilter<CodedDiagnosisDB>().property('bh_searchterms').contains(query))
	);
}

export function getConceptTestsAndPanelsSearchFilter(query?: string) {
	let filter = DBFilter<ConceptDB>()
		.property('isactive')
		.equals(true)
		// Concept class of Test or Panel
		.and(
			DBFilter<ConceptDB>()
				.or(DBFilter<ConceptDB>().property('bh_concept_class').equals(ConceptClass.TEST))
				.or(DBFilter<ConceptDB>().property('bh_concept_class').equals(ConceptClass.LAB_SET)),
		);

	if (query) {
		filter = filter
			// Use the given search query to look through a variety of places
			.and(
				DBFilter<ConceptDB>()
					.or(DBFilter<ConceptDB>().property('bh_display_name').contains(query))
					// Check for a local_name extra attached to the concept
					.or(
						DBFilter<ConceptDB>()
							.nested('bh_concept_extra')
							.property('bh_key')
							.equals(ConceptExtraKey.LOCAL_NAME)
							.property('bh_value')
							.contains(query)
							.up(),
					)
					// Check for a local_name extra attached to the parent mapping (which is normal for tests controlled by CIEL)
					.or(
						DBFilter<ConceptDB>()
							.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
							.property('bh_map_type')
							.equals(ConceptMappingMapType.CONCEPT_SET)
							.nested('bh_concept_extra')
							.property('bh_key')
							.equals(ConceptExtraKey.LOCAL_NAME)
							.property('bh_value')
							.contains(query)
							.up()
							.up(),
					),
			);
	}

	return filter;
}

export function getConceptTestsWithinPanelFilter(panelUuid: string) {
	return DBFilter<ConceptDB>()
		.property('isactive')
		.equals(true)
		.property('bh_concept_class')
		.equals(ConceptClass.TEST)
		.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
		.property('bh_map_type')
		.equals(ConceptMappingMapType.CONCEPT_SET)
		.nested('bh_concept')
		.property('bh_concept_class')
		.equals(ConceptClass.LAB_SET)
		.property('bh_concept_uu')
		.equals(panelUuid)
		.up()
		.up();
}

export function getDefaultSearchFilter(query: string) {
	return DBFilter<BaseEntityDB>().property('name').contains(query);
}

export function getActiveServicesAndProductsWithInventory(query: string, locator?: Locator) {
	let storageOnHandFilter = DBFilter<StorageOnHandDB>()
		.nested('m_attributesetinstance')
		.or(DBFilter<AttributeSetInstanceDB>().property('guaranteeDate').isNull())
		.or(DBFilter<AttributeSetInstanceDB>().property('guaranteeDate').isGreaterThanOrEqualTo(startOfDay(new Date())))
		.up();
	if (locator) {
		storageOnHandFilter.nested('m_locator').property('m_locator_uu').equals(locator.uuid).up();
	}
	return DBFilter<ProductDB>()
		.or(DBFilter<ProductDB>().property('producttype').equals(ProductTypeValue.Service).property('name').contains(query))
		.or(
			DBFilter<ProductDB>()
				.property('producttype')
				.equals(ProductTypeValue.Item)
				.property('name')
				.contains(query)
				.nested('m_storageonhand')
				.countOf('qtyonhand', storageOnHandFilter)
				.isGreaterThan(0)
				.up(),
		);
}

export function getWarehouseByOrg(organizationUuid: string) {
	return DBFilter<WarehouseDB>()
		.and(DBFilter<WarehouseDB>().property('AD_Org.AD_Org_UU').equals(organizationUuid))
		.and(DBFilter<WarehouseDB>().property('name').doesNotEqual('Standard'))
		.and(DBFilter<WarehouseDB>().property('isactive').equals(true));
}

export function getConceptTestSearchFilter(searchName: string) {
	return (
		DBFilter<ConceptDB>()
			.property('bh_concept_class')
			.equals(ConceptClass.TEST)
			// search in display name (which can be scientific name)
			.or(DBFilter<ConceptDB>().property('bh_display_name').contains(searchName))
			// search in any client concepts
			.or(DBFilter<ConceptDB>().nested('bh_client_concept').property('name').contains(searchName).up())
			// search in local name attributes attached to the concept
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_extra')
					.property('bh_key')
					.equals(ConceptExtraKey.LOCAL_NAME)
					.property('bh_value')
					.contains(searchName)
					.up(),
			)
			// search in local name attributes attached to the mapping to parent
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
					.property('bh_map_type')
					.equals(ConceptMappingMapType.CONCEPT_SET)
					.nested('bh_concept_extra')
					.property('bh_key')
					.equals(ConceptExtraKey.LOCAL_NAME)
					.property('bh_value')
					.contains(searchName)
					.up()
					.up(),
			)
			// search in panel display name
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
					.property('bh_map_type')
					.equals(ConceptMappingMapType.CONCEPT_SET)
					.nested('bh_concept')
					.property('bh_concept_class')
					.equals(ConceptClass.LAB_SET)
					.property('bh_display_name')
					.contains(searchName)
					.up()
					.up(),
			)
			// search in panel local name
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
					.property('bh_map_type')
					.equals(ConceptMappingMapType.CONCEPT_SET)
					.nested('bh_concept')
					.property('bh_concept_class')
					.equals(ConceptClass.LAB_SET)
					.nested('bh_concept_extra')
					.property('bh_key')
					.equals(ConceptExtraKey.LOCAL_NAME)
					.property('bh_value')
					.contains(searchName)
					.up()
					.up()
					.up(),
			)
	);
}

export function getBusinessPartnerDefaultSearchFilter(searchName: string) {
	return DBFilter<BusinessPartnerDB>().property('name').contains(searchName);
}

export function getPatientSearchFilter(query: string) {
	return DBFilter<BusinessPartnerDB>()
		.nested('c_bp_group')
		.property('name')
		.equals(businessPartnerGroupName.PATIENTS)
		.up()
		.and(
			DBFilter<BusinessPartnerDB>()
				.or(DBFilter<BusinessPartnerDB>().property('name').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('BH_PatientID').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('bh_phone').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('BH_Local_PatientID').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('nextofkin_contact').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('nationalid').contains(query)),
		)
		.and(DBFilter<BusinessPartnerDB>().property('isactive').equals(true));
}
