import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import {
	M_ProductCategoriesForProductsDocument,
	M_ProductCategoriesForProductsQuery,
	M_ProductForProductListPageDocument,
	M_ProductForProductListPageQuery,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import {
	DBFilter,
	Filter,
	ListPageState,
	Product as ProductModel,
	ProductCategoryDB,
	ProductCategoryType,
	ProductDB,
	ProductType,
} from '../../models';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { getManualModelPriceMargin } from '../../utils/ModelUtils';
import { formatNumber } from '../../utils/NumberUtil';
import BHGraphQLTable, { BHGraphQLTableProps } from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import ProductForm from './ProductForm';

export const setLocationState = (redirectFromSave: boolean, response: ProductModel) => {
	return { fromSave: redirectFromSave, data: response };
};

export const PRICE_MARGIN_SORT = 'calc_priceMargin';
const sortedMap = { [PRICE_MARGIN_SORT]: 'm_product.bh_sellprice - product_costs.purchase_price' } as const;
const sortedReverseMap = Object.entries(sortedMap).reduce((map: any, [tableSort, dbSort]) => {
	map[dbSort] = tableSort;
	return map;
}, {});

const availableFilters = {
	[uiText.product.filter.INACTIVE]: DBFilter<ProductDB>().property('isactive').equals(false),
	[uiText.product.filter.ACTIVE]: IS_ACTIVE as unknown as Filter<ProductDB>,
	[uiText.product.filter.ALL]: IS_ACTIVE as unknown as Filter<ProductDB>,
	categories: {} as { [key: string]: Filter<ProductDB> },
};

const Product = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { disableWrite } = useActionPrivileges(pageUuid.PRODUCTS);

	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid: selectedData,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<M_ProductForProductListPageQuery['M_ProductGet']['Results'][0]>({
		fetch: useCallback(
			async (variables) =>
				(
					await graphqlClient.query({
						query: M_ProductForProductListPageDocument,
						variables: variables,
						fetchPolicy: 'network-only',
					})
				).data.M_ProductGet,
			[graphqlClient],
		),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.product.error.UNABLE_TO_LOAD));
				}
				exception({ description: `Product fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});

	// Filter states
	const [inactiveFilter, setInactiveFilter, { reset: resetInactiveFilter }] = useStateWithReset<
		keyof typeof availableFilters
	>(uiText.product.filter.ACTIVE);
	const [categoriesFilterText, setCategoriesFilterText, { reset: resetCategoriesFilterText }] = useStateWithReset<
		keyof typeof availableFilters
	>(uiText.patient.filter.ALL);

	// Handle searching and filtering
	useEffect(() => {
		let defaultFilter = DBFilter<ProductDB>()
			.and(availableFilters[inactiveFilter] as Filter<ProductDB>)
			.property('producttype')
			.equals(ProductType.ITEM);

		if (searchText) {
			defaultFilter = defaultFilter.and(DBFilter<ProductDB>().property('name').contains(searchText));
		}

		const categoriesFilter = availableFilters.categories[categoriesFilterText];
		if (categoriesFilter && categoriesFilterText !== uiText.product.filter.ALL) {
			defaultFilter = defaultFilter.and(categoriesFilter);
		}
		onFilterUpdate(defaultFilter.toString());
	}, [searchText, onFilterUpdate, categoriesFilterText, inactiveFilter]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetInactiveFilter();
		resetCategoriesFilterText();
		reset();
	});

	const { value: categoryFilters = {} } = useAsync(async () => {
		const productCategories = (
			await graphqlClient.query({
				query: M_ProductCategoriesForProductsDocument,
				variables: {
					Filter: DBFilter<ProductCategoryDB>()
						.property('bh_product_category_type')
						.equals(ProductCategoryType.VALUE_PRODUCT)
						.toString(),
				},
				fetchPolicy: 'cache-first',
			})
		).data.M_Product_CategoryGet.Results;

		availableFilters.categories = productCategories.reduce(
			(
				filterMap: { [key: string]: Filter<ProductDB> },
				category: M_ProductCategoriesForProductsQuery['M_Product_CategoryGet']['Results'][0],
			) => {
				filterMap[category.Name] = DBFilter<ProductDB>().property('m_product_category.name').equals(category.Name);
				return filterMap;
			},
			{} as { [key: string]: Filter<ProductDB> },
		);
		availableFilters.categories = Object.assign(
			{
				[t(uiText.product.filter.ALL)]: IS_ACTIVE,
			},
			availableFilters.categories,
		);
		return availableFilters.categories;
	}, [graphqlClient]);

	type ColumnType = BHGraphQLTableProps<M_ProductForProductListPageQuery['M_ProductGet']['Results'][0]>['columns'][0];
	const columns = useRef([
		{
			id: 'Created',
			Header: () => <div className={'React-table-header'}>{t(uiText.product.tableHeaders.CREATED)}</div>,
			accessor: (d) => formatDate(new Date(d.Created)),
			sortDescFirst: true,
		} as ColumnType,
		{
			id: 'Name',
			Header: () => <div className={'React-table-header'}>{t(uiText.product.tableHeaders.NAME)}</div>,
			accessor: 'Name',
		} as ColumnType,
		{
			id: 'LastPurchasePrice',
			Header: () => <div className={'React-table-header'}>{t(uiText.product.tableHeaders.UNIT_BUY_PRICE)}</div>,
			disableSortBy: true,
			accessor: (d) => <div className="d-flex justify-content-center">{formatNumber(d.LastPurchasePrice)}</div>,
		} as ColumnType,
		{
			id: 'BH_SellPrice',
			Header: () => <div className={'React-table-header'}>{t(uiText.product.tableHeaders.UNIT_SELL_PRICE)}</div>,
			disableSortBy: true,
			accessor: (d) => <div className="d-flex justify-content-center">{formatNumber(d.BH_SellPrice)}</div>,
		} as ColumnType,
		{
			id: PRICE_MARGIN_SORT,
			Header: () => <div className={'React-table-header'}>{t(uiText.product.tableHeaders.PRICE_MARGIN)}</div>,
			accessor: (d) => (
				<div className="d-flex justify-content-center">
					{formatNumber(getManualModelPriceMargin(d.LastPurchasePrice, d.BH_SellPrice))}
				</div>
			),
		} as ColumnType,
	]);

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.product.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="activeFilter" className="mt-0_5">
									<Form.Check
										checked={inactiveFilter === uiText.product.filter.INACTIVE}
										onChange={(e) =>
											setInactiveFilter(
												e.target.checked ? uiText.product.filter.INACTIVE : uiText.product.filter.ACTIVE,
											)
										}
										label={t(uiText.product.filter.INACTIVE)}
									/>
								</Form.Group>
								<Form.Group controlId="categoryFilter">
									<Form.Label column>{t(uiText.product.filter.CATEGORIES)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={categoriesFilterText}
										onChange={(e) => setCategoriesFilterText(e.target.value)}
									>
										{Object.entries(categoryFilters).map(([filter]) => (
											<option key={filter} value={filter}>
												{filter}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<M_ProductForProductListPageQuery['M_ProductGet']['Results'][0]>
								data={data}
								columns={columns.current}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => <LoadSpinner show={isLoading} title={t(uiText.product.LOADING_TEXT)} />}
								onFetchData={(data) => {
									if (data.sorted) {
										data.sorted = JSON.stringify(
											(JSON.parse(data.sorted) as Array<[string, string]>).map(([id, desc]) =>
												id in sortedMap ? [sortedMap[id as keyof typeof sortedMap], desc] : [id, desc],
											),
										);
									}
									onTableUpdate(data);
								}}
								getTrGroupProps={rowProperties}
								sortBy={
									(sorted &&
										JSON.stringify(
											(JSON.parse(sorted) as Array<[string, string]>).map(([id, desc]) =>
												sortedReverseMap[id] ? [sortedReverseMap[id], desc] : [id, desc],
											),
										)) ||
									''
								}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<ProductForm
					uuid={selectedData}
					onFinish={(refreshData?: boolean) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default Product;
