import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Organization, { OrganizationDto } from './Organization';

export enum ClientUU {
	System = '11237b53-9592-4af1-b3c5-afd216514b5d',
}

export interface ClientDB extends BaseEntityDB {
	ad_client_uu: string;
}

export interface ClientDto extends BaseEntityDto {
	organizations?: OrganizationDto[];
}

export default class Client extends BaseEntity {
	organizations: Organization[];

	constructor(props?: Partial<Client | ClientDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.organizations = (props.organizations || []).map((organization) => new Organization(organization));
	}
}
