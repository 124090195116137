import { ApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_Encounter_DiagnosisInput,
	Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
	Bh_VisitForEditingQuery,
} from '../../graphql/__generated__/graphql';
import { EncounterDiagnosis, EncounterTypeWindowUU } from '../../models';
import { uiText } from '../../utils/Language';
import { ClinicalDetailsFormValues } from './ClinicalDetails';
import DiagnosisTableRow from './DiagnosisTableRow';

export type DiagnosisTableFormValues = {
	encounterDiagnoses: Array<{
		UU: string;
		BH_Coded_Diagnosis: { UU: string };
		BH_Uncoded_Diagnosis: string | null;
	}>;
	addNewDiagnosis: {
		BH_Coded_Diagnosis: { UU: string };
		BH_Uncoded_Diagnosis: string | null;
	};
	encounterDiagnosesPrimary: string | null;
};

export const convertToDiagnosisTableFormValues: (
	graphqlClient: ApolloClient<object>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
) => DiagnosisTableFormValues = (graphqlClient, initialData) => {
	const clinicalDetailsEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.ClinicialDetails,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	});
	let commonData: Pick<DiagnosisTableFormValues, 'addNewDiagnosis' | 'encounterDiagnosesPrimary'> = {
		addNewDiagnosis: { BH_Coded_Diagnosis: { UU: '' }, BH_Uncoded_Diagnosis: null },
		encounterDiagnosesPrimary: '0',
	};
	if (!clinicalDetailsEncounterTypeWindow || !initialData) {
		return {
			encounterDiagnoses: [],
			...commonData,
		};
	}
	return {
		encounterDiagnoses: sortBy(
			initialData.BH_Encounters?.filter(
				(encounter) => encounter.BH_Encounter_Type.UU === clinicalDetailsEncounterTypeWindow.BH_Encounter_Type.UU,
			).flatMap((encounter) => encounter.BH_Encounter_DiagnosisList || []) || [],
			'LineNo',
		).map((encounterDiagnosis) => ({
			UU: encounterDiagnosis.UU,
			BH_Coded_Diagnosis: { UU: encounterDiagnosis.BH_Coded_Diagnosis?.UU || '' },
			BH_Uncoded_Diagnosis: encounterDiagnosis.BH_Uncoded_Diagnosis || null,
		})),
		...commonData,
	};
};

export const constructDiagnosisTableFormDataToSave = (
	data: ClinicalDetailsFormValues,
): Bh_Encounter_DiagnosisInput[] => {
	// For some reason, the encounter diagnoses are randomly undefined despite their type not allowing that
	return (
		data.encounterDiagnoses
			?.filter(
				(encounterDiagnosis) => !!encounterDiagnosis.BH_Coded_Diagnosis.UU || !!encounterDiagnosis.BH_Uncoded_Diagnosis,
			)
			.map((encounterDiagnosis, index) => ({
				UU: encounterDiagnosis.UU,
				BH_Coded_Diagnosis: encounterDiagnosis.BH_Coded_Diagnosis.UU
					? { UU: encounterDiagnosis.BH_Coded_Diagnosis.UU }
					: null,
				BH_Encounter: { UU: data.clinicalDetailsEncounter.UU },
				BH_Uncoded_Diagnosis: encounterDiagnosis.BH_Uncoded_Diagnosis || null,
				IsActive: true,
				LineNo: index,
			})) || []
	);
};

type DiagnosisTableProps = {
	visitUuid: string;
	readOnly?: boolean;
};

const DiagnosisTable = ({ visitUuid, readOnly }: DiagnosisTableProps) => {
	const { t } = useTranslation();
	const { setValue, getValues } = useFormContext<DiagnosisTableFormValues>();
	const FIELD_PREFIX = 'encounterDiagnoses';
	const PRIMARY_RADIO_GROUP_NAME = `${FIELD_PREFIX}Primary.${visitUuid}`;

	const { fields, append, move, remove } = useFieldArray<DiagnosisTableFormValues, 'encounterDiagnoses', 'UU'>({
		name: 'encounterDiagnoses',
		keyName: 'UU',
	});

	const previousFirstRow = useRef<EncounterDiagnosis>(new EncounterDiagnosis());
	const rowBeingMoved = useRef(0);

	// This handles adding a new row if the user added a diagnosis to the special add row at the bottom
	const addDiagnosisRow = useWatch<DiagnosisTableFormValues, 'addNewDiagnosis'>({ name: 'addNewDiagnosis' });
	useEffect(() => {
		if (addDiagnosisRow && (addDiagnosisRow.BH_Coded_Diagnosis.UU || addDiagnosisRow.BH_Uncoded_Diagnosis)) {
			append({
				UU: v4(),
				BH_Coded_Diagnosis: { UU: addDiagnosisRow.BH_Coded_Diagnosis.UU },
				BH_Uncoded_Diagnosis: addDiagnosisRow.BH_Uncoded_Diagnosis,
			});

			// Reset the fields for adding a new row
			setValue('addNewDiagnosis', { BH_Coded_Diagnosis: { UU: '' }, BH_Uncoded_Diagnosis: null });
		}
	}, [addDiagnosisRow, fields.length, append, setValue, getValues]);

	// Watch the value of the primary, to trigger moving it to the top
	const selectedPrimary = useWatch<DiagnosisTableFormValues, 'encounterDiagnosesPrimary'>({
		name: 'encounterDiagnosesPrimary',
	});
	// This useWatch is used to trigger the useEffect below after re-rendering of the top row
	const firstRow = useWatch<DiagnosisTableFormValues, 'encounterDiagnoses.0'>({
		name: `${FIELD_PREFIX}.0` as 'encounterDiagnoses.0',
	});
	// When a row is chosen as the primary, this takes care of moving it to the top
	useEffect(() => {
		if (selectedPrimary === null) {
			return;
		}
		// Note: we can ignore the lineNo, as it will get overwritten on save with the index. So,
		// the index is what really matters.
		const primaryIndex: number = parseInt(selectedPrimary);
		// If the row we are trying to move is not the first one, and the first row hasn't been
		// changed yet, then move
		if (primaryIndex && primaryIndex !== 0 && rowBeingMoved.current === 0) {
			rowBeingMoved.current = primaryIndex;
			move(primaryIndex, 0);
		} else if (rowBeingMoved.current !== 0) {
			rowBeingMoved.current = 0;
			setValue('encounterDiagnosesPrimary', '0');
		}
	}, [selectedPrimary, move, firstRow, previousFirstRow, setValue, PRIMARY_RADIO_GROUP_NAME]);

	// Handles removal of a row
	const handleRemove = (index: number) => {
		remove(index);
		if (index === 0) {
			// If the first one was removed, need to ensure primary is re-set to 0 after
			// re-render. That will get handled by the useEffect hook above
			rowBeingMoved.current = -1;
		}
	};

	// If this is read-only and there are no diagnoses, hide the whole table
	if (readOnly && fields.length === 0) {
		return null;
	} else {
		return (
			<div className="table-responsive">
				<table className="table bh-table--form">
					<thead>
						<tr>
							<th className="data-type-action">{t(uiText.visit.form.patient.PRIMARY)}</th>
							<th>{t(uiText.visit.form.patient.DIAGNOSIS)}</th>
							{!readOnly && <th className="data-type-action print__d-none">{t(uiText.visit.form.product.DELETE)}</th>}
						</tr>
					</thead>
					<tbody>
						{fields.map((diagnosis, index) => (
							<DiagnosisTableRow
								key={diagnosis.UU}
								index={index}
								field={diagnosis}
								id={`${FIELD_PREFIX}.${index}.${visitUuid}`}
								fieldPrefix={`${FIELD_PREFIX}.${index}`}
								remove={handleRemove}
								readOnly={readOnly}
							/>
						))}
						{!readOnly && (
							<DiagnosisTableRow
								isAddRow={true}
								fieldPrefix={'addNewDiagnosis'}
								id={'addNewDiagnosis'}
								readOnly={readOnly}
							/>
						)}
					</tbody>
				</table>
			</div>
		);
	}
};

export default DiagnosisTable;
