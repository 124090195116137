import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import { pageUuid } from '../../services/AuthService';
import { uiText } from '../../utils/Language';
import { AdditionalInformationFromVisitiFormFields } from './AdditionalInformationFromVisit';
import ReorderButtons from './ReorderButtons';

type AdditionalInfoValuesFormRowProps = {
	field: AdditionalInformationFromVisitiFormFields['BH_Payer_Info_FldList'][0]['BH_Payer_Info_Fld_ValList'][0];
	index: number;
	remove: UseFieldArrayReturn<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList'
	>['remove'];
	baseName: string;
	move: UseFieldArrayReturn<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList'
	>['move'];
	isLast: boolean;
};

/**
 * This is the component for a row in the additional info values form component. It is kept in this file
 * because it's not intended to be reused. It is separated to avoid re-rendering the whole form
 * @param props The props for this component
 */
const AdditionalInfoValuesFormRow = ({
	field,
	index,
	remove,
	baseName,
	move,
	isLast,
}: AdditionalInfoValuesFormRowProps) => {
	const { t } = useTranslation();
	const { disableDeactivate } = useActionPrivileges(pageUuid.INSURERS_AND_DONORS);
	const { register } = useFormContext<AdditionalInformationFromVisitiFormFields>();
	const chargeInformationListValueFieldName = `${baseName}.${index}`;
	const chargeInformationValueIsActive: boolean = useWatch<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList.0.IsActive'
	>({
		name: `${chargeInformationListValueFieldName}.IsActive` as 'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList.0.IsActive',
	});

	return (
		<tr>
			<td className="align-middle">
				<input
					type="hidden"
					{...register(
						`${chargeInformationListValueFieldName}.UU` as 'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList.0.UU',
					)}
				/>
				<ReorderButtons index={index} move={move} isLast={isLast} />
			</td>
			<td className="align-middle text-center">
				<fieldset disabled={disableDeactivate}>
					<Form.Check
						aria-label={t(uiText.nonPatientPayment.button.ACTIVE)}
						id={`${chargeInformationListValueFieldName}.isActive`}
						{...register(
							`${chargeInformationListValueFieldName}.IsActive` as 'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList.0.IsActive',
						)}
					/>
				</fieldset>
			</td>
			<td className="align-middle">
				<fieldset disabled={!chargeInformationValueIsActive}>
					<Form.Control
						aria-label={t(uiText.nonPatientPayment.tableHeaders.VALUE)}
						placeholder={t(uiText.nonPatientPayment.label.ENTER_VALUE)}
						{...register(
							`${chargeInformationListValueFieldName}.Name` as unknown as 'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList.0.IsActive',
							{ required: true },
						)}
					/>
				</fieldset>
			</td>
			<td className="align-middle text-center">
				{field.isNew && (
					<button
						aria-label={t(uiText.nonPatientPayment.button.DELETE)}
						type="button"
						className="btn p-0 w-100"
						tabIndex={-1}
						onClick={() => remove(index)}
					>
						<FontAwesomeIcon icon="trash" className="border-0" />
					</button>
				)}
			</td>
		</tr>
	);
};

export default AdditionalInfoValuesFormRow;
