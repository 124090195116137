import { Button } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PATIENTS_PAGE } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { setPatientStateForStartingNewPatient } from '../Patient/PatientList';
import { VisitDetailsFormValues } from './VisitDetailsEdit';

const ViewPatient = () => {
	const { t } = useTranslation();
	const patientUU = useWatch<VisitDetailsFormValues, 'Patient.UU'>({ name: 'Patient.UU' });
	const history = useHistory();

	return patientUU ? (
		<Button
			variant="link"
			className="text-decoration-none"
			onClick={(event) => {
				event.preventDefault();
				history.push({
					pathname: PATIENTS_PAGE,
					state: setPatientStateForStartingNewPatient(patientUU),
				});
			}}
		>
			{t(uiText.visit.form.patient.DETAILS)}
		</Button>
	) : null;
};

export default ViewPatient;
