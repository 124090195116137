import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { C_BPartnerForSuppliersDocument, C_BPartnerForSuppliersQuery } from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import { BusinessPartner, BusinessPartnerDB } from '../../models';
import DBFilter, { Filter } from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import SupplierForm from './SupplierForm';

type SupplierListLocationState = { fromSave: boolean; data: BusinessPartner };

const availableFilters = {
	[uiText.supplier.filter.INACTIVE]: DBFilter<BusinessPartnerDB>().property('isactive').equals(false),
	[uiText.supplier.filter.ACTIVE]: IS_ACTIVE as unknown as Filter<BusinessPartnerDB>,
} as const;

export const setLocationState = (redirectFromSave: boolean, response: BusinessPartner): SupplierListLocationState => {
	return { fromSave: redirectFromSave, data: response };
};

const Supplier = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { disableWrite } = useActionPrivileges(pageUuid.SUPPLIERS);
	const { state } = useLocation<SupplierListLocationState | undefined>();

	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<C_BPartnerForSuppliersQuery['C_BPartnerGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(await graphqlClient.query({ query: C_BPartnerForSuppliersDocument, variables, fetchPolicy: 'network-only' }))
						.data.C_BPartnerGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t('Unable to load suppliers'));
					}
					exception({ description: `Supplier fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			viewState: state?.fromSave ? ListPageState.ADD_EDIT : undefined,
		},
	);

	// Filter states
	const [inactiveFilter, setInactiveFilter, { reset: resetInactiveFilter }] = useStateWithReset<
		keyof typeof availableFilters
	>(uiText.patient.filter.ACTIVE);

	// Handle searching and filtering
	useEffect(() => {
		let defaultFilter = DBFilter<BusinessPartnerDB>()
			.property('isVendor')
			.equals(true)
			.and(availableFilters[inactiveFilter]);
		if (searchText) {
			defaultFilter = defaultFilter.and(DBFilter<BusinessPartnerDB>().property('name').contains(searchText));
		}
		onFilterUpdate(defaultFilter.toString());
	}, [searchText, onFilterUpdate, inactiveFilter]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetInactiveFilter();
		reset();
	});

	useEffect(() => {
		if (state?.fromSave) {
			setViewState(ListPageState.ADD_EDIT);
		}
	}, [setViewState, state]);

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.supplier.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="activeFilter" className="mt-2">
									<Form.Check
										checked={inactiveFilter === uiText.supplier.filter.INACTIVE}
										onChange={(e) =>
											setInactiveFilter(
												e.target.checked ? uiText.supplier.filter.INACTIVE : uiText.supplier.filter.ACTIVE,
											)
										}
										label={t(uiText.supplier.filter.SHOW_INACTIVE)}
									/>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<C_BPartnerForSuppliersQuery['C_BPartnerGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'created',
										Header: () => <div className={'React-table-header'}>{t(uiText.supplier.tableHeaders.CREATED)}</div>,
										accessor: (d) => formatDate(new Date(d.Created)),
									},
									{
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.supplier.tableHeaders.ENTITY_NAME)}</div>
										),
										accessor: 'Name',
									},
									{
										id: 'BH_EMail',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.supplier.tableHeaders.EMAIL_ADDRESS)}</div>
										),
										accessor: 'BH_EMail',
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => <LoadSpinner show={isLoading} title={t(uiText.supplier.loading.LOADING)} />}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<SupplierForm
					uuid={selectedUuid}
					onFinish={(refreshData?: boolean) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default Supplier;
