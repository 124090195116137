import { throttle } from 'lodash';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	AsyncTypeahead,
	AsyncTypeaheadProps,
	Menu,
	MenuItem,
	TypeaheadLabelKey,
	TypeaheadResult,
} from 'react-bootstrap-typeahead';
import { Controller, FieldPath, FieldValues, UseControllerProps, useFormContext, useWatch } from 'react-hook-form';
import ForeignEntityInput from '../../types/ForeignEntityInput';

export type EntityLookupGraphQLProps<
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
> = {
	defaultValue?: ForeignEntityInput;
	visible?: boolean;
	onSelectNew?: (newValue: string) => void;
	labelKey: (option: ForeignEntityInput) => string;
} & Omit<AsyncTypeaheadProps<ForeignEntityInput>, 'allowNew' | 'defaultSelected' | 'labelKey'> &
	Pick<UseControllerProps<FormValues, FieldName>, 'name' | 'rules' | 'defaultValue'>;

/**
 * This component creates an uncontrolled entity lookup component that allows a user to type information (such as a patient name) and
 * have the component look that information up. It also manages clearing text if nothing is selected and ensuring the form
 * data is up-to-date when a form is submitted. Also, it is required to be a child of a form context.
 *
 * @param {ControllerProps | AsyncTypeaheadProps} props The properties for both the controller and async typeahead components
 */
const EntityLookupGraphQLInner = <
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>(
	{
		name: formRegisterName,
		rules,
		defaultValue,
		onSelectNew,
		visible = true,
		labelKey,
		options,
		...asyncTypeaheadProps
	}: EntityLookupGraphQLProps<FormValues, FieldName>,
	ref: ((instance: AsyncTypeahead<ForeignEntityInput> | null) => void) | null,
) => {
	const { getValues, setValue } = useFormContext<FormValues>();

	// function to check if the selected is custom
	const isCustomSelection = useCallback(
		(selectedFromTypeahead?: ForeignEntityInput | TypeaheadResult<ForeignEntityInput>) =>
			selectedFromTypeahead && 'customOption' in selectedFromTypeahead && selectedFromTypeahead.customOption,
		[],
	);

	// The autocomplete will call the focus twice in quick succession from time to time (I think it's
	// due to the menu opening, then re-focusing on the input), so ensure that doesn't happen
	const onFocus = asyncTypeaheadProps.onFocus
		? throttle(asyncTypeaheadProps.onFocus, 300, { trailing: false })
		: undefined;

	// The noSelectedObject is what we'll use when the form's value isn't valid
	const defaultUuidToUse = (defaultValue as ForeignEntityInput | undefined)?.UU
		? (defaultValue as ForeignEntityInput).UU
		: !!(getValues(`${formRegisterName}.UU` as any) as string)
			? (getValues(`${formRegisterName}.UU` as any) as string)
			: '';
	const [shouldClearInput, setShouldClearInput] = useState(false);

	// This can be modified outside this component, so we need to watch it
	// NB: Something happens with the selected object where it's properties can be updated without re-triggering a watch,
	// so we're going to copy it
	const selectedObjectUuid: string = useWatch({ name: `${formRegisterName}.UU` }) || '';
	const [formObjectUuid, setFormObjectUuid] = useState(selectedObjectUuid);

	// Create some refs for working with the typeahead
	const asyncTypeaheadRef = useRef<AsyncTypeahead<ForeignEntityInput> | null>(null);
	const userHasModifiedInputWithoutSelecting = useRef(false);
	const userIsModifyingLookup = useRef(false);
	const hasUserMadeSelection = useRef(false);
	const isMenuOpen = useRef(false);
	// We don't want the typeahead's selection changes to trigger re-renders, so store it in a ref
	const selected = useRef<string[]>(!!defaultUuidToUse ? [defaultUuidToUse] : []);

	// We'll update our stored form object if the form's value changes
	// NB: We can't leverage `getValues` from RHF because it's inconsistent
	useEffect(() => {
		// If this is run because the user made a selection, we don't care
		if (hasUserMadeSelection.current) {
			hasUserMadeSelection.current = false;
			return;
		}
		// If the UUID didn't change, we don't care
		if (formObjectUuid !== selectedObjectUuid) {
			// If the new object is valid, we'll update
			// Otherwise, if it's not already the "noSelectedObject", we'll set it to it
			if (!!selectedObjectUuid) {
				setFormObjectUuid(selectedObjectUuid);
				// So that the next render will catch the selected objects, also set selected here
				selected.current = [selectedObjectUuid];
			} else {
				setFormObjectUuid('');
			}
		}
	}, [selectedObjectUuid, formObjectUuid, formRegisterName]);

	// Once the selected form object updates, update the UI accordingly
	useEffect(() => {
		if (!formObjectUuid || shouldClearInput) {
			if (asyncTypeaheadRef.current) {
				asyncTypeaheadRef.current.clear();
			}
			selected.current = [];
			setValue(`${formRegisterName}.UU` as any, '' as any);
			setShouldClearInput(false);
			if (formObjectUuid !== '') {
				setFormObjectUuid('');
			}
		} else if (!shouldClearInput) {
			selected.current = [formObjectUuid];
			setValue(`${formRegisterName}.UU` as any, formObjectUuid as any, {
				shouldValidate: getValues(`${formRegisterName}.UU` as any) !== formObjectUuid,
			});
		}
	}, [setValue, formObjectUuid, formRegisterName, shouldClearInput, getValues]);

	// If the user is supplying a default input, update the selected
	const isSelectionCustom = useRef(false);
	if (!selected.current.length && asyncTypeaheadProps.defaultInputValue && !userIsModifyingLookup.current) {
		isSelectionCustom.current = true;
		selected.current.push(asyncTypeaheadProps.defaultInputValue as any);
	}

	const inputProps: AsyncTypeaheadProps<ForeignEntityInput>['inputProps'] = asyncTypeaheadProps.inputProps || {};
	inputProps.id = inputProps.id || asyncTypeaheadProps.id?.toString();

	// If we can allow new, we need to add a property to the option for display
	let optionsToUse = useMemo(() => {
		if (!onSelectNew) {
			return options;
		}
		let optionsWithLabel = JSON.parse(JSON.stringify(options)) as typeof options;
		optionsWithLabel.forEach((option) => {
			(option as any).label = labelKey(option);
		});
		return optionsWithLabel;
	}, [options, labelKey, onSelectNew]);

	return visible ? (
		<Controller
			name={`${formRegisterName}.UU` as any}
			rules={rules}
			render={({ field: { ref: controllerRef } }) => (
				<AsyncTypeahead
					delay={800}
					ignoreDiacritics={true}
					positionFixed={true}
					filterBy={() => true} // since filtering will happen on the back-end, we don't need to filter results again
					useCache={false}
					allowNew={!!onSelectNew} // If a function is passed in to select new, make sure this property set so it works
					{...asyncTypeaheadProps}
					labelKey={(!!onSelectNew ? 'label' : labelKey) as TypeaheadLabelKey<ForeignEntityInput>}
					options={optionsToUse}
					inputProps={inputProps}
					ref={(element) => {
						controllerRef(element);
						asyncTypeaheadRef.current = element;
						if (ref) {
							ref(element);
						}
					}}
					defaultInputValue={undefined}
					selected={selected.current.map((currentSelection) =>
						isSelectionCustom.current
							? ({ label: currentSelection } as any)
							: ({
									UU: currentSelection,
									// Since selecting new needs a string, add this property (in conjunction with what's listed above)
									label: !!onSelectNew ? labelKey({ UU: currentSelection }) : undefined,
								} as ForeignEntityInput),
					)}
					onChange={(selectedFromTypeahead = []) => {
						if (onSelectNew && isCustomSelection(selectedFromTypeahead[0])) {
							isSelectionCustom.current = true;
							onSelectNew((selectedFromTypeahead[0] as any).label);
							setShouldClearInput(true);
							userIsModifyingLookup.current = false;
							return;
						}
						isSelectionCustom.current = false;
						// In case where the user scrolled before selecting, the input might get cleared - so undo it here
						setShouldClearInput(false);
						selected.current = [];
						if (selectedFromTypeahead[0]?.UU) {
							selected.current = selectedFromTypeahead.map((selection) => selection.UU);
						}
						// If nothing selected, be done
						if (!selectedFromTypeahead.length) {
							return;
						}
						// The user has now selected, so reset their interaction tracking
						userHasModifiedInputWithoutSelecting.current = false;
						setFormObjectUuid(selectedFromTypeahead[0].UU);
						hasUserMadeSelection.current = true;
						if (asyncTypeaheadProps.onChange) {
							asyncTypeaheadProps.onChange(selectedFromTypeahead);
						}
						// Now that a value has been selected, consider the user finished modifying the input
						userIsModifyingLookup.current = false;
					}}
					onInputChange={(...args) => {
						// Whenever the user changes the input, we want to know
						userIsModifyingLookup.current = true;
						userHasModifiedInputWithoutSelecting.current = true;
						// Also, we'll clear a selected value once the input changes
						selected.current = [];
						if (asyncTypeaheadProps.onInputChange) {
							asyncTypeaheadProps.onInputChange(...args);
						}
					}}
					onMenuToggle={(isOpen) => {
						isMenuOpen.current = isOpen;
						// If the menu is closing and the input no longer has focus, clear it
						if (!isOpen && asyncTypeaheadRef.current?.getInput() !== document.activeElement) {
							userIsModifyingLookup.current = false;
							// If the user has modified the input and they're moving away (without selecting), clear the selection
							if (userHasModifiedInputWithoutSelecting.current) {
								setShouldClearInput(true);
								userHasModifiedInputWithoutSelecting.current = false;
							}
						}
					}}
					onBlur={(event) => {
						// If the user hasn't selected anything (and they're done interacting with the dropdown), clear it
						if (!isMenuOpen.current && userHasModifiedInputWithoutSelecting.current) {
							setShouldClearInput(true);
							userHasModifiedInputWithoutSelecting.current = false;
						}
						if (asyncTypeaheadProps.onBlur) {
							asyncTypeaheadProps.onBlur(event);
						}
					}}
					onFocus={onFocus}
					renderMenu={(results, menuProps) => (
						<Menu {...menuProps}>
							{results.map((option, position) => (
								<MenuItem option={option} position={position} key={option.UU || position}>
									{!!onSelectNew && isCustomSelection(option)
										? (asyncTypeaheadProps.newSelectionPrefix || '') + (option as any)['label']
										: labelKey(option)}
								</MenuItem>
							))}
						</Menu>
					)}
				/>
			)}
		/>
	) : (
		<></>
	);
};

const EntityLookupGraphQL = forwardRef(EntityLookupGraphQLInner as any) as <
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>(
	props: EntityLookupGraphQLProps<FormValues, FieldName> & {
		ref?: (instance: AsyncTypeahead<ForeignEntityInput> | null) => void;
	},
) => ReturnType<typeof EntityLookupGraphQLInner>;

export default EntityLookupGraphQL;
