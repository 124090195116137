import { useApolloClient } from '@apollo/client';
import { Suspense } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	Ad_Ref_ListPatientTypesForVisitsQuery,
	Ad_Ref_ListReferralsForVisitsQuery,
	Ad_UserForVisitCliniciansQuery,
	Bh_VisitForEditingDocument,
} from '../../graphql/__generated__/graphql';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import { formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ClinicalDetails, { primeClinicalDetailsData } from './ClinicalDetails';
import LabDiagnosticsDetails, { primeLabDiagnosticsDetailsData } from './LabDiagnosticsDetails';
import TriageDetails, { primeTriageDetailsData } from './TriageDetails';
import VisitDetailsReview from './VisitDetailsReview';
import { convertToVisitFormFields, VisitFormValues } from './VisitForm';

type VisitHistoryLineProps = {
	clinicians?: Ad_UserForVisitCliniciansQuery['AD_UserGet']['Results'];
	patientTypes?: Ad_Ref_ListPatientTypesForVisitsQuery['AD_Ref_ListGet']['Results'];
	referrals?: Ad_Ref_ListReferralsForVisitsQuery['AD_Ref_ListGet']['Results'];
	visitUU: string;
};

const VisitHistoryLineInternal = ({ clinicians, patientTypes, referrals, visitUU }: VisitHistoryLineProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { data: [visit] = [] } = useSuspenseAsync('load-visit-history' + visitUU, async () =>
		Promise.all([
			graphqlClient
				.query({ query: Bh_VisitForEditingDocument, variables: { UU: visitUU } })
				.then((response) => response.data.BH_Visit),
			primeTriageDetailsData(),
			primeClinicalDetailsData(graphqlClient),
			primeLabDiagnosticsDetailsData(graphqlClient),
		]),
	);
	const formMethods = useForm<VisitFormValues>({ defaultValues: convertToVisitFormFields(graphqlClient, t, visit) });

	return (
		<FormProvider {...formMethods}>
			<Form>
				<div className="info-ribbon sticky-top print__position-relative">
					<div className="info-ribbon__entity-prop">{`${t(uiText.visit.NUMBER)} ${visit?.DocumentNo || '-'}`}</div>
					<div className="info-ribbon__entity-prop">
						{t(uiText.visit.TIME, { visitTime: formatDateAndTime(new Date(visit?.BH_VisitDate!)) })}
					</div>
					<div className="info-ribbon__entity-prop">
						{t(uiText.visit.CLINICIAN_DISPLAY, {
							clinician: visit?.BH_Clinician_User?.Name || '-',
						})}
					</div>
					<div className="info-ribbon__entity-prop">
						{t(uiText.visit.REFERRAL, { referral: visit?.bh_referral || '-' })}
					</div>
				</div>
				<fieldset disabled={true}>
					<VisitDetailsReview
						clinicians={clinicians}
						patientTypes={patientTypes}
						referrals={referrals}
						loading={false}
					/>
					<TriageDetails reviewHistory={true} />
					<ClinicalDetails reviewHistory={true} />
					<LabDiagnosticsDetails isDataReadOnly={true} />

					<Card className="bh-card">
						<Card.Header className="d-flex">
							<div className="fw-bold h5 mb-0">{t(uiText.visit.form.product.LABEL)}</div>
						</Card.Header>
						<Card.Body>
							<table className="bh-table--form readonly">
								<thead>
									<tr>
										<th className="data-type-text">{t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE)}</th>
										<th className="data-type-text">{t(uiText.visit.form.product.table.INSTRUCTIONS)}</th>
										<th className="data-type-numeric">{t(uiText.visit.form.product.table.QUANTITY)}</th>
									</tr>
								</thead>
								<tbody>
									{visit?.C_Orders?.[0]?.C_OrderLines?.filter((orderLine) => !!orderLine.M_Product?.UU).map(
										(orderLine) => (
											<tr key={orderLine.UU}>
												<td>
													<Form.Control
														aria-label={t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE)}
														defaultValue={orderLine.M_Product?.Name}
													/>
												</td>
												<td>
													<Form.Control
														aria-label={t(uiText.visit.form.product.table.INSTRUCTIONS)}
														defaultValue={orderLine.Description || ''}
													/>
												</td>
												<td>
													<Form.Control
														aria-label={t(uiText.visit.form.product.table.QUANTITY)}
														className="text-end"
														defaultValue={orderLine.QtyEntered}
													/>
												</td>
											</tr>
										),
									)}
								</tbody>
							</table>
						</Card.Body>
					</Card>
				</fieldset>
			</Form>
		</FormProvider>
	);
};

export default function VisitHistoryLine(props: VisitHistoryLineProps) {
	const { t } = useTranslation();
	return (
		<Suspense
			fallback={
				<Card className="bh-card">
					<LoadSpinner inline title={t(uiText.visit.FETCHING)} />
				</Card>
			}
		>
			<VisitHistoryLineInternal {...props} />
		</Suspense>
	);
}
