import { useApolloClient } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Controller, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	Ad_Ref_ListTenderTypesDocument,
	Ad_Ref_ListTenderTypesQueryVariables,
} from '../../graphql/__generated__/graphql';
import { DBFilter, referenceUuids } from '../../models';
import { ReferenceListDB, sortPaymentTypesGraphQL } from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { PharmacySalesFormValues } from './PharmacySalesForm';
import { PharmacySalesPaymentLineItemTableFormValues } from './PharmacySalesPaymentLineItemTable';

const tenderTypesVariables: Ad_Ref_ListTenderTypesQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.TENDER_TYPES)
		.up()
		.toString(),
};

type PharmacySalesPaymentTableRowProps = { index: number; remove: UseFieldArrayReturn['remove'] };

const PharmacySalesPaymentTableRow = ({ index, remove }: PharmacySalesPaymentTableRowProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { register } = useFormContext<PharmacySalesFormValues>();

	const tenderTypes = graphqlClient.readQuery({
		query: Ad_Ref_ListTenderTypesDocument,
		variables: tenderTypesVariables,
	})?.AD_Ref_ListGet.Results;

	return (
		<tr>
			<td>
				<input type="hidden" {...register(`C_Payments.${index}.UU`)} />
				<Form.Select
					aria-label={t(uiText.visit.form.payment.table.TYPE)}
					{...register(`C_Payments.${index}.TenderType.UU`)}
				>
					{sortPaymentTypesGraphQL(tenderTypes?.filter((tenderType) => tenderType.IsActive) || []).map((type) => (
						<option key={type.UU} value={type.UU}>
							{type.Name}
						</option>
					))}
				</Form.Select>
			</td>
			<td>
				<Controller<PharmacySalesPaymentLineItemTableFormValues, 'C_Payments.0.PayAmt'>
					name={`C_Payments.${index}.PayAmt` as 'C_Payments.0.PayAmt'}
					rules={{ validate: (value) => (value && value > 0) || false }}
					render={({ field }) => (
						<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} {...field} />
					)}
				/>
			</td>
			<td>
				<Form.Control {...register(`C_Payments.${index}.Description`)} />
			</td>
			<td className="align-middle text-center print__d-none" onClick={() => remove(index)}>
				<button
					type="button"
					className="btn p-0 w-100 flex-grow-1"
					tabIndex={-1}
					aria-label={t(uiText.visit.button.DELETE)}
				>
					<FontAwesomeIcon icon={faTrash} />
				</button>
			</td>
		</tr>
	);
};

export default PharmacySalesPaymentTableRow;
