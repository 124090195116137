import i18next from 'i18next';
import { isNumber } from 'lodash';
import {
	Bh_Coded_DiagnosisGetForVisitsQuery,
	Bh_ConceptForEditingQuery,
	Bh_ConceptGetForVisitsQuery,
	Bh_ConceptsListPageGetQuery,
} from '../graphql/__generated__/graphql';
import {
	AttributeSetInstance,
	BaseEntityDB,
	BusinessPartner,
	Concept,
	ConceptClass,
	ConceptExtra,
	ConceptExtraKey,
	ConceptMappingMapType,
	DBFilter,
	DocumentTypeDB,
	Filter,
} from '../models';
import { formatDate, getDaysIntoYear } from './DateUtil';
import { uiText } from './Language';
import { formatNumber, precisionSubtract } from './NumberUtil';

export function getCodedDiagnosisName(
	codedDiagnosis?: Bh_Coded_DiagnosisGetForVisitsQuery['BH_Coded_DiagnosisGet']['Results'][0] | null,
) {
	return codedDiagnosis?.bh_cielname + (codedDiagnosis?.bh_icd10who ? `, ICD ${codedDiagnosis.bh_icd10who}` : '');
}

export function getConceptMinmumValue(concept?: Bh_ConceptGetForVisitsQuery['BH_ConceptGet']['Results'][0] | null) {
	let minimumValue = concept?.BH_Concept_Extras?.find(
		(conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.LOW_ABSOLUTE,
	)?.BH_Value;
	return minimumValue ? Number(minimumValue) : undefined;
}

export function getConceptMaximumValue(concept?: Bh_ConceptGetForVisitsQuery['BH_ConceptGet']['Results'][0] | null) {
	let maximumValue = concept?.BH_Concept_Extras?.find(
		(conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.HI_ABSOLUTE,
	)?.BH_Value;
	return maximumValue ? Number(maximumValue) : undefined;
}

export function getConceptName(concept?: Bh_ConceptGetForVisitsQuery['BH_ConceptGet']['Results'][0] | null) {
	return (concept?.bh_concept_class === ConceptClass.LAB_SET ? 'Panel - ' : '') + getConceptLocalNameGQL(concept);
}

/**
 * Get the new items and the removed items by comparing an initial and modified array
 * @param initial The initial list of items (typically before form manipulation)
 * @param modified The modified list of items (typically after form manipulation)
 * @param identity A function to determine if the items are the same
 * @param equality A function to determine if the items are equal (in addition to what the identity function runs)
 * @returns A 3-tuple of the new, removed, and changed items
 */
export function getDifference<T, S>(
	initial: T[] | undefined,
	modified: S[] | undefined,
	identity: (first: T, second: S) => boolean,
	equality: (first: T, second: S) => boolean,
): [S[], T[], S[]] {
	let newItems =
		modified?.filter((modifiedItem) => !initial?.some((initialItem) => identity(initialItem, modifiedItem))) || [];
	let removedItems =
		initial?.filter((initialItem) => !modified?.some((modifiedItem) => identity(initialItem, modifiedItem))) || [];
	let foundMatch: T | undefined;
	let changedItems =
		modified?.filter(
			(modifiedItem) =>
				(foundMatch = initial?.find((initialItem) => identity(initialItem, modifiedItem))) &&
				!equality(foundMatch, modifiedItem),
		) || [];
	return [newItems, removedItems, changedItems];
}

export function getDropdownDisplay(entity: { name: string; description: string }): string {
	return entity.name + (entity.description ? ` -> ${entity.description}` : '');
}

export function getDisplay(attributeSetInstance?: AttributeSetInstance): string | undefined {
	if (!attributeSetInstance) {
		return;
	}
	let display: string[] = [];
	if (attributeSetInstance.guaranteeDate) {
		display.push(
			i18next.t(uiText.transferInventory.labels.EXPIRATION) + ': ' + formatDate(attributeSetInstance.guaranteeDate),
		);
	}
	if (attributeSetInstance.purchaseDate) {
		display.push(
			i18next.t(uiText.product.PURCHASED_ON) + ': ' + formatDate(new Date(attributeSetInstance.purchaseDate)),
		);
	}
	if (attributeSetInstance.purchasePrice) {
		display.push(i18next.t(uiText.product.BUY_PRICE) + ': ' + formatNumber(attributeSetInstance.purchasePrice));
	}
	return display.join(', ');
}

export function getAttributeSetInstanceDisplay(
	attributeSetInstance?: {
		GuaranteeDate?: number | null;
		PurchaseDate?: number | null;
		PurchasePrice?: number | null;
	} | null,
): string | undefined {
	if (!attributeSetInstance) {
		return;
	}
	let display: string[] = [];
	if (attributeSetInstance.GuaranteeDate) {
		display.push(
			i18next.t(uiText.transferInventory.labels.EXPIRATION) +
				': ' +
				formatDate(new Date(attributeSetInstance.GuaranteeDate)),
		);
	}
	if (attributeSetInstance.PurchaseDate) {
		display.push(
			i18next.t(uiText.product.PURCHASED_ON) + ': ' + formatDate(new Date(attributeSetInstance.PurchaseDate)),
		);
	}
	if (attributeSetInstance.PurchasePrice) {
		display.push(i18next.t(uiText.product.BUY_PRICE) + ': ' + formatNumber(attributeSetInstance.PurchasePrice));
	}
	return display.join(', ');
}

export function getPriceMargin(product: { sellPrice?: number; buyPrice?: number }) {
	return isNumber(product.sellPrice) || isNumber(product.buyPrice)
		? precisionSubtract(product.sellPrice || 0, product.buyPrice || 0)
		: 0;
}

export function getManualModelPriceMargin(buyPrice?: number | null, sellPrice?: number | null) {
	return isNumber(sellPrice || 0) || isNumber(buyPrice || 0) ? precisionSubtract(sellPrice || 0, buyPrice || 0) : 0;
}

// This must match what's coming from iDempiere in all languages
const disallowNegativeInventoryMessage =
	/The .+ warehouse does not allow negative inventory for Product = (.+), ASI = .+, Locator = .+ \(Shortage of (\d+)\)/;
export function getVisitNegativeInventoryErrorInformation(
	errorMessage: string,
): { productName: string; inventoryShortageAmount: number }[] {
	if (disallowNegativeInventoryMessage.test(errorMessage)) {
		const matches = errorMessage.match(disallowNegativeInventoryMessage) as RegExpMatchArray;
		let negativeInventoryErrorInformation = [];
		for (let i = 1; i < matches.length - 1; i += 2) {
			const productValueAndName = matches[i];
			const splitProductValueAndName = productValueAndName.split('_');
			const productName = splitProductValueAndName.slice(Math.floor(splitProductValueAndName.length / 2)).join('_');
			const inventoryShortageAmount = matches[i + 1];
			negativeInventoryErrorInformation.push({
				productName,
				inventoryShortageAmount: parseFloat(inventoryShortageAmount),
			});
		}
		return negativeInventoryErrorInformation;
	}
	return [];
}

/**
 * Gets the age matching our requirements:
 *	* 0-14 days of age —> # days (date of birth = day 0)
 *	* Day of life 15 through the end of month 1 —>  #Weeks + days
 *	* Month 2 through the end of Month 23 —> # months
 *	* Month 24 on - #years
 * @param businessPartner The business partner to calculate the age for
 * @returns The age in the appropriate string
 * @deprecated
 */
export function getAgeDisplay(businessPartner: BusinessPartner) {
	if (!businessPartner.dateOfBirth) {
		return '';
	}
	const today = new Date();
	const birthDate = new Date(businessPartner.dateOfBirth);
	let age = today.getFullYear() - birthDate.getFullYear();

	// If their birthday hasn't come this year, they're not as old as the year difference
	if (
		today.getMonth() < birthDate.getMonth() ||
		(today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
	) {
		age--;
	}

	if (age >= 2) {
		return i18next.t(uiText.patient.age.YEARS, { years: age });
	}

	// Redo the age
	age = today.getFullYear() - birthDate.getFullYear();
	let months = age * 12 + today.getMonth() - birthDate.getMonth();
	// If the day of birth hasn't come this month, they're not as old as the month difference
	if (today.getDate() < birthDate.getDate()) {
		months--;
	}
	if (months >= 2) {
		return i18next.t(uiText.patient.age.MONTHS, { months });
	}

	// Now let's calculate days and/or weeks
	let days = getDaysIntoYear(today) - getDaysIntoYear(birthDate);
	if (today.getFullYear() > birthDate.getFullYear()) {
		days =
			getDaysIntoYear(today) + getDaysIntoYear(new Date(today.getFullYear(), 0, -1)) - getDaysIntoYear(birthDate) + 1;
	}
	if (days >= 15) {
		const weeks = Math.floor(days / 7);
		days = days % 7;
		if (days === 0) {
			return i18next.t(uiText.patient.age.WEEKS, { weeks });
		} else if (days === 1) {
			return i18next.t(uiText.patient.age.WEEKS_AND_DAY, { weeks, days });
		}
		return i18next.t(uiText.patient.age.WEEKS_AND_DAYS, { weeks, days });
	}
	if (days === 1) {
		return i18next.t(uiText.patient.age.DAY, { days });
	}
	return i18next.t(uiText.patient.age.DAYS, { days });
}

/**
 * Gets the age matching our requirements:
 *	* 0-14 days of age —> # days (date of birth = day 0)
 *	* Day of life 15 through the end of month 1 —>  #Weeks + days
 *	* Month 2 through the end of Month 23 —> # months
 *	* Month 24 on - #years
 * @param businessPartner The business partner to calculate the age for
 * @returns The age in the appropriate string
 */
export function getGraphQLAgeDisplay(dateOfBirth?: Date | null | number) {
	if (!dateOfBirth) {
		return '';
	}
	const today = new Date();
	let birthDate = new Date(dateOfBirth);
	let age = today.getFullYear() - birthDate.getFullYear();

	// If their birthday hasn't come this year, they're not as old as the year difference
	if (
		today.getMonth() < birthDate.getMonth() ||
		(today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
	) {
		age--;
	}

	if (age >= 2) {
		return i18next.t(uiText.patient.age.YEARS, { years: age });
	}

	// Redo the age
	age = today.getFullYear() - birthDate.getFullYear();
	let months = age * 12 + today.getMonth() - birthDate.getMonth();
	// If the day of birth hasn't come this month, they're not as old as the month difference
	if (today.getDate() < birthDate.getDate()) {
		months--;
	}
	if (months >= 2) {
		return i18next.t(uiText.patient.age.MONTHS, { months });
	}

	// Now let's calculate days and/or weeks
	let days = getDaysIntoYear(today) - getDaysIntoYear(birthDate);
	if (today.getFullYear() > birthDate.getFullYear()) {
		days =
			getDaysIntoYear(today) + getDaysIntoYear(new Date(today.getFullYear(), 0, -1)) - getDaysIntoYear(birthDate) + 1;
	}
	if (days >= 15) {
		const weeks = Math.floor(days / 7);
		days = days % 7;
		if (days === 0) {
			return i18next.t(uiText.patient.age.WEEKS, { weeks });
		} else if (days === 1) {
			return i18next.t(uiText.patient.age.WEEKS_AND_DAY, { weeks, days });
		}
		return i18next.t(uiText.patient.age.WEEKS_AND_DAYS, { weeks, days });
	}
	if (days === 1) {
		return i18next.t(uiText.patient.age.DAY, { days });
	}
	return i18next.t(uiText.patient.age.DAYS, { days });
}

/*
 * Concepts may have attributes attached to them called "extras". In addition to having
 * these attributes directly attached to them, however, they may also be attached to
 * the mappings between a concept and it's parent. This helper function makes it simple
 * to get all of the extras in one place.
 */
export function getConceptAllExtras(concept: Concept): ConceptExtra[] {
	const allExtras: ConceptExtra[] = [];
	if (concept?.conceptExtras) {
		allExtras.push(...concept.conceptExtras);
	}

	if (concept.fromConceptMappings) {
		concept.fromConceptMappings.forEach((parentConcept) => {
			// Look for the one child of the parent that matches the passed in concept - we don't
			// care about mappings to sibling concepts
			const mappingToParent = parentConcept.toConceptMappings.find(
				(mapping) => mapping.toConceptCode === concept.oclId,
			);
			if (mappingToParent) {
				allExtras.push(...mappingToParent.conceptExtras);
			}
		});
	}

	return allExtras;
}

export function getConceptAllExtrasGQL(
	concept: Bh_ConceptsListPageGetQuery['BH_ConceptGet']['Results'][0] | Bh_ConceptForEditingQuery['BH_Concept'],
) {
	const allExtras: Bh_ConceptsListPageGetQuery['BH_ConceptGet']['Results'][0]['BH_Concept_Extras'] = [];
	if (concept?.BH_Concept_Extras) {
		allExtras.push(...concept.BH_Concept_Extras);
	}

	if (concept?.FromBH_Concept_Mappings) {
		concept.FromBH_Concept_Mappings.forEach((conceptMapping) => {
			if (conceptMapping.BH_Map_Type === ConceptMappingMapType.CONCEPT_SET && conceptMapping.BH_Concept_Extras) {
				allExtras.push(...conceptMapping.BH_Concept_Extras);
			}
		});
	}

	return allExtras;
}

/*
 * Get the name that should be shown locally, for this client, in the selected language. A variety of places
 * are searched, in priority order, to determine which name to show.
 */
export function getConceptLocalName(concept: Concept, includeClientName: boolean = true) {
	let localName: string | undefined;

	// First, look for a customized name supplied by the client
	if (includeClientName) {
		localName = concept.clientConcepts.find((clientConcept) => clientConcept.name)?.name;
		if (localName) return localName;
	}

	// Next, look for a local name in the concept extras
	localName = getConceptAllExtras(concept).find(
		(conceptExtra) => conceptExtra.key === ConceptExtraKey.LOCAL_NAME,
	)?.value;
	if (localName) return localName;

	// Next, look for a concept name, in the currently selected language, sorted with the item marked
	// 'localPreferred' first
	// TODO - HANDLE OTHER LANGUAGES
	const LANGUAGE = 'en';
	const conceptNamesSorted = concept.conceptNames
		.filter((conceptName) => conceptName.locale === LANGUAGE)
		.sort((a, b) => (a.localePreferred && !b.localePreferred ? -1 : !a.localePreferred && b.localePreferred ? 1 : 0));
	if (conceptNamesSorted && conceptNamesSorted.length > 0) {
		localName = conceptNamesSorted[0].name;
		if (localName) return localName;
	}

	// Finally, if none of those exist, use the display name (which should also be the scientific name)
	return concept.displayName;
}

export function getConceptLocalNameGQL(
	concept: Bh_ConceptsListPageGetQuery['BH_ConceptGet']['Results'][0] | Bh_ConceptForEditingQuery['BH_Concept'],
	includeClientName: boolean = true,
) {
	let localName: string | null | undefined;

	// First, look for a customized name supplied by the client
	if (includeClientName) {
		localName = concept?.BH_Client_Concepts?.find((clientConcept) => !!clientConcept.Name)?.Name;
		if (localName) {
			return localName;
		}
	}

	// Next, look for a local name in the concept extras
	localName = getConceptAllExtrasGQL(concept).find(
		(conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.LOCAL_NAME,
	)?.BH_Value;
	if (localName) {
		return localName;
	}

	// Next, look for a concept name, in the currently selected language, sorted with the item marked
	// 'localPreferred' first
	// TO DO - HANDLE OTHER LANGUAGES
	if (concept?.BH_Concept_Names) {
		const LANGUAGE = 'en';
		const conceptNamesSorted = concept.BH_Concept_Names.filter(
			(conceptName) => conceptName.BH_Concept_Locale === LANGUAGE,
		).sort((a, b) =>
			a.BH_Concept_Locale_Preferred && !b.BH_Concept_Locale_Preferred
				? -1
				: !a.BH_Concept_Locale_Preferred && b.BH_Concept_Locale_Preferred
					? 1
					: 0,
		);
		if (conceptNamesSorted && conceptNamesSorted.length > 0) {
			localName = conceptNamesSorted[0].Name;
			if (localName) {
				return localName;
			}
		}
	}

	// Finally, if none of those exist, use the display name (which should also be the scientific name)
	return concept?.BH_Display_Name;
}

/*
 * Get an array of strings that are valid names or index terms for this concept, all in lowercase
 */
export function getConceptSearchTerms(concept: Concept, language: string = 'en') {
	// First, the display name can be searched
	let searchTerms = [concept.displayName.toLowerCase()];

	// Add the client's customized name if there is one
	let searchTerm = concept.clientConcepts.find((clientConcept) => clientConcept.name)?.name;
	if (searchTerm) searchTerms.push(searchTerm.toLowerCase());

	// Add any concept extra local_name properties
	searchTerm = getConceptAllExtras(concept).find(
		(conceptExtra) => conceptExtra.key === ConceptExtraKey.LOCAL_NAME,
	)?.value;
	if (searchTerm) searchTerms.push(searchTerm.toLowerCase());

	// Add all concept names in the currently selected language
	if (language) {
		const conceptNames = concept.conceptNames.filter((conceptName) => conceptName.locale === language);
		if (conceptNames && conceptNames.length > 0) {
			searchTerms.push(...conceptNames.map((conceptName) => conceptName.name.toLowerCase()));
		}
	}

	return searchTerms;
}

/*
 * Get an array of strings that are valid names or index terms for this concept, all in lowercase
 */
export function getConceptSearchTermsGQL(
	concept: Bh_ConceptGetForVisitsQuery['BH_ConceptGet']['Results'][0],
	language: string = 'en',
) {
	// First, the display name can be searched
	let searchTerms = [concept.BH_Display_Name?.toLowerCase()];

	// Add the client's customized name if there is one
	let searchTerm = concept.BH_Client_Concepts?.find((clientConcept) => clientConcept.Name)?.Name;
	if (searchTerm) {
		searchTerms.push(searchTerm.toLowerCase());
	}

	// Add any concept extra local_name properties
	searchTerm = getConceptAllExtrasGQL(concept).find(
		(conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.LOCAL_NAME,
	)?.BH_Value;
	if (searchTerm) {
		searchTerms.push(searchTerm.toLowerCase());
	}

	// Add all concept names in the currently selected language
	if (language) {
		const conceptNames = concept.BH_Concept_Names?.filter(
			(conceptName) => conceptName.BH_Concept_Locale === language && conceptName.Name,
		);
		if (conceptNames && conceptNames.length > 0) {
			searchTerms.push(...conceptNames.map((conceptName) => conceptName.Name!.toLowerCase()));
		}
	}

	return searchTerms.filter((searchTerm) => !!searchTerm) as string[];
}

export function getConceptExtraValueByKey(concept: Concept, key: ConceptExtraKey) {
	return getConceptAllExtras(concept).find((conceptExtra) => conceptExtra.key === key)?.value;
}

export function getLabTestResultReferenceRange(concept: Concept, includeUnits: boolean) {
	let refRange = '';
	const allExtras = getConceptAllExtras(concept);
	if (!allExtras) {
		return refRange;
	}
	const lowNormal = allExtras.find((conceptExtra) => conceptExtra.key === ConceptExtraKey.LOW_NORMAL)?.value;
	const hiNormal = allExtras.find((conceptExtra) => conceptExtra.key === ConceptExtraKey.HI_NORMAL)?.value;

	if (lowNormal && hiNormal) {
		refRange = `${lowNormal} - ${hiNormal}`;
	} else if (lowNormal) {
		refRange = `> ${lowNormal}`;
	} else if (hiNormal) {
		refRange = `< ${hiNormal}`;
	}
	if (refRange && includeUnits) {
		const units = allExtras.find((conceptExtra) => conceptExtra.key === ConceptExtraKey.UNITS)?.value;
		if (units) {
			refRange = refRange + ` ${units}`;
		}
	}
	return refRange;
}

export function isLabTestResultOutsideReferenceRange(result: string | undefined, concept: Concept | undefined) {
	// If the result is not a number, we can't compare  it to a range, so just return false
	if (!result || Number.isNaN(result) || !concept) {
		return false;
	}

	const allExtras = getConceptAllExtras(concept);
	if (!allExtras) {
		return false;
	}
	const lowNormal = allExtras.find((conceptExtra) => conceptExtra.key === ConceptExtraKey.LOW_NORMAL)?.value;
	const hiNormal = allExtras.find((conceptExtra) => conceptExtra.key === ConceptExtraKey.HI_NORMAL)?.value;
	const numResult = Number(result);

	// If the range limits exist and are valid numbers, then return true if the result is outside the range
	if (lowNormal && !Number.isNaN(lowNormal) && numResult < Number(lowNormal)) {
		return true;
	}
	if (hiNormal && !Number.isNaN(hiNormal) && numResult > Number(hiNormal)) {
		return true;
	}

	// If we passed the above checks, return false
	return false;
}

export function isLabTestResultOutsideReferenceRangeGQL(
	result: string | undefined | null,
	concept: Bh_ConceptGetForVisitsQuery['BH_ConceptGet']['Results'][0] | undefined | null,
) {
	// If the result is not a number, we can't compare  it to a range, so just return false
	if (!result || Number.isNaN(result) || !concept) {
		return false;
	}

	const allExtras = getConceptAllExtrasGQL(concept);
	if (!allExtras) {
		return false;
	}
	const lowNormal = allExtras.find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.LOW_NORMAL)?.BH_Value;
	const hiNormal = allExtras.find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.HI_NORMAL)?.BH_Value;
	const numResult = Number(result);

	// If the range limits exist and are valid numbers, then return true if the result is outside the range
	if (lowNormal && !Number.isNaN(lowNormal) && numResult < Number(lowNormal)) {
		return true;
	}
	if (hiNormal && !Number.isNaN(hiNormal) && numResult > Number(hiNormal)) {
		return true;
	}

	// If we passed the above checks, return false
	return false;
}

export function getLabTestResultReferenceRangeGQL(
	concept: Bh_ConceptForEditingQuery['BH_Concept'],
	includeUnits: boolean,
) {
	let refRange = '';
	const allExtras = getConceptAllExtrasGQL(concept);
	if (!allExtras) {
		return refRange;
	}
	const lowNormal = allExtras.find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.LOW_NORMAL)?.BH_Value;
	const hiNormal = allExtras.find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.HI_NORMAL)?.BH_Value;

	if (lowNormal && hiNormal) {
		refRange = `${lowNormal} - ${hiNormal}`;
	} else if (lowNormal) {
		refRange = `> ${lowNormal}`;
	} else if (hiNormal) {
		refRange = `< ${hiNormal}`;
	}
	if (refRange && includeUnits) {
		const units = allExtras.find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.UNITS)?.BH_Value;
		if (units) {
			refRange = refRange + ` ${units}`;
		}
	}
	return refRange;
}

export function getConceptParentLabSetName(concept: Concept) {
	const parentLabSet = concept.fromConceptMappings.find((concept) => (concept.conceptClass = ConceptClass.LAB_SET));
	return (
		parentLabSet?.conceptExtras.find((conceptExtra) => conceptExtra.key === ConceptExtraKey.LOCAL_NAME)?.value ||
		parentLabSet?.displayName
	);
}

export function getConceptParentLabSetNameGQL(
	concept: Bh_ConceptsListPageGetQuery['BH_ConceptGet']['Results'][0] | Bh_ConceptForEditingQuery['BH_Concept'],
) {
	const parentLabSet = concept?.FromBH_Concept_Mappings?.find(
		(mapping) =>
			mapping.BH_Map_Type === ConceptMappingMapType.CONCEPT_SET &&
			mapping.FromBH_Concept.bh_concept_class === ConceptClass.LAB_SET,
	)?.FromBH_Concept;
	return (
		parentLabSet?.BH_Concept_Extras?.find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.LOCAL_NAME)
			?.BH_Value || parentLabSet?.BH_Display_Name
	);
}

export function getConceptIsActiveForClient(concept: Concept) {
	return concept.clientConcepts && concept.clientConcepts.length > 0
		? concept.clientConcepts[0].isActive
		: concept.isActive;
}

export function getConceptIsActiveForClientGQL(concept: Bh_ConceptsListPageGetQuery['BH_ConceptGet']['Results'][0]) {
	return concept.BH_Client_Concepts && concept.BH_Client_Concepts.length > 0
		? concept.BH_Client_Concepts[0].IsActive
		: concept.IsActive;
}

export function getDefaultSearchFilter<T extends BaseEntityDB = BaseEntityDB>(query: string): Filter<T> {
	return DBFilter<BaseEntityDB>().property('name').contains(query) as unknown as Filter<T>;
}

export function getDocumentBaseTypeFilter(
	documentBaseType: string,
	documentSalesSubType: string | null,
	documentInventorySubType: string | null,
	isSalesTransaction: boolean,
	isShipmentConfirm: boolean,
	isPickQAConfirm: boolean,
) {
	const filter = DBFilter<DocumentTypeDB>()
		.property('docbasetype')
		.equals(documentBaseType)
		.property('issotrx')
		.equals(isSalesTransaction)
		.property('isshipconfirm')
		.equals(isShipmentConfirm)
		.property('ispickqaconfirm')
		.equals(isPickQAConfirm);
	if (documentSalesSubType) {
		filter.property('docsubtypeso').equals(documentSalesSubType);
	} else {
		filter.property('docsubtypeso').isNull();
	}
	if (documentInventorySubType) {
		filter.property('docsubtypeinv').equals(documentInventorySubType);
	} else {
		filter.property('docsubtypeinv').isNull();
	}
	return filter;
}

export function getAccountDisplay(account: { Name: string; Value: string }) {
	return `${account.Value} - ${account.Name}`;
}

export function getExpenseCategoryDisplay(expenseCategory: { Name: string; Description?: string | null }) {
	return expenseCategory.Name + (expenseCategory.Description ? ` -> ${expenseCategory.Description}` : '');
}
