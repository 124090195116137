import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Tab, { TabDto } from './Tab';

export interface WindowDto extends BaseEntityDto {
	tabs?: TabDto[] | null;
}

export interface WindowDB extends BaseEntityDB {
	ad_window_uu: string;
}

export default class Window extends BaseEntity {
	tabs: Tab[];

	constructor(props: Partial<WindowDto | Window> = {}) {
		props = cloneDeep(props);
		super(props);

		this.tabs = (props.tabs || []).map((tab) => new Tab(tab));
	}
}
