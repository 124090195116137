import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { ProductLineItemTableFormValues } from './ProductLineItemTable';

type ProductLineItemTableFooterProps = {
	shouldDisplayTheDeleteColumn: boolean;
};

const ProductLineItemTableFooter = ({ shouldDisplayTheDeleteColumn }: ProductLineItemTableFooterProps) => {
	const { t } = useTranslation();
	// For some reason this occaisionally gets reset to undefined, so just use an empty array, if that's the case
	const visitOrderLines =
		useWatch<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines'>({
			name: 'C_Orders.0.C_OrderLines',
		}) || [];
	const addOrderLine = useWatch<ProductLineItemTableFormValues, 'addNewOrderLine'>({ name: 'addNewOrderLine' });
	const orderLineTotal = [...visitOrderLines, addOrderLine].reduce(
		(runningTotal, orderLine) => runningTotal + (orderLine.QtyEntered || 0) * (orderLine.PriceEntered || 0),
		0,
	);

	return (
		<tr>
			<td colSpan={5} className="align-middle">
				<span className="float-end me-2">
					<strong>{t(uiText.visit.form.product.TOTAL)}</strong>
				</span>
			</td>
			<td>
				<FormatNumberInput
					className="fw-bold"
					readOnly={true}
					tabIndex={-1}
					value={orderLineTotal}
					aria-label={t(uiText.visit.form.product.table.VISIT_TOTAL)}
				/>
			</td>
			{shouldDisplayTheDeleteColumn ? <td className="d-none print__d-none"></td> : null}
		</tr>
	);
};

export default ProductLineItemTableFooter;
