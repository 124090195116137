import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseMetadata, { BaseMetadataDto } from './BaseMetadata';
import Warehouse, { WarehouseDB, WarehouseDto } from './Warehouse';
import { BaseEntityDB } from './BaseEntity';

export interface LocatorDB extends BaseEntityDB {
	m_locator_uu: string;
	m_warehouse: WarehouseDB;
}

export interface LocatorDto extends BaseMetadataDto {
	value: string;
	warehouse?: WarehouseDto | null;
	isDefault: boolean;
}

export default class Locator extends BaseMetadata {
	value: string;
	warehouse: Warehouse;
	isDefault: boolean;

	constructor(props?: DeepPartial<Locator | LocatorDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.value = props.value || '';
		this.warehouse = new Warehouse(props.warehouse || {});
		this.isDefault = props.isDefault !== false;
	}
}
