import { defaultsDeep } from 'lodash';

/**
 * This is not exported intentionally so that it can be copied for every page below, but so that the "outside world" only
 * uses the UI string specific to it's page. This object should contain strings that may appear on more than one page
 */
const common = {
	button: {
		EDIT: 'common.edit',
		NEW: 'common.new',
		PRINT: 'common.print',
		SAVE: 'common.save',
		SAVE_AND_CLOSE: 'common.saveAndClose',
		CLOSE: 'common.close',
		COMPLETE: 'common.complete',
		CONFIRM: 'common.confirm',
		DELETE: 'common.delete',
		BACK: 'common.back',
		CANCEL: 'common.cancel',
		ACTIVE: 'common.active',
		VOID: 'common.void',
		CHANGE: 'common.change',
	},
	created: {
		LABEL: 'common.created',
	},
	DELETE_DRAFT_PROMPT: 'common.doYouWantToDeleteDraft',
	description: {
		ENTER: 'common.enterDescription',
		LABEL: 'common.description',
		PLACEHOLDER: 'common.enterDescription',
	},
	filter: {
		ACTIVE: 'common.showInactive',
		ALL: 'common.all',
		COMPLETED: 'common.completed',
		DRAFTED: 'common.drafted',
		VOIDED: 'common.voided',
	},
	HIDE_PASSWORD: 'common.hidePassword',
	name: {
		LABEL: 'common.name',
	},
	prompt: {
		VOIDED_REASON_MESSAGE: 'common.voidedReasonMessage',
	},
	PROCESSING: 'common.processing',
	PROMPT_MESSAGE: 'common.saveBeforeContinuing',
	WEBSITE_TITLE: 'common.companyAndMotto',
};

const baseLanguage = {
	changeAccess: {
		CLIENT: 'changeAccess.client',
		ORGANIZATION: 'changeAccess.organization',
		ROLE: 'changeAccess.role',
		STOREROOM: 'changeAccess.storeroom',
		TITLE: 'changeStoreroom.title',
	},
	changePassword: {
		PASSWORD_CHANGED: 'changePassword.passwordChanged',
		error: {
			NO_CURRENT_PASSWORD: 'changePassword.enterCurrentPassword',
			PASSWORDS_DIFFER: 'changePassword.passwordsDiffer',
			NO_NEW_PASSWORD_ENTERED: 'changePassword.enterNewPassword',
		},
	},
	changeStoreroom: {
		TITLE: 'changeStoreroom.title',
	},
	codedDiagnosis: {
		error: {
			COULD_NOT_DELETE: 'codedDiagnosis.couldNotDelete',
			COULD_NOT_LOAD: 'codedDiagnosis.couldNotLoad',
			COULD_NOT_SAVE: 'codedDiagnosis.couldNotSave',
		},
		LOADING: 'codedDiagnosis.loading',
		title: {
			LIST: 'codedDiagnosis.list',
			VIEW: 'codedDiagnosis.view',
		},
		labels: {
			CIEL_NAME: 'codedDiagnosis.cielName',
			ICD10_WHO: 'codedDiagnosis.icd10Who',
			CIEL_ID: 'codedDiagnosis.cielId',
			CONCEPT_CLASS: 'codedDiagnosis.conceptClass',
			SEARCH_TERMS: 'codedDiagnosis.searchTerms',
			SYNOMED_CT: 'codedDiagnosis.synomedCt',
			SYNOMED_NP: 'codedDiagnosis.synomedNp',
			SYNONYMS: 'codedDiagnosis.synonyms',
			DESCRIPTION: 'common.description',
			MOH705A_LESSTHAN5: 'codedDiagnosis.moh705alessthan5',
			MOH705B_GREATERTHAN5: 'codedDiagnosis.moh705bgreaterthan5',
		},
		NOT_FOUND: 'codedDiagnosis.notFound',
		validationMessages: {
			REQUIRE_CIEL_NAME: 'codedDiagnosis.cielNameRequired',
		},
		success: {
			UPDATE: 'codedDiagnosis.successfulUpdate',
		},
	},
	dashboard: {
		TITLE: 'common.dashboard',
	},
	diagnostic: {
		button: {
			REVERT_NAME: 'diagnostic.revertName',
		},
		error: {
			COULD_NOT_LOAD: 'diagnostic.couldNotLoad',
			COULD_NOT_SAVE: 'diagnostic.couldNotSave',
			LOCAL_NAME_REQUIRED: 'diagnostic.localNameRequired',
		},
		LOADING: 'diagnostic.loading',
		labels: {
			ACTIVE: 'common.active',
			CIEL_ID: 'diagnostic.cielId',
			CIEL_NAME: 'diagnostic.cielName',
			DESCRIPTION: 'common.description',
			DIAGNOSTIC_CATEGORY: 'diagnostic.diagnosticCategory',
			LOCAL_NAME: 'diagnostic.localName',
			MOH_706: 'diagnostic.moh706',
			NAME: 'common.name',
			PANEL: 'diagnostic.panel',
			REFERENCE_RANGE: 'diagnostic.referenceRange',
			SCIENTIFIC_NAME: 'diagnostic.scientificName',
			SPECIMEN_TYPE: 'diagnostic.specimenType',
			UNITS: 'diagnostic.units',
		},
		success: {
			UPDATE: 'diagnostic.successfulUpdate',
		},
		title: {
			LIST: 'diagnostic.list',
			VIEW: 'diagnostic.view',
		},
	},
	document: { COULD_NOT_PROCESS_TRY_AGAIN: 'document.couldNotProcessPleaseFixAndTryAgain' },
	error: {
		ENTITY_ALREADY_EXISTS: 'error.entityAlreadyExists',
		FOLLOWING_ERROR_OCCURRED: 'error.followingErrorOccurred',
		INTERNAL_SERVER_ERROR: 'error.500',
		PLEASE_TRY_AGAIN: 'error.errorSavingTryAgain',
		SERVER_UNREACHABLE: 'error.502',
		FIELD_REQUIRED: 'error.fieldRequired',
		FORBIDDEN: 'error.403',
		NOT_FOUND: 'error.notFound',
		SLOW_CONNECTION: 'error.slowConnection',
		UNAUTHORIZED: 'error.401',
	},
	expense: {
		button: {
			VOID_EXPENSE: 'expense.voidExpense',
		},
		CREATED_BY: 'expense.createdBy',
		form: {
			TITLE: 'expense.information',
			DATE: 'common.date',
			PAYMENT_METHOD: 'expense.paymentMethod',
			PAYMENT_METHOD_OPTION: 'expense.selectPaymentMethod',
		},
		LOADING: 'expense.loading',
		message: {
			PROCESSING: 'expense.processing',
		},
		supplier: {
			DETAILS: 'expense.supplierDetails',
			NOT_FOUND: 'supplier.notFound',
			SEARCH: 'expense.supplierSearch',
			PLACEHOLDER: 'expense.enterSupplierName',
			PROMPT_TEXT: 'common.searching',
			SEARCH_TEXT: 'common.searching',
		},
		UPDATE_SUCCESSFUL: 'expense.expenseUpdated',
		category: {
			PLACEHOLDER: 'expenseCategory.search',
		},
		columnHeader: {
			DATE: 'common.date',
			SUPPLIER: 'expense.supplierName',
			TOTAL: 'expense.total',
			STATUS: 'expense.status',
		},
		pageDisplay: {
			LIST: 'expense.trackedExpense',
			ADD: 'expense.add',
			EDIT: 'expense.edit',
			VIEW: 'expense.view',
		},
		error: {
			COULD_NOT_DELETE: 'expense.couldNotDelete',
			COULD_NOT_LOAD: 'expense.couldNotLoad',
			SUPPLIER: 'expense.supplierRequired',
			SUPPLIER_NOT_FOUND: 'expense.supplierNotFound',
			PAYMENT_METHOD: 'expense.paymentMethodRequired',
			LINE_ITEM_AMOUNT: 'expense.lineItemAmountRequired',
		},
		lineItem: {
			header: {
				CATEGORY: 'common.category',
				DESCRIPTION: 'common.description',
				AMOUNT: 'expense.amount',
				ACTION: 'common.delete',
			},
			search: {
				EMPTY: 'expenseCategory.notFound',
				PROMPT_TEXT: 'common.searching',
				SEARCH_TEXT: 'common.searching',
			},
			summary: {
				TOTAL: 'expense.total',
			},
		},
	},
	expenseCategory: {
		account: {
			LABEL: 'common.account',
			LOADING: 'account.loading',
			SELECT: 'account.select',
		},
		error: {
			COULD_NOT_DELETE: 'expenseCategory.couldNotDelete',
			COULD_NOT_LOAD: 'expenseCategory.couldNotLoad',
			COULD_NOT_SAVE: 'expenseCategory.couldNotSave',
		},
		label: {
			ENTER_NAME: 'expenseCategory.enterName',
		},
		LOADING: 'expenseCategory.loading',
		success: {
			UPDATE: 'expenseCategory.successfulUpdate',
		},
		title: {
			LIST: 'expenseCategory.list',
			NEW: 'expenseCategory.add',
			UPDATE: 'expenseCategory.update',
		},
		tableHeaders: {
			CREATED: 'common.created',
			DESCRIPTION: 'common.description',
			ENTITY_NAME: 'common.name',
		},
		validationMessages: {
			REQUIRE_NAME: 'expenseCategory.nameRequired',
			REQUIRE_CATEGORY: 'expenseCategory.categoryRequired',
		},
	},
	income: {
		button: {
			VOID_INCOME: 'income.voidIncome',
		},
		CREATED_BY: 'income.createdBy',
		form: {
			TITLE: 'income.information',
			DATE: 'common.date',
			PAYMENT_METHOD: 'income.paymentMethod',
			PAYMENT_METHOD_OPTION: 'income.selectPaymentMethod',
		},
		LOADING: 'income.loading',
		message: {
			PROCESSING: 'income.processing',
		},
		PROCESS: 'income.process',
		category: {
			PLACEHOLDER: 'incomeCategory.search',
		},
		columnHeader: {
			DATE: 'common.date',
			INSURER_STROKE_DONOR: 'nonPatientPayment.insurerStrokeDonor',
			TOTAL: 'income.total',
			STATUS: 'income.status',
		},
		pageDisplay: {
			LIST: 'income.trackedIncome',
			ADD: 'income.add',
			EDIT: 'income.edit',
			VIEW: 'income.view',
		},
		error: {
			COULD_NOT_DELETE: 'income.couldNotDelete',
			COULD_NOT_LOAD: 'income.couldNotLoad',
			INSURER_STROKE_DONOR_REQUIRED: 'income.insurerStrokeDonorRequired',
			PAYMENT_METHOD: 'income.paymentMethodRequired',
			LINE_ITEM_AMOUNT: 'income.lineItemAmountRequired',
		},
		lineItem: {
			header: {
				CATEGORY: 'common.category',
				DESCRIPTION: 'common.description',
				AMOUNT: 'income.amount',
				ACTION: 'common.delete',
			},
			search: {
				EMPTY: 'incomeCategory.notFound',
				PROMPT_TEXT: 'common.searching',
				SEARCH_TEXT: 'common.searching',
			},
			summary: {
				TOTAL: 'income.total',
			},
		},
	},
	inventory: {
		button: {
			CLEAN: 'common.clean',
			UPDATE_LOWER: 'inventory.updateLower',
		},
		CLEAN_CONFIRMATION_MESSAGE: 'inventory.cleanProcessWillRemoveExpiredAndOldInventoryAndReconcileNegative',
		ERROR_CLEANING: 'inventory.cleanProcessError',
		label: {
			EXPIRATION_DATE: 'inventory.expirationDate',
			QUANTITY: 'inventory.quantity',
			UPDATE_REASON: 'inventory.updateReason',
		},
		SELECT_REASON: 'inventory.select',
		filter: {
			EXPIRED_PRODUCTS: 'inventory.expiredProducts',
			SHOW_ITEMS: 'inventory.showItems',
			SHOW_STOREROOMS: 'inventory.showStorerooms',
			WITH_STOCK: 'inventory.withStock',
		},
		loading: {
			FAIL: 'inventory.unableToLoad',
			LOADING: 'inventory.loading',
		},
		QUANTITY_UPDATE: 'inventory.quantityUpdate',
		STOCK_CLEANED: 'inventory.inventoryCleaned',
		STOREROOM: 'inventory.storeroom',
		title: {
			LIST: 'inventory.list',
		},
		updateQuantityProcess: {
			success: {
				MESSAGE: 'inventory.quantityUpdated',
				TITLE: 'inventory.success',
			},
			error: {
				ERROR_UPDATING_QUANTITY: 'inventory.couldNotUpdateQuantity',
				MISSING_EXPIRATION_DATE: 'inventory.requiredExpirationDate',
				MISSING_UPDATE_REASON: 'inventory.requiredUpdateReason',
				PRODUCT_MISCONFIGURED: 'inventory.productMisconfiguration',
				UPDATING_EXPIRED_PRODUCT_QUANTITY: 'inventory.cannotUpdateExpired',
			},
		},
	},
	layout: {
		CHANGE_STOREROOM: 'common.changeStoreroom',
		CHANGE_PASSWORD: 'common.changePassword',
		DATA_REFRESHED: 'common.dataRefreshed',
		PRIVACY_POLICY: 'common.privacyPolicy',
		HOW_TO_VIDEOS: 'common.howToVideos',
		REFRESH: 'common.refresh',
		SIGN_OUT: 'common.signOut',
		MANAGE_USERS: 'common.manageUsers',
		TERMS_OF_SERVICE: 'common.termsOfService',
	},
	login: {
		ABOUT: 'login.about',
		CONTACT: 'login.contact',
		changePassword: {
			CURRENT_PASSWORD: 'login.currentPassword',
			NEW_PASSWORD: 'login.newPassword',
			RETYPE_PASSWORD: 'login.retypePassword',
			CHANGE: 'common.changePassword',
		},
		ENTER_USERNAME_PASSWORD: 'login.enterUserNameOrPassword',
		error: {
			ENTER_PASSWORD: 'error.enterPassword',
			ENTER_USERNAME: 'error.enterUsername',
			ERROR_OCCURRED: 'error.errorOccurred',
			NO_CLIENTS: 'error.noClients',
			WRONG_USERNAME_PASSWORD: 'error.wrongUsernamePassword',
			WRONG_PASSWORD: 'error.wrongCurrentPassword',
		},
		LOADING: 'login.loading',
		LOG_IN: 'common.login',
		MOTTO: 'login.motto',
		PASSWORD: 'login.password',
		REMEMBER_ME: 'login.rememberMe',
		SELECT_CLIENT: 'login.selectClient',
		SIGN_IN: 'login.signIn',
		SHOW_PASSWORD: 'login.showPassword',
		USERNAME_OR_EMAIL: 'login.userNameOrEmail',
		WELCOME: 'login.welcome',
	},
	manageUsers: {
		button: {
			ACTIVATION: 'manageUsers.activation',
			ACTIVATE: 'manageUsers.activate',
			ACTIVE: 'common.active',
			DEACTIVATE: 'manageUsers.deactivate',
			INACTIVE: 'common.inactive',
			NEW: 'common.new',
		},
		ENTER_PASSWORD: 'manageUsers.enterPassword',
		error: {
			COULD_NOT_DELETE: 'manageUsers.couldNotDelete',
			COULD_NOT_LOAD: 'manageUsers.couldNotLoad',
			COULD_NOT_SAVE: 'manageUsers.couldNotSave',
			COULD_NOT_LOCATE_USER: 'manageUsers.couldNotLocateUser',
			ERROR_WITH_PASSWORD: 'manageUsers.errorWithPassword',
			USER_ALREADY_EXISTS: 'manageUsers.userAlreadyExists',
		},
		LABELS: {
			ACTIVE: 'common.active',
			ENTER_USERNAME: 'manageUsers.enterUsername',
			LAST_LOGIN: 'manageUsers.lastLogin',
			SELECT_ROLE: 'manageUsers.selectRole',
			USERNAME: 'manageUsers.username',
		},
		LOADING: 'manageUsers.loading',
		ONE_TIME_PASSWORD: 'manageUsers.oneTimePassword',
		RESET: 'common.reset',
		RESET_PASSWORD: 'common.resetPassword',
		tableHeaders: {
			ACTION: 'manageUsers.action',
			DATE_CREATED: 'manageUsers.dateCreated',
			LAST_LOGIN: 'manageUsers.lastLogin',
			ROLE: 'manageUsers.role',
			STATUS: 'common.status',
			USERNAME: 'manageUsers.username',
		},
		title: {
			LIST: 'manageUsers.list',
		},
		toast: {
			UPDATE_FAIL: 'manageUsers.couldNotSave',
			UPDATE_SUCCESS: 'manageUsers.updateSuccess',
		},
		validationMessages: {
			REQUIRE_ONE_TIME_PASSWORD: 'manageUsers.requireOneTimePassword',
			REQUIRE_ROLE: 'manageUsers.requireRole',
			REQUIRE_USERNAME: 'manageUsers.requireUsername',
		},
	},
	modal: {
		CONTINUE: 'common.continue',
		OK: 'common.ok',
		WARNING: 'common.warning',
	},
	navigation: {
		REPORTS: 'common.report_plural',
	},
	nonPatientPayment: {
		account: {
			LABEL: 'common.account',
			LOADING: 'account.loading',
			SELECT: 'account.select',
		},
		additionalData: {
			TITLE: 'nonPatientPayment.additionalDataToCollectOnAVisit',
		},
		button: {
			NEED_ADDITIONAL_VISIT_INFORMATION: 'nonPatientPayment.collectAdditionalDataOnAVisit',
		},
		category: {
			LABEL: 'nonPatientPayment.category',
			LOADING: 'nonPatientPayment.loadingCategories',
			SELECT: 'nonPatientPayment.selectCategory',
		},
		CLAIM_AMOUNT: 'nonPatientPayment.claimAmount',
		EDIT_LIST_VALUES: 'nonPatientPayment.editListValues',
		error: {
			COULD_NOT_DELETE: 'nonPatientPayment.couldNotDelete',
			COULD_NOT_LOAD: 'nonPatientPayment.couldNotLoad',
			COULD_NOT_SAVE: 'nonPatientPayment.couldNotSave',
		},
		filter: {
			SHOW_INACTIVE: 'nonPatientPayment.showInactive',
		},
		INSURER_STROKE_DONOR: 'nonPatientPayment.insurerStrokeDonor',
		label: {
			ENTER_NAME: 'nonPatientPayment.enterName',
			ENTER_VALUE: 'nonPatientPayment.enterValue',
		},
		LOADING: 'nonPatientPayment.loading',
		PROFIT: 'nonPatientPayment.profit',
		SEARCH_INSURER_STROKE_DONOR: 'nonPatientPayment.searchInsurerStrokeDoner',
		success: {
			UPDATE: 'nonPatientPayment.successfulUpdate',
		},
		title: {
			LIST: 'nonPatientPayment.insurersAndDonors',
			NEW: 'nonPatientPayment.addInsurerOrDonor',
			UPDATE: 'nonPatientPayment.updateInsurerOrDonor',
		},
		tableHeaders: {
			CREATED: 'common.created',
			FILL_FROM_PATIENT: 'nonPatientPayment.fillFromPatient',
			NAME: 'nonPatientPayment.name',
			ORDER: 'nonPatientPayment.order',
			TYPE: 'nonPatientPayment.type',
			VALUE: 'common.value',
			VALUES: 'common.value_plural',
		},
		validationMessages: {
			REQUIRE_NAME: 'nonPatientPayment.nameRequired',
			REQUIRE_ACCOUNT: 'nonPatientPayment.accountRequired',
			REQUIRE_CATEGORY: 'nonPatientPayment.categoryRequired',
			REQUIRE_INFO_TO_HAVE_NAME: 'nonPatientPayment.informationNamesRequired',
			REQUIRE_INFO_ALL_LIST_TYPES_NEED_LIST_VALUES: 'nonPatientPayment.informationListNamesRequired',
		},
	},
	notificationContainer: {
		button: {
			clickReload: 'deploymentNotification.clickReload',
		},
		prompt: {
			PROMPT_MESSAGE: 'common.saveBeforeContinuing',
		},
		updateAvailable: 'deploymentNotification.updateAvailable',
	},
	order: {
		ERROR_SAVING_PLEASE_TRY_AGAIN: 'order.errorSavingPleaseTryAgain',
		error: {
			EXPENSE_MISSING_PRICE: 'expense.missingPrice',
			MISSING_PATIENT: 'Missing patient.',
			MISSING_PRODUCT: 'error.missingProduct',
			MISSING_SUPPLIER: 'Missing supplier.',
			PRODUCT_MISSING_EXPIRATION_GENERIC: 'product.missingExpirationDateGeneric',
			PRODUCT_MISSING_PRICE: 'product.missingPrice',
			PRODUCT_MISSING_PRICE_GENERIC: 'product.missingPriceGeneric',
			PRODUCT_MISSING_SELL_PRICE_GENERIC: 'product.missingSellPriceGeneric',
			PRODUCT_MISSING_TOTAL_AMOUNT: 'product.missingTotalAmount',
			PRODUCT_MISSING_TOTAL_AMOUNT_GENERIC: 'product.missingTotalAmountGeneric',
			PRODUCT_MISSING_QUANTITY: 'product.missingQuantity',
			PRODUCT_MISSING_QUANTITY_GENERIC: 'product.missingQuantityGeneric',
		},
	},
	organization: {
		error: {
			COULD_NOT_SAVE: 'organization.couldNotSave',
		},
		facilityInformation: 'organization.facilityInformation',
		labels: {
			COUNTY: 'organization.county',
			FACILITY_NUMBER: 'organization.facilityNumber',
			FOOTER: 'organization.footer',
			HEADER: 'organization.header',
			PAYMENT_INFORMATION: 'organization.paymentInformation',
			PHONE: 'organization.phone',
			SUB_COUNTY: 'organization.subCounty',
			WARD: 'organization.ward',
			UPLOAD_LOGO: 'organization.uploadLogo',
		},
		LOADING: 'common.loading',
		officialName: 'organization.officialName',
		placeholders: {
			ENTER_COUNTY: 'organization.enterCounty',
			ENTER_FACILITY_NUMBER: 'organization.enterFacilityNumber',
			ENTER_FOOTER: 'organization.enterFooter',
			ENTER_HEADER: 'organization.enterHeader',
			ENTER_PAYMENT_INFORMATION: 'organization.enterPaymentInformation',
			ENTER_PHONE: 'organization.enterPhone',
			ENTER_SUB_COUNTY: 'organization.enterSubCounty',
			ENTER_WARD: 'organization.enterWard',
		},
		success: {
			UPDATE: 'organization.successfulUpdate',
		},
	},
	patient: {
		action: {},
		additionalInformation: {
			ENTER_PREFIX: 'common.enter',
			LOADING: 'patient.loadingNonPatientPayments',
			NON_PATIENT_PAYMENT: 'common.nonPatientPayment',
			TITLE: 'patient.insuranceAndOtherPaymentInformation',
			PATIENT_INFORMATION: 'patient.patientInformation',
			SELECT_PREFIX: 'common.select',
		},
		address: {
			LABEL: 'patient.address',
			PLACEHOLDER: 'patient.enterAddress',
		},
		age: {
			APPROXIMATE_MONTHS: 'patient.approximateMonths',
			APPROXIMATE_YEARS: 'patient.approximateYears',
			APPROXIMATE_DOB_GUIDE: 'patient.dateOfBirthGuide',
			DATE_OF_BIRTH: 'patient.dateOfBirth',
			DAY: 'patient.day',
			DAYS: 'patient.days',
			LABEL: 'patient.age',
			MONTH: 'patient.month',
			MONTHS: 'patient.months',
			WEEK_AND_DAY: 'patient.weekAndDay',
			WEEK_AND_DAYS: 'patient.weekAndDays',
			WEEKS: 'patient.weeks',
			WEEKS_AND_DAY: 'patient.weeksAndDay',
			WEEKS_AND_DAYS: 'patient.weeksAndDays',
			YEARS: 'patient.years',
		},
		AMOUNT_TO_WAIVE: 'patient.amountToWaive',
		button: {
			OPEN_BALANCE_INVOICE: 'patient.openBalanceInvoice',
			PAY_OPEN_BALANCE: 'patient.payOpenBalance',
			VIEW_PAYMENT_TRAIL: 'patient.paymentTrail',
			NEW_VISIT: 'patient.newvisit',
			START_VISIT: 'visit.startNewVisit',
			ACTION: 'patient.action',
			SAVE_AND_CLOSE: 'patient.saveAndClose',
			SAVE_AND_NEW_PATIENT: 'patient.saveAndNewPatient',
			SAVE_AND_NEW_VISIT: 'patient.saveAndNewVisit',
			WAIVE: 'patient.waive',
			WAIVE_OPEN_BALANCE: 'patient.waiveOpenBalance',
		},
		created: { LABEL: 'common.created' },
		error: {
			CANNOT_LOAD: 'patient.cannotLoad',
			INCORRECT_WAIVE_AMOUNT: 'patient.pleaseEnterACorrectAmountToWaive',
		},
		filter: {
			ACTIVE: 'patient.active',
			DATE_LAST_VISIT: 'patient.dateOfLastVisit',
			INACTIVE: 'patient.inactive',
			LAST_30_DAYS: 'visit.last30Days',
			LAST_7_DAYS: 'visit.last7Days',
			SHOW_INACTIVE: 'common.showInactive',
			TODAY: 'visit.today',
			YESTERDAY: 'visit.yesterday',
		},
		fullName: {
			LABEL: 'patient.fullName',
			PLACEHOLDER: 'patient.enterFullName',
			VALIDATION: 'patient.nameRequired',
		},
		gender: {
			FEMALE: 'patient.female',
			LABEL: 'patient.gender',
			MALE: 'patient.male',
		},
		insurance: {
			nhif: {
				memberName: {
					LABEL: 'insurance.nhif.memberName',
					PLACEHOLDER: 'insurance.nhif.enterMemberName',
				},
				number: {
					LABEL: 'insurance.nhif.number',
					PLACEHOLDER: 'insurance.nhif.enterNumber',
				},
				relationship: {
					LABEL: 'insurance.nhif.relationship',
				},
				type: {
					LABEL: 'insurance.nhif.type',
				},
			},
		},
		LABEL: 'common.patient',
		LOADING: 'patient.loading',
		name: {
			VALIDATION: 'patient.nameRequired',
		},
		nationalId: {
			LABEL: 'patient.nationalId',
			PLACEHOLDER: 'patient.enterNationalId',
		},
		nextOfKin: {
			contact: {
				LABEL: 'patient.nextOfKinContact',
				PLACEHOLDER: 'patient.enterNextOfKinContact',
			},
			name: {
				LABEL: 'patient.nextOfKinName',
				PLACEHOLDER: 'patient.enterNextOfKinName',
			},
		},
		occupation: {
			LABEL: 'patient.occupation',
			PLACEHOLDER: 'patient.enterOccupation',
		},
		OPEN_BALANCE: 'patient.openBalance',
		patientNumber: {
			BANDA: 'patient.bandaPatientNumber',
			BANDA_ABBREVIATED: 'patient.bandaPatientNumberAbbreviated',
			ENTER_LOCAL: 'patient.enterLocalPatientNumber',
			LOCAL: 'patient.localPatientNumber',
			LOCAL_ABBREVIATED: 'patient.localPatientNumberAbbreviated',
		},
		patientAge: {
			YEARS_OR_MONTHS: 'patient.patientAgeLabel',
			YEARS: 'patient.patientAgeInYears',
			MONTHS: 'patient.patientAgeInMonths',
		},
		phone: {
			LABEL: 'common.phone',
			PLACEHOLDER: 'patient.enterPhone',
		},
		search: {
			EMPTY: 'patient.notFound',
			PLACEHOLDER: 'patient.search',
			SEARCHING: 'common.searching',
		},
		success: {
			REGISTERED: 'patient.successFullyRegistered',
		},
		title: {
			LIST: 'patient.list',
			NEW: 'patient.new',
			PAYMENT_UPDATE: 'patient.paymentUpdate',
			UPDATE: 'patient.update',
			ADDITIONAL_INFO: 'patient.additionalInformation',
		},
		tabs: {
			GENERAL_INFO: 'patient.generalInformation',
			INSURANCE_AND_PAYMENT_INFO: 'patient.insuranceAndOtherPaymentInformation',
		},
		validationMessages: { REQUIRE_INFO_TO_BE_ENTERED: 'patient.allInformationMustBeEntered' },
		visit: {
			LAST: 'patient.dateOfLastVisit',
			PREVIOUS: 'patient.previousVisits',
		},
		WAIVE_ALL: 'patient.waiveAll',
	},
	payment: {
		action: {
			PRINT_RECEIPT: 'payment.printReceipt',
			PROCESS: 'payment.process',
			SAVE: 'payment.save',
		},
		button: {
			VOID_PAYMENT: 'payment.voidPayment',
		},
		CHANGE: 'payment.change',
		error: {
			FULL_BALANCE_MUST_BE_PAID: 'payment.fullBalanceMustBePaid',
			MISSING_AMOUNT: 'payment.missingAmount',
			MISSING_INSURER_OR_DONOR: 'payment.missingInsurerOrDonor',
			MISSING_TRANSACTION_DATE: 'payment.missingTransactionDate',
			MISSING_TYPE: 'payment.missingType',
			PAYMENT_REQUIRED: 'payment.paymentRequired',
		},
		filter: {
			ONLY_OPEN_BALANCES: 'payment.onlyOpenBalances',
			OPEN_BALANCES: 'payment.openBalances',
		},
		insurance: {
			nhif: {
				claimNumber: {
					LABEL: 'insurance.claimNumber',
					PLACEHOLDER: 'insurance.enterClaimNumber',
				},
				memberId: {
					LABEL: 'insurance.memberId',
					PLACEHOLDER: 'insurance.enterMemberId',
				},
				memberName: {
					LABEL: 'insurance.nhif.memberNameNoNhif',
					PLACEHOLDER: 'insurance.nhif.enterMemberNameNoNhif',
				},
				number: {
					LABEL: 'insurance.nhif.number',
					PLACEHOLDER: 'insurance.nhif.enterNumber',
				},
				relationship: {
					LABEL: 'insurance.nhif.relationship',
					SELECT: 'insurance.nhif.selectRelationship',
				},
				type: {
					LABEL: 'insurance.nhif.type',
					PLACEHOLDER: 'insurance.nhif.type',
					SELECT: 'insurance.nhif.selectType',
				},
			},
		},
		loading: {
			FAIL: 'payment.unableToLoad',
			LOADING: 'payment.loading',
		},
		PATIENT_NAME: 'payment.patientName',
		PAYMENT_AMOUNT: 'payment.paymentAmount',
		PAYMENT_STATUS: 'payment.paymentStatus',
		PROCESS_SUCCESS: 'payment.successfullyProcessed',
		PROCESSING: 'payment.processing',
		tenderType: {
			LABEL: 'payment.tenderType',
			VALIDATION: 'payment.tenderTypeRequired',
		},
		title: {
			LIST: 'payment.list',
			NEW: 'payment.add',
			UPDATE: 'payment.update',
		},
		TRANSACTION_DATE: 'payment.transactionDate',
	},
	pharmacySales: {
		button: {
			COMPLETE_AND_PRINT_RECEIPT: 'visit.completeAndPrintReceipt',
		},
		title: {
			NEW: 'common.pharmacySale',
		},
	},
	product: {
		BUY_PRICE: 'product.buyPrice',
		DOES_NOT_EXPIRE: 'product.doesNotExpire',
		error: {
			ALREADY_EXISTS: 'product.alreadyExists',
			PLEASE_TRY_AGAIN: 'product.errorSavingProductTryAgain',
			DUPLICATE_STOCK_EXPIRATION_DATES: 'product.duplicateStockExpirationDate',
			MISSING_EXPIRATION_DATE: 'product.missingExpirationDate',
			MISSING_STOCK_EXPIRATION_DATE: 'product.missingStockExpirationDate',
			MISSING_STOCK_QUANTITY: 'product.missingStockQuantity',
			SAVE_UPDATE: 'product.saveUpdate',
			STOCK_QUANTITY_INCORRECT: 'product.stockQuantityIncorrect',
			UNABLE_TO_LOAD: 'product.unableToLoad',
		},
		expirationDate: {
			DAYS_TO_EXPIRATION: 'product.daysToExpiration',
			LABEL_ABBREVIATION: 'product.expirationDateAbbreviation',
			LABEL: 'product.expirationDate',
		},
		button: {
			SAVE_AND_NEW_PRODUCT: 'product.saveAndNewProduct',
		},
		PURCHASED_ON: 'product.purchasedOn',
		RECEIVED_ON: 'product.receivedOn',
		title: {
			LIST: 'product.list',
			NEW: 'product.new',
			UPDATE: 'product.update',
		},
		INITIAL_STOCK: 'product.initialStock',
		LOADING_TEXT: 'product.loading',
		name: {
			byOthers: {
				LABEL: 'product.name',
			},
		},
		tableHeaders: {
			CREATED: 'common.created',
			NAME: 'product.name',
			UNIT_BUY_PRICE: 'product.unitBuyPrice',
			UNIT_SELL_PRICE: 'product.unitSellPrice',
			PRICE_MARGIN: 'product.priceMargin',
		},
		tabs: {
			GENERAL_INFORMATION: 'product.generalInformation',
			INVENTORY_TRANSACTIONS: 'product.inventoryTransactions',
			CURRENT_INVENTORY: 'product.currentInventory',
		},
		filter: {
			ACTIVE: 'product.active',
			CATEGORIES: 'common.category_plural',
			INACTIVE: 'product.inactive',
		},
		validationMessages: {
			REQUIRE_PRODUCT_NAME: 'product.requireName',
			REQUIRE_CATEGORY_NAME: 'product.requireCategoryName',
		},
		labels: {
			ENTER_DESCRIPTION: 'common.enterDescription',
			ENTER_PRODUCT_NAME: 'product.enterName',
			ENTER_REORDER_LEVEL: 'product.enterReorderLevel',
			ENTER_REORDER_QUANTITY: 'product.enterReorderQuantity',
			NAME: 'product.name',
			SELECT_PRODUCT_CATEGORY: 'product.selectCategory',
			CATEGORY: 'common.category',
			SELL_PRICE: 'product.sellPrice',
			PRICE_MARGIN: 'product.priceMargin',
			REORDER_LEVEL: 'product.reorderLevel',
			REORDER_QUANTITY: 'product.reorderQuantity',
			CURRENT_QUANTITY: 'product.currentQuantity',
			DESCRIPTION: 'common.description',
			PRODUCT_EXPIRES: 'product.expires',
			UNIT_BUY_PRICE: 'product.unitBuyPrice',
			UNIT_SELL_PRICE: 'product.unitSellPrice',
		},
		search: {
			EMPTY: 'product.notFound',
			PLACEHOLDER: 'product.search',
			SEARCHING: 'common.searching',
		},
		userMessages: {
			CANNOT_DEACTIVATE: 'product.cannotDeactivate',
			UPDATE_SUCCESS: 'product.updateSuccess',
		},
		quantity: {
			LABEL: 'product.quantity',
		},
	},
	receipt: { PRINT: 'common.printReceipt' },
	receiveProduct: {
		button: {
			ACTION: 'common.action',
			REACTIVATE: 'common.reactivate',
			VOID_RECEIVE_PRODUCT: 'receiveProduct.voidReceiveProduct',
		},
		DATE_RECEIVED: 'receiveProduct.dateReceived',
		EXPIRATION_DATE: 'receiveProduct.expirationDate',
		loading: {
			FAIL: 'receiveProduct.unableToLoad',
			IN_PROCESS: 'receiveProduct.processing',
			LOADING: 'receiveProduct.loading',
		},
		ORDER_INFORMATION: 'receiveProduct.orderInformation',
		PRODUCTS: 'receiveProduct.products',
		QUANTITY_RECEIVED: 'receiveProduct.quantityReceived',
		SAVED: 'receiveProduct.receiveProductSaved',
		STATUS: 'common.status',
		title: {
			ADD: 'receiveProduct.add',
			LIST: 'receiveProduct.list',
		},
		TOTAL: 'receiveProduct.total',
		TOTAL_PAID: 'receiveProduct.totalPaid',
		UNIT_BUY_PRICE: 'receiveProduct.unitBuyPrice',
		modal: {
			TITLE: 'receiveProduct.modalTitle',
			columns: {
				PRODUCT: 'receiveProduct.modalColumnProduct',
				PREVIOUS_BUY_PRICE: 'receiveProduct.modalColumnPrevBuyPrice',
				CURRENT_BUY_PRICE: 'receiveProduct.modalColumnCurrentBuyPrice',
				OLD_MARGIN: 'receiveProduct.modalColumnOldMargin',
				NEW_MARGIN: 'receiveProduct.modalColumnNewMargin',
				SELL_PRICE: 'receiveProduct.modalColumnSellPrice',
			},
			SAVE_CHANGES: 'receiveProduct.modalSaveChanges',
		},
	},
	report: {
		FORMAT: 'report.format',
		generate: {
			CREATING: 'report.creating',
			ERROR: 'report.error',
			ERROR_MESSAGE: 'report.errorMessage',
			GENERATE: 'report.generate',
			TITLE: 'report.generateReport',
		},
		NO_DATA_FETCHER_DEFINED: 'report.noDataFetcherDefined',
		NOTHING_FOUND: 'report.nothingFound',
		PAYMENT_MODE: 'report.paymentMode',
		PATIENT_SUMMARY: 'report.patientSummary',
		PLACEHOLDER: 'common.search',
		SEARCHING: 'common.searching',
		SELECT: 'report.select',
		TITLE: 'common.report_plural',
		ALL: 'common.all',
	},
	search: {
		PLACEHOLDER: 'common.search',
	},
	session: {
		TIMEOUT: 'common.sessionTimeout',
	},
	service: {
		error: {
			ALREADY_EXISTS: 'service.alreadyExists',
			SAVE_UPDATE: 'service.saveUpdate',
			UNABLE_TO_LOAD: 'service.unableToLoad',
		},
		CREATED: 'common.created',
		button: {
			SAVE_AND_NEW_SERVICE: 'service.saveAndNewService',
		},
		filter: {
			ACTIVE: 'service.active',
			INACTIVE: 'service.inactive',
			SHOW_INACTIVE: 'service.showInactive',
		},
		loading: {
			FAIL: 'service.unableToLoad',
			LOADING: 'service.loading',
		},
		name: {
			PLACEHOLDER: 'service.enterName',
			validation: {
				INVALID_TOOLTIP: 'service.enterName',
				REQUIRED: 'service.nameRequired',
			},
		},
		save: {
			SUCCESS: 'service.successfullyUpdated',
		},
		sellingPrice: {
			LABEL: 'service.sellingPrice',
			PLACEHOLDER: 'service.enterSellingPrice',
		},
		title: {
			LIST: 'service.list',
			NEW: 'service.new',
			UPDATE: 'service.update',
		},
	},
	supplier: {
		email: {
			HELP: 'supplier.emailAddress',
			LABEL: 'common.email',
			LABEL_FULL: 'common.emailAddress',
			TITLE: 'supplier.mainEmailAddress',
			VALIDATION: 'supplier.enterValidEmailAddress',
		},
		error: {
			COULD_NOT_SAVE: 'supplier.couldNotSave',
		},
		button: {
			SAVE_AND_NEW_SUPPLIER: 'supplier.saveAndNewSupplier',
		},
		DETAILS: 'supplier.details',
		filter: {
			ACTIVE: 'supplier.active',
			INACTIVE: 'supplier.inactive',
			SHOW_INACTIVE: 'supplier.showInactive',
		},
		LABEL: 'common.supplier',
		loading: {
			LOADING: 'supplier.loading',
		},
		name: {
			byOthers: {
				LABEL: 'supplier.name',
			},
			HELP: 'supplier.companyOrPerson',
			LABEL: 'common.name',
			VALIDATION: 'supplier.nameRequired',
			VALIDATION2: 'supplier.nameRequired',
		},
		message: {
			PROCESSING: 'supplier.processing',
		},
		phone: {
			ABBREVIATION: 'common.phone',
			HELP: 'supplier.phoneContacts',
			LABEL: 'common.phoneNumber',
			VALIDATION: 'supplier.contactRequired',
		},
		search: {
			EMPTY: 'supplier.notFound',
			PLACEHOLDER: 'supplier.search',
			SEARCHING: 'common.searching',
		},
		summary: {
			ADDRESS: 'supplier.addressWithData',
			EDIT: 'supplier.edit',
			OPEN_BALANCE: 'supplier.openBalanceWithData',
			PHONE: 'supplier.phoneWithData',
		},
		tableHeaders: {
			CREATED: 'common.created',
			EMAIL_ADDRESS: 'common.emailAddress',
			ENTITY_NAME: 'common.name',
		},
		title: {
			LIST: 'supplier.list',
			NEW: 'supplier.new',
			REGISTER: 'supplier.register',
			UPDATE: 'supplier.editSupplier',
			UPDATE2: 'supplier.update',
		},
		userMessages: {
			UPDATE_SUCCESS: 'supplier.updateSuccess',
		},
		validationMessages: {
			REQUIRE_SUPPLIER_NAME: 'supplier.requireName',
		},
	},
	table: {
		NO_RECORDS: 'common.noRecords',
		RESULTS: 'common.results',
	},
	transaction: {
		columnHeader: {
			BATCH: 'transaction.batch',
			DATE: 'transaction.date',
			DONE_BY: 'transaction.doneBy',
			QUANTITY: 'transaction.quantity',
			STORE_ROOM: 'transaction.storeroom',
			TOTAL: 'transaction.total',
			TRANSACTION_TYPE: 'transaction.transactionType',
		},
		DRAFTED_STATUS: 'transaction.draftedStatus',
		EXPIRATION: 'transaction.expiration',
		loading: {
			FAIL: 'transaction.unableToLoad',
			LOADING: 'transaction.loading',
		},
		transactionType: {
			DRAFTED: 'transaction.drafted',
			INITIAL_INVENTORY: 'transaction.initialInventory',
			MANUAL_INVENTORY_ADJUSTMENT: 'transaction.manualInventoryAdjustment',
			OTC_SALE: 'transaction.otcSale',
			PATIENT_RETURNS: 'transaction.patientReturns',
			PATIENT_SALE: 'transaction.patientSale',
			PRODUCT_RECEIVED: 'transaction.productReceived',
			REACTIVATE_PATIENT_SALE: 'transaction.reactivatePatientSale',
			REACTIVATED_PRODUCT_RECEIPT: 'transaction.reactivatedProductReceipt',
			SUPPLIER_RETURNS: 'transaction.supplierReturns',
			TRANSFER_IN: 'transaction.transferIn',
			TRANSFER_OUT: 'transaction.transferOut',
			UNKNOWN_STATUS: 'transaction.unknown',
			VOID_PATIENT_SALE: 'transaction.voidPatientSale',
			VOID_PRODUCT_RECEIPT: 'transaction.voidProductReceipt',
		},
	},
	termsOfService: {
		ACCEPT: 'termsOfService.accept',
		content: {
			acceptance: {
				HEADER: 'termsOfService:acceptance.header',
				TEXT: 'termsOfService:acceptance.text',
			},
			updates: {
				HEADER: 'termsOfService:updates.header',
				TEXT: 'termsOfService:updates.text',
			},
			eligibility: {
				HEADER: 'termsOfService:eligibility.header',
				TEXT: 'termsOfService:eligibility.text',
			},
			registration: {
				HEADER: 'termsOfService:registration.header',
				TEXT: 'termsOfService:registration.text',
			},
			feesAndPayments: {
				HEADER: 'termsOfService:feesAndPayments.header',
				TEXT: 'termsOfService:feesAndPayments.text',
			},
			taxes: {
				HEADER: 'termsOfService:taxes.header',
				TEXT: 'termsOfService:taxes.text',
			},
			notHealthcareServices: {
				HEADER: 'termsOfService:notHealthcareServices.header',
				TEXT: 'termsOfService:notHealthcareServices.text',
			},
			serviceDataAndAnalytics: {
				HEADER: 'termsOfService:serviceDataAndAnalytics.header',
				TEXT: 'termsOfService:serviceDataAndAnalytics.text',
			},
			userRulesAndConduct: {
				HEADER: 'termsOfService:userRulesAndConduct.header',
				TEXT: 'termsOfService:userRulesAndConduct.text',
			},
			connections: {
				HEADER: 'termsOfService:connections.header',
				TEXT: 'termsOfService:connections.text',
			},
			advertisingAndPromotions: {
				HEADER: 'termsOfService:advertisingAndPromotions.header',
				TEXT: 'termsOfService:advertisingAndPromotions.text',
			},
			proprietaryRights: {
				HEADER: 'termsOfService:proprietaryRights.header',
				TEXT: 'termsOfService:proprietaryRights.text',
			},
			feedbackLicense: {
				HEADER: 'termsOfService:feedbackLicense.header',
				TEXT: 'termsOfService:feedbackLicense.text',
			},
			termination: {
				HEADER: 'termsOfService:termination.header',
				TEXT: 'termsOfService:termination.text',
			},
			noWarranties: {
				HEADER: 'termsOfService:noWarranties.header',
				TEXT: 'termsOfService:noWarranties.text',
			},
			limitationOfLiability: {
				HEADER: 'termsOfService:limitationOfLiability.header',
				TEXT: 'termsOfService:limitationOfLiability.text',
			},
			indemnification: {
				HEADER: 'termsOfService:indemnification.header',
				TEXT: 'termsOfService:indemnification.text',
			},
			governingLawAndArbitration: {
				HEADER: 'termsOfService:governingLawAndArbitration.header',
				TEXT: 'termsOfService:governingLawAndArbitration.text',
			},
			integration: {
				HEADER: 'termsOfService:integration.header',
				TEXT: 'termsOfService:integration.text',
			},
			generalProvisions: {
				HEADER: 'termsOfService:generalProvisions.header',
				TEXT: 'termsOfService:generalProvisions.text',
			},
			copyrightAndTrademarkNotices: {
				HEADER: 'termsOfService:copyrightAndTrademarkNotices.header',
				TEXT: 'termsOfService:copyrightAndTrademarkNotices.text',
			},
			privacyPolicy: {
				TITLE: 'termsOfService:privacyPolicy.title',
				COMPANY: 'termsOfService:privacyPolicy.company',
				ourCommitmentToPrivacy: {
					HEADER: 'termsOfService:privacyPolicy.ourCommitmentToPrivacy.header',
					TEXT: 'termsOfService:privacyPolicy.ourCommitmentToPrivacy.text',
				},
				acceptancePrivacyPolicyTerms: {
					HEADER: 'termsOfService:privacyPolicy.acceptancePrivacyPolicyTerms.header',
					TEXT: 'termsOfService:privacyPolicy.acceptancePrivacyPolicyTerms.text',
				},
				links: {
					HEADER: 'termsOfService:privacyPolicy.links.header',
					TEXT: 'termsOfService:privacyPolicy.links.text',
				},
				theInformationWeCollect: {
					HEADER: 'termsOfService:privacyPolicy.theInformationWeCollect.header',
					sectionA: {
						HEADER: 'termsOfService:privacyPolicy.theInformationWeCollect.sectionA.header',
						TEXT: 'termsOfService:privacyPolicy.theInformationWeCollect.sectionA.text',
					},
					sectionB: {
						HEADER: 'termsOfService:privacyPolicy.theInformationWeCollect.sectionB.header',
						TEXT: 'termsOfService:privacyPolicy.theInformationWeCollect.sectionB.text',
					},
					sectionC: {
						HEADER: 'termsOfService:privacyPolicy.theInformationWeCollect.sectionC.header',
						TEXT: 'termsOfService:privacyPolicy.theInformationWeCollect.sectionC.text',
					},
				},
				howWeUsePersonalInformation: {
					HEADER: 'termsOfService:privacyPolicy.howWeUsePersonalInformation.header',
					TEXT: 'termsOfService:privacyPolicy.howWeUsePersonalInformation.text',
				},
				optingOutOrUnsubscribing: {
					HEADER: 'termsOfService:privacyPolicy.optingOutOrUnsubscribing.header',
					TEXT: 'termsOfService:privacyPolicy.optingOutOrUnsubscribing.text',
				},
				ourCommitmentToDataSecurity: {
					HEADER: 'termsOfService:privacyPolicy.ourCommitmentToDataSecurity.header',
					TEXT: 'termsOfService:privacyPolicy.ourCommitmentToDataSecurity.text',
				},
				amendments: {
					HEADER: 'termsOfService:privacyPolicy.amendments.header',
					TEXT: 'termsOfService:privacyPolicy.amendments.text',
				},
				howToContactUs: {
					HEADER: 'termsOfService:privacyPolicy.howToContactUs.header',
					TEXT: 'termsOfService:privacyPolicy.howToContactUs.text',
				},
			},
		},
		REJECT: 'termsOfService.reject',
		TITLE: 'termsOfService.title',
	},
	toolbar: {},
	transferInventory: {
		error: {
			COULD_NOT_DELETE: 'transferInventory.couldNotDelete',
			COULD_NOT_LOAD: 'transferInventory.couldNotLoad',
			COULD_NOT_SAVE: 'transferInventory.couldNotSave',
			DUPLICATE_PRODUCT: 'transferInventory.duplicateProduct',
			MISSING_EXPIRATION: 'transferInventory.missingExpiration',
			MISSING_FROM_STOREROOM: 'transferInventory.missingFromStoreroom',
			MISSING_MOVEMENT_DATE: 'transferInventory.missingMovementDate',
			MISSING_MOVEMENT_QUANTITY: 'transferInventory.missingMovementQuantity',
			MISSING_PRODUCT: 'product.missingProduct',
			MISSING_TO_STOREROOM: 'transferInventory.missingToStoreroom',
		},
		LOADING: 'transferInventory.loading',
		NO_BATCHES_AVAILABLE: 'transferInventory.noBatchesAvailable',
		title: {
			LIST: 'transferInventory.list',
			NEW: 'transferInventory.new',
			VIEW: 'transferInventory.view',
			UPDATE: 'transferInventory.update',
		},
		filter: {
			ALL: 'common.all',
			FROM: 'transferInventory.from',
			TO: 'transferInventory.to',
			USER: 'transferInventory.user',
		},
		labels: {
			BATCH: 'transferInventory.batch',
			DATE: 'transferInventory.date',
			DATE_TRANSFERRED: 'transferInventory.dateTransferred',
			DELETE: 'transferInventory.delete',
			EXISTING_QUANTITY_IN_DESTINATION: 'transferInventory.existingQuantityInDestination',
			EXISTING_QUANTITY_IN_SOURCE: 'transferInventory.existingQuantityInSource',
			EXPIRATION: 'transferInventory.expiration',
			FROM_STOREROOM: 'transferInventory.fromStoreroom',
			PRODUCT: 'transferInventory.product',
			QUANTITY_TO_TRANSFER: 'transferInventory.quantityToTransfer',
			SELECT_BATCH: 'transferInventory.selectBatch',
			STATUS: 'transferInventory.status',
			TO_STOREROOM: 'transferInventory.toStoreroom',
			USER_MAKING_TRANSFER: 'transferInventory.userMakingTransfer',
			VIEW_BATCH: 'transferInventory.viewBatch',
		},
		NOT_FOUND: 'transferInventory.notFound',
		SELECT: 'common.select',
		success: {
			UPDATE: 'transferInventory.successfulUpdate',
		},
	},
	visit: {
		BALANCE_DUE: 'visit.balanceDue',
		BILL_STATUS: 'visit.billStatus',
		BILL_TOTAL: 'visit.billTotal',
		button: {
			ACTION: 'visit.action',
			ADD_NEW_VISIT: 'visit.addNew',
			ADD_NEW_VITAL: 'visit.addNewVital',
			COMPLETE_AND_CLOSE: 'visit.completeAndClose',
			COMPLETE_AND_NEW_VISIT: 'visit.completeAndNewVisit',
			INSURER_INVOICE: 'visit.insurerInvoice',
			PATIENT_INVOICE: 'visit.patientInvoice',
			PATIENT_SUMMARY: 'visit.patientSummary',
			RECEIPT: 'common.receipt',
			REACTIVATE_BILL: 'visit.reactivateBill',
			SAVE_AND_SEND_TO: 'visit.saveAndSendTo',
			SAVE_BILL: 'visit.saveBill',
			VOID_BILL: 'visit.voidBill',
		},
		CHANGE_DUE: 'visit.changeDue',
		CLINICIAN: 'common.clinician',
		CLINICIAN_DISPLAY: 'visit.clinician',
		COUNTING: 'visit.counting',
		DATE: 'visit.date',
		DATE_STROKE_TIME: 'visit.dateStrokeTime',
		DATE_BILLED: 'visit.dateBilled',
		DEFAULT_CLINICAL_NOTES: 'visit.clinicalNotes',
		DETAILS: 'visit.details',
		error: {
			COULD_NOT_DELETE: 'visit.couldNotDelete',
			PLEASE_TRY_AGAIN: 'visit.errorSavingVisitTryAgain',
			SAVE_ERROR: 'visit.saveError',
			TEST_RESULT_MUST_BE_GREATER: 'visit.errorTestResultMustBeGreater',
			TEST_RESULT_MUST_BE_LESS: 'visit.errorTestResultMustBeLess',
			TEST_RESULT_MUST_BE_NUMBER: 'visit.errorTestResultMustBeNumber',
			UNABLE_TO_LOAD: 'visit.unableToLoad',
			UNABLE_TO_PROCESS: 'visit.unableToProcess',
		},
		FETCHING: 'visit.fetching',
		FETCHING_VISITS: 'visit.fetchingVisits',
		form: {
			additionalInformation: {
				INSURER: 'nonPatientPayment.insurer',
				DONOR: 'nonPatientPayment.donor',
				EDIT_PATIENT_INFORMATION: 'visit.editPatientsInsurerStrokeDonorInformation',
				SELECT_TYPE: 'visit.selectType',
			},
			CLINICAL_DETAILS: 'visit.clinicalDetails',
			DATE: 'common.date',
			diagnostic: {
				DELETE_DIAGNOSTIC: 'visit.deleteDiagnostic',
				LAB_DIAGNOSTICS_DETAILS: 'visit.labDiagnostics',
				NOT_FOUND: 'visit.testOrPanelNotFound',
				PLACEHOLDER_NUMERIC: 'visit.placeholderNumericResult',
				PLACEHOLDER_NUMERIC_BETWEEN: 'visit.placeholderNumericResultBetween',
				PLACEHOLDER_NUMERIC_GREATER: 'visit.placeholderNumericResultGreater',
				PLACEHOLDER_NUMERIC_LESS: 'visit.placeholderNumericResultLess',
				PLACEHOLDER_TEXT: 'visit.placeholderTextResult',
				REFERENCE_RANGE: 'visit.referenceRange',
				RESULT: 'visit.result',
				SEARCH: 'visit.searchTestOrPanel',
				SEARCHING: 'common.searching',
				STATUS: 'common.status',
				TEST: 'visit.test',
			},
			FIRST_VISIT: 'visit.firstVisit',
			NON_CODED_DIAGNOSIS_SELECTION_PREFIX: 'visit.prefix_nonCoded',
			NON_CODED_DIAGNOSIS_SELECTION_SUFFIX: 'visit.suffix_nonCoded',
			PROCESS_STAGE: 'visit.processStage',
			VITALS: 'visit.vitals',
			patient: {
				CHIEF_COMPLAINT: 'patient.chiefComplaint',
				COMMENTS: 'patient.comments',
				DELETE_DIAGNOSIS: 'visit.deleteDiagnosis',
				DETAILS: 'visit.viewPatient',
				DIAGNOSIS: 'patient.diagnosis',
				LABEL: 'common.patient',
				NAME_HELP: 'patient.typeName',
				NAME_REQUIRED: 'patient.nameRequired',
				NOT_FOUND: 'patient.notFound',
				LAB_OR_IMAGING_NOTES: 'patient.labNotes',
				PRIMARY: 'common.primary',
				PRIMARY_DIAGNOSIS: 'patient.primaryDiagnosis',
				REFERRAL: 'patient.referral',
				REFERRED_FROM_TO: 'patient.referredFromTo',
				SEARCH: 'patient.search',
				SEARCH_CODED_DIAGNOSIS: 'codedDiagnosis.search',
				SEARCHING: 'common.searching',
				SEND_PATIENT_TO: 'visit.sendPatientTo',
				SEND_THE_PATIENT_TO: 'visit.sendThePatientTo',
				SENT_TO: 'visit.patientSentTo',
				TYPE: 'patient.type',
				VIEW_PATIENT: 'patient.view',
			},
			payment: {
				LABEL: 'common.payment_plural',
				LOADING: 'common.loading',
				table: {
					AMOUNT_PAID: 'payment.amountPaid',
					DESCRIPTION: 'common.description',
					TYPE: 'payment.type',
				},
			},
			product: {
				DELETE: 'common.delete',
				LABEL: 'visit.productsAndServices',
				table: {
					EXISTING_QUANTITY: 'product.existingQuantity',
					EXPIRY: 'product.expiry',
					INSTRUCTIONS: 'visit.instructions',
					PRODUCT_OR_SERVICE: 'common.productOrService',
					QUANTITY: 'product.quantity',
					TOTAL: 'common.total',
					UNIT_BUY_PRICE: 'product.unitBuyPrice',
					UNIT_SELL_PRICE: 'product.unitSellPrice',
					VISIT_TOTAL: 'visit.productsAndServicesTotal',
				},
				TOTAL: 'common.total',
			},
			TIME: 'common.time',
		},
		GENERATING_RECEIPT: 'visit.generatingReceipt',
		HISTORY: 'visit.viewPatientHistory',
		info: {
			AGE: 'visit.ageWithData',
			LAST_VISIT: 'visit.lastVisitDate',
			LAST_VISIT_WITH_DATA: 'visit.lastVisitDate_data',
			NAME: 'common.nameWithData',
			OPEN_BALANCE: 'patient.openBalance_colon',
			OPEN_BALANCE_WITH_DATA: 'patient.openBalance_data',
			PATIENT_NUMBER_WITH_DATA: 'patient.patientNumberWithData',
			PATIENT_NUMBER_ON_RIBBON: 'patient.patientNumberAbbreviated',
			TOTAL_VISITS: 'visit.totalVisits',
			TOTAL_VISITS_WITH_DATA: 'visit.totalVisits_data',
		},
		insurance: {
			ENTER_CLAIM_NUMBER: 'insurance.enterClaimNumber',
			ENTER_MEMBER_ID: 'insurance.enterMemberID',
			MEMBER_NAME: 'insurance.nhif.memberNameNoNhif',
			nhif: {
				ENTER_NUMBER: 'insurance.memberId',
				SELECT_TYPE: 'insurance.nhif.selectType',
				TYPE: 'insurance.nhif.type',
			},
		},
		LOADING_MORE_DETAILS: 'visit.loadingMoreDetails',
		MEDICAL_RECORDS: 'visit.medicalRecordsFromPreviousVisits',
		NEGATIVE_INVENTORY_DISALLOWED: 'visit.negativeInventoryDisallowed',
		NEW: 'visit.new',
		NUMBER: 'visit.number',
		NUMBER_DATA: 'visit.number_data',
		PATIENT_NAME: 'visit.patientName',
		PATIENT_TYPE: 'visit.patientType',
		PROCESSING: 'visit.processing',
		product: {
			expiration: {
				AUTO: 'visit.auto',
			},
			NOT_FOUND: 'product.notFound',
			SEARCH: 'visit.searchProductOrService',
			SEARCHING: 'common.searching',
		},
		prompt: {
			COMPLETE_CONFIRM_MESSAGE: 'visit.confirmBeforeComplete',
			COMPLETE_WITHOUT_PAYMENT: 'visit.completeWithoutPaymentPrompt',
			PRODUCT_LINE_QUANTITY_EXCEEDS_INVENTORY: 'visit.soldQuantityHigherThanAvailable',
		},
		REFERRAL: 'visit.referral',
		SAVED: 'visit.saved',
		SELLING_MORE_OF_PRODUCT_THAN_AVAILABLE_QUANTITY: 'visit.sellingMoreOfProductThanAvailableQuantity',
		STATUS: 'common.status',
		TIME: 'visit.time',
		TOTAL_NUMBER_OF_VISITS: 'visit.totalNumberOfVisits',
		UPDATE: 'visit.update',
		LIST: 'visit.list',
		OPEN_DRAFTS_MESSAGE: 'visit.openDrafts',
		filter: {
			TODAY: 'visit.today',
			YESTERDAY: 'visit.yesterday',
			LAST_7_DAYS: 'visit.last7Days',
			LAST_30_DAYS: 'visit.last30Days',
			OPD: 'visit.outpatient',
			IPD: 'visit.inpatient',
		},
		vitals: {
			title: {
				EDIT: 'visit.editVitalsMeasurements',
				NEW: 'visit.addVitalsMeasurements',
			},
			error: {
				MEASURE_TIME_REQUIRED: 'visit.vitalMeasurementTimeRequired',
			},
			label: {
				MEASUREMENT_TIME_LABEL: 'visit.vitalsTime',
			},
		},
	},
	voidedReason: {
		VOIDED_REASON_TYPE: 'common.voidedReasonType',
	},
	warehouse: {
		NO_DEFAULT_WAREHOUSE: 'warehouse.noDefaultWarehouse',
		NO_LOCATORS_CONFIGURED: 'warehouse.noLocatorsForWarehouse',
	},
};

type Screens<T, S> = {
	[P in keyof T]: T[P] & S;
};

/**
 * The strings shown on the UI. Each page/entity should have a copy of the common labels (a copy so that, if a label was
 * accidentally updated somewhere, it would only affect that place and not every place). This base object is defined as the
 * argument to this IIFE.
 */
export const uiText: Screens<typeof baseLanguage, typeof common> = ((pageText) => {
	// This goes through and adds the common object above to each page property if no property is already defined
	Object.keys(pageText).forEach((key) => {
		defaultsDeep(pageText[key as keyof typeof baseLanguage] as any, common);
	});
	return pageText as Screens<typeof baseLanguage, typeof common>;
})(baseLanguage);

export const textNeededBeforeTranslation = {
	LOGO_ALT: 'BandaHealth Logo',
};
