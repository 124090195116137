export const getErrorMessage = (
	error: { response?: { data?: { message?: string } | string }; message?: string } | string = '',
): string => {
	// check if the error object is a string and return
	if (typeof error === 'string') {
		return error;
	}

	// parse the object
	if (error.response) {
		if (error.response?.data) {
			// check the data object
			if (typeof error.response.data === 'string') {
				return error.response.data.trim() || error.response.data || '';
			} else {
				return error.response.data.message?.split(': ')[1] || '';
			}
		}

		if (error?.message) {
			return error.message || '';
		}
	}

	return '';
};
