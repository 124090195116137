import { useApolloClient } from '@apollo/client';
import { endOfDay } from 'date-fns';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { Ad_FieldForObservationsFragmentDoc } from '../../graphql/__generated__/graphql';
import { fieldUuid, referenceUuids } from '../../models';
import { formatDate } from '../../utils/DateUtil';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import PrintableTextArea from '../PrintableTextArea/PrintableTextArea';
import { ObservationTableFormValues } from './ObservationTable';

export default function ObservationDisplay({
	name,
	observation,
}: {
	name: string;
	observation: ObservationTableFormValues['vitalsEncounters'][0]['BH_Observations'][0];
}) {
	const graphqlClient = useApolloClient();
	const field = graphqlClient.readFragment({
		id: observation.AD_Field.UU,
		fragment: Ad_FieldForObservationsFragmentDoc,
	});
	const { register } = useFormContext<ObservationTableFormValues>();
	return (
		<fieldset disabled={observation.AD_Field.UU === fieldUuid.BMI} className="w-100">
			{field?.AD_Column.AD_Reference.UU === referenceUuids.STRING ? (
				<Form.Control {...register(name as 'vitalsEncounters.0.BH_Observations.0.BH_Value')} />
			) : field?.AD_Column.AD_Reference.UU === referenceUuids.INTEGER ||
			  field?.AD_Column.AD_Reference.UU === referenceUuids.AMOUNT ? (
				<Form.Control
					step="any"
					min="0"
					type="number"
					{...register(name as 'vitalsEncounters.0.BH_Observations.0.BH_Value')}
				/>
			) : field?.AD_Column.AD_Reference.UU === referenceUuids.TEXT_LONG ? (
				<Controller<ObservationTableFormValues, 'vitalsEncounters.0.BH_Observations.0.BH_Value'>
					name={name as 'vitalsEncounters.0.BH_Observations.0.BH_Value'}
					render={({ field }) => <PrintableTextArea rows={6} {...field} value={field.value || ''} />}
				/>
			) : field?.AD_Column.AD_Reference.UU === referenceUuids.DATE ? (
				<Controller<ObservationTableFormValues, 'vitalsEncounters.0.BH_Observations.0.BH_Value'>
					name={name as 'vitalsEncounters.0.BH_Observations.0.BH_Value'}
					render={({ field }) => (
						<BandaDatePicker
							maxDate={endOfDay(new Date())}
							{...field}
							value={(field.value && formatDate(new Date(parseInt(field.value, 10)))) || undefined}
							onChange={(selectedDate) => {
								let parsedDate: string | null = null;
								if (selectedDate) {
									parsedDate = selectedDate.getTime().toString();
								}
								field.onChange(parsedDate);
							}}
							selected={(field.value && new Date(parseInt(field.value, 10))) || null}
						/>
					)}
				/>
			) : null}
		</fieldset>
	);
}
