import { useQuery } from '@apollo/client';
import { differenceInDays } from 'date-fns';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/UserContext';
import {
	M_StorageOnHandForInventoryListQuery,
	M_WarehouseForInventoryListDocument,
} from '../../graphql/__generated__/graphql';
import { formatDate } from '../../utils/DateUtil';
import { getWarehouseByOrg } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import { BHGraphQLTableProps } from '../BHTable/BHGraphQLTable';
import InventoryList, { InventoryListRef } from '../inventory-list/InventoryList';
import Layout from '../Layout/Layout';

const StockTake = () => {
	const { t } = useTranslation();
	const { organization } = useContext(UserContext);

	const { data: warehouses } = useQuery(M_WarehouseForInventoryListDocument, {
		variables: { Filter: getWarehouseByOrg(organization.uuid).toString() },
		fetchPolicy: 'network-only',
	});
	const columns = useMemo(() => {
		type ColumnType = BHGraphQLTableProps<
			M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0]
		>['columns'][0];
		let columns: ColumnType[] = [
			{
				id: 'm_product.name',
				Header: () => <div className={'React-table-header'}>{t(uiText.product.name.byOthers.LABEL)}</div>,
				accessor: (d) => d.M_Product.Name,
			},
			{
				id: 'qtyonhand',
				Header: () => <div className={'React-table-header'}>{t(uiText.product.quantity.LABEL)}</div>,
				accessor: (d) => d.QtyOnHand,
			},
		];
		if (warehouses?.M_WarehouseGet.Results.length! > 1) {
			columns.push({
				id: 'm_locator.m_warehouse.name',
				disableSortBy: true,
				Header: () => <div className={'React-table-header'}>{t(uiText.inventory.STOREROOM)}</div>,
				accessor: (d) => d.M_Locator?.M_Warehouse.Name,
			} as ColumnType);
		}
		columns = [
			...columns,
			...[
				{
					id: 'm_attributesetinstance.guaranteedate',
					Header: () => (
						<div className={'React-table-header'}>{t(uiText.product.expirationDate.LABEL_ABBREVIATION)}</div>
					),
					accessor: (d) => (
						<div className="d-flex justify-content-center">
							{!d.M_AttributeSetInstance.GuaranteeDate
								? ''
								: formatDate(new Date(d.M_AttributeSetInstance.GuaranteeDate))}
						</div>
					),
				} as ColumnType,
				{
					id: 'shelflife',
					// disableSortBy: true,
					Header: () => (
						<div className={'React-table-header'}>{t(uiText.product.expirationDate.DAYS_TO_EXPIRATION)}</div>
					),
					accessor: (d) => (
						<div className="d-flex justify-content-center">
							{!d.M_AttributeSetInstance.GuaranteeDate
								? ''
								: differenceInDays(d.M_AttributeSetInstance.GuaranteeDate, new Date())}
						</div>
					),
				} as ColumnType,
				{
					id: 'purchaseDate',
					disableSortBy: true,
					Header: () => <div className={'React-table-header'}>{t(uiText.product.RECEIVED_ON)}</div>,
					accessor: (d) =>
						(d.M_AttributeSetInstance.PurchaseDate && formatDate(new Date(d.M_AttributeSetInstance.PurchaseDate))) ||
						'',
				} as ColumnType,
				{
					id: 'price',
					disableSortBy: true,
					Header: () => <div className={'React-table-header'}>{t(uiText.product.BUY_PRICE)}</div>,
					accessor: (d) => formatNumber(d.M_AttributeSetInstance.PurchasePrice),
				} as ColumnType,
			],
		];
		return columns;
	}, [t, warehouses]);

	const ref = useRef<InventoryListRef>({ areRefreshing: false, refresh() {} });

	return (
		<Layout>
			<Layout.Header>
				<Layout.Title
					title={t(uiText.inventory.title.LIST)}
					showRefreshIcon
					onRefresh={() => ref.current.refresh()}
					areRefreshing={ref.current.areRefreshing || false}
				/>
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<InventoryList columns={columns} showSearch={true} showCleanUpButton={true} ref={ref} />
			</Layout.Body>
		</Layout>
	);
};

export default StockTake;
