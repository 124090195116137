import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Report, { ReportDto } from './Report';
import { TreeNodeMMDB } from './TreeNodeMM';
import Window, { WindowDto } from './Window';

export const menuUuid = {
	REPORTS_DROPDOWN: '35ce7d6a-cf7d-4962-a748-75e27d0121bf',
	GREENLIGHT_MAIN_MENU: 'bb0670c5-0dc1-468a-8b85-a91b15407368',
} as const;

export interface MenuDB extends BaseEntityDB {
	ad_menu_uu: string;
	'ad_treenodemm::ad_menu_id->node_id': TreeNodeMMDB;
}

export interface MenuDto extends BaseEntityDto {
	iconClassName: string | null;
	window: WindowDto | null;
	process: ReportDto | null;
	subMenus: MenuDto[] | null;
	sequenceNumber: number | null;
}

export const getMenuClassName = (menu?: { IconClassName?: string | null } | null): IconName | undefined => {
	return (menu?.IconClassName?.split(' ')[1].replace(/fa-/g, '') as IconName) || undefined;
};

export const getMenuBrand = (menu?: { IconClassName?: string | null } | null): IconPrefix => {
	return menu?.IconClassName?.split(' ')[0].replace('r', '') as IconPrefix;
};

export default class Menu extends BaseEntity {
	iconClassName: string;
	window?: Window;
	process?: Report;
	subMenus: Menu[];
	sequenceNumber: number;

	constructor(props?: Partial<Menu | MenuDto>) {
		props = cloneDeep(props || {});
		super(props);
		this.sequenceNumber = props.sequenceNumber || 0;
		this.iconClassName = props.iconClassName || '';
		this.window = props.window ? new Window(props.window) : undefined;
		this.process = props.process ? new Report(props.process) : undefined;
		this.subMenus = (props.subMenus || []).map((menu) => new Menu(menu));
	}
}
