import { useApolloClient } from '@apollo/client';
import { isEqual, sortBy } from 'lodash';
import { Fragment, useContext, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import UserContext from '../../contexts/UserContext';
import {
	Ad_Ref_ListBusinessPartnerNeedsDocument,
	Ad_RoleDisplayForUserFormFragmentDoc,
	Ad_RoleLookupForUsersDocument,
	Ad_RoleMasterRolesDocument,
	Ad_RoleWithIncludedRolesSaveDocument,
	Ad_UserForCheckingDuplicatesDocument,
	Ad_UserForEditingDocument,
	Ad_UserForEditingQuery,
	Ad_UserSaveOnListOrFormDocument,
	Ad_User_RolesDeleteForUsersDocument,
	Ad_User_RolesSaveForUsersDocument,
	C_BPartnerForManageUsersSaveDocument,
	C_LocationForOrganizationDocument,
	C_LocationInput,
	C_PaymentTermForBusinessPartnersDocument,
	LoginCheckOtherClientsDocument,
	M_PriceListsForBusinessPartnersDocument,
} from '../../graphql/__generated__/graphql';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	BaseEntityDB,
	DBFilter,
	LocationDB,
	Paging,
	PriceListDB,
	ReferenceListDB,
	referenceUuids,
	RoleDB,
	roleUuid,
	UserDB,
} from '../../models';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import PasswordInput from '../password-input/PasswordInput';

type ManageUserFormProps = EntityFormProperties;

type ManageUserFormFormValues = {
	UU: string;
	AD_RolesToInclude: Array<{ UU: string }>;
	IsActive: boolean;
	Name: string;
	Password: string | null;
};
const getTitle = (uuid?: string) => uiText.manageUsers.title.LIST;
const convertToFormFields: (initialData?: Ad_UserForEditingQuery['AD_User']) => ManageUserFormFormValues = (
	initialData,
) => {
	if (!initialData) {
		return { UU: v4(), AD_RolesToInclude: [], IsActive: true, Name: '', Password: null };
	}
	return {
		UU: initialData.UU,
		AD_RolesToInclude: [
			...(new Set(
				initialData?.AD_User_Roles?.flatMap((userRole) => userRole.AD_Role.AD_Role_IncludedList)
					.filter(
						(includedRole) => includedRole?.Included_Role.UU && includedRole?.Included_Role.UU !== roleUuid.MUST_HAVES,
					)
					.map((includedRole) => ({ UU: includedRole?.Included_Role.UU })) as Array<{ UU: string }>,
			) || []),
		].sort(),
		IsActive: initialData.IsActive,
		Name: initialData.Name,
		Password: null,
	};
};

const ManageUserForm = ({ uuid, onFinish, renderAsModal }: ManageUserFormProps) => {
	const graphqlClient = useApolloClient();
	const { client, organization } = useContext(UserContext);
	const {
		data: [
			initialData,
			masterRoles,
			locationForOrganization,
			businessPartnerReferenceLists,
			paymentTerms,
			priceLists,
		] = [],
	} = useSuspenseAsync(uuid || 'add-user', async () =>
		Promise.all([
			uuid ? graphqlClient.query({ query: Ad_UserForEditingDocument, variables: { UU: uuid } }) : undefined,
			graphqlClient.query({
				query: Ad_RoleMasterRolesDocument,
				variables: {
					Page: Paging.ALL.page,
					Size: Paging.ALL.size,
					Sort: JSON.stringify([['name', 'asc']]),
					Filter: DBFilter<RoleDB>().property('isMasterRole').equals(true).toString(),
				},
			}),
			graphqlClient.query({
				query: C_LocationForOrganizationDocument,
				variables: {
					Filter: DBFilter<LocationDB>()
						.nested('ad_orginfo')
						.nested('ad_org')
						.property('ad_org_uu')
						.equals(organization.uuid)
						.up()
						.up()
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: Ad_Ref_ListBusinessPartnerNeedsDocument,
				variables: {
					Filter: DBFilter<ReferenceListDB>()
						.nested('ad_reference')
						.property('ad_reference_uu')
						.isIn([
							referenceUuids.BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS,
							referenceUuids.ORDER_INVOICE_RULES,
							referenceUuids.PAYMENT_TYPES,
							referenceUuids.USER_NOTIFICATION_TYPES,
						])
						.up()
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: C_PaymentTermForBusinessPartnersDocument,
				variables: { Size: 1, Filter: DBFilter<BaseEntityDB>().property('name').equals('Immediate').toString() },
				fetchPolicy: 'cache-first',
			}),
			graphqlClient.query({
				query: M_PriceListsForBusinessPartnersDocument,
				variables: {
					Sort: JSON.stringify([['created', 'desc']]),
					Filter: DBFilter<PriceListDB>()
						.property('isdefault')
						.equals(true)
						.property('isactive')
						.equals(true)
						.toString(),
				},
				fetchPolicy: 'cache-first',
			}),
		]),
	);

	const [data] = useState(initialData?.data.AD_User);
	const title = getTitle(data?.UU);

	const { t } = useTranslation();
	const formMethods = useForm<ManageUserFormFormValues>({
		defaultValues: convertToFormFields(data),
	});

	const onHandleSubmit: SubmitHandler<ManageUserFormFormValues> = async (formData) => {
		try {
			// Check if a user with the same username AND same password exists in a
			// different client. If so, raise an error
			if (formData.Password) {
				const checkOtherClients = await graphqlClient.mutate({
					mutation: LoginCheckOtherClientsDocument,
					variables: { Credentials: { Username: formData.Name, Password: formData.Password, AD_Language: '' } },
				});
				if (checkOtherClients.data?.LoginCheckOtherClients) {
					toast.error(t(uiText.manageUsers.error.ERROR_WITH_PASSWORD));
					return;
				}
			}

			// If this is a new user, we have some work to do
			if (!data) {
				// Check if a user with this name already exists in this client
				const duplicateUser = (
					await graphqlClient.query({
						query: Ad_UserForCheckingDuplicatesDocument,
						variables: {
							Page: Paging.ALL.page,
							Size: Paging.ALL.size,
							Filter: DBFilter<UserDB>().property('name').equals(formData.Name).toString(),
						},
					})
				).data.AD_UserGet.Results[0];
				if (duplicateUser) {
					toast.error(t(uiText.manageUsers.error.USER_ALREADY_EXISTS));
					return;
				}
				let immediateOrderInvoiceRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
					(referenceList) =>
						referenceList.AD_Reference.UU === referenceUuids.ORDER_INVOICE_RULES && referenceList.Value === 'I', // Immediate
				);
				let directDepositPaymentRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
					(referenceList) =>
						referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'T', // Direct Deposit
				);
				let onCreditPaymentRule = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
					(referenceList) =>
						referenceList.AD_Reference.UU === referenceUuids.PAYMENT_TYPES && referenceList.Value === 'P', // On Credit
				);
				let salesOrderNoCreditCheck = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
					(referenceList) =>
						referenceList.AD_Reference.UU === referenceUuids.BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS &&
						referenceList.Value === 'X', // No Credit Check
				);
				let noNotification = businessPartnerReferenceLists?.data.AD_Ref_ListGet.Results.find(
					(referenceList) =>
						referenceList.AD_Reference.UU === referenceUuids.USER_NOTIFICATION_TYPES && referenceList.Value === 'X', // No Notification
				);
				let purchasePriceListUU = priceLists?.data.M_PriceListGet.Results.find(
					(priceList) => !priceList.IsSOPriceList,
				)?.UU;
				let salesPriceListUU = priceLists?.data.M_PriceListGet.Results.find((priceList) => priceList.IsSOPriceList)?.UU;

				let locationUU = locationForOrganization?.data.C_LocationGet.Results[0]?.UU;
				let location: C_LocationInput = locationUU ? { UU: locationUU } : { UU: v4() };
				// If we don't have a location UUID by now, we need to get or create one
				if (!locationUU) {
					let locationFilter = DBFilter<LocationDB>();
					let organizationLocation = locationForOrganization?.data.C_LocationGet.Results[0];
					if (organizationLocation?.C_Country.UU) {
						locationFilter = locationFilter
							.nested('c_country')
							.property('c_country_uu')
							.equals(organizationLocation.C_Country.UU)
							.up();
					}
					if (organizationLocation?.C_Region?.UU) {
						locationFilter = locationFilter
							.nested('c_region')
							.property('c_region_uu')
							.equals(organizationLocation.C_Region.UU)
							.up();
					}
					let searchedLocationUU = (
						await graphqlClient.query({
							query: C_LocationForOrganizationDocument,
							variables: { Size: 1, Filter: locationFilter.toString() },
						})
					).data.C_LocationGet.Results[0]?.UU;
					// If we found something, use it
					if (searchedLocationUU) {
						location.UU = searchedLocationUU;
					} else {
						// Otherwise, make our own
						location.C_Country = organizationLocation?.C_Country.UU
							? { UU: organizationLocation.C_Country.UU }
							: undefined;
						location.C_Region = organizationLocation?.C_Region?.UU
							? { UU: organizationLocation.C_Region.UU }
							: undefined;
					}
				}

				const businessPartnerUU = v4();
				await graphqlClient.mutate({
					mutation: C_BPartnerForManageUsersSaveDocument,
					variables: {
						C_BPartner: {
							UU: businessPartnerUU,
							BH_NeedAdditionalVisitInfo: false,
							C_PaymentTerm: paymentTerms?.data.C_PaymentTermGet.Results[0]?.UU
								? { UU: paymentTerms.data.C_PaymentTermGet.Results[0].UU }
								: undefined,
							InvoiceRule: immediateOrderInvoiceRule ? { UU: immediateOrderInvoiceRule.UU } : undefined,
							IsActive: formData.IsActive,
							IsCustomer: false,
							IsVendor: false,
							M_PriceList: salesPriceListUU ? { UU: salesPriceListUU } : undefined,
							Name: formData.Name,
							PaymentRule: onCreditPaymentRule ? { UU: onCreditPaymentRule.UU } : undefined,
							PaymentRulePO: directDepositPaymentRule ? { UU: directDepositPaymentRule.UU } : undefined,
							PO_PaymentTerm: paymentTerms?.data.C_PaymentTermGet.Results[0]?.UU
								? { UU: paymentTerms.data.C_PaymentTermGet.Results[0].UU }
								: undefined,
							PO_PriceList: purchasePriceListUU ? { UU: purchasePriceListUU } : undefined,
							SOCreditStatus: salesOrderNoCreditCheck ? { UU: salesOrderNoCreditCheck.UU } : undefined,
						},
						C_Location: location,
						C_BPartner_Location: {
							C_BPartner: { UU: businessPartnerUU },
							C_Location: { UU: location.UU! },
							Name: formData.Name + ' Location',
						},
						AD_User: {
							UU: formData.UU,
							C_BPartner: { UU: businessPartnerUU },
							IsExpired: true,
							IsFullBPAccess: false,
							IsActive: formData.IsActive,
							Name: formData.Name,
							NotificationType: noNotification?.UU ? { UU: noNotification.UU } : undefined,
							Password: formData.Password,
						},
					},
				});
			} else {
				// Just save the isactive flag - role stuff is below
				await graphqlClient.mutate({
					mutation: Ad_UserSaveOnListOrFormDocument,
					variables: { AD_User: { UU: formData.UU, IsActive: formData.IsActive } },
				});
			}

			// If we had a change in role selection, we'll do some checks
			if (
				!data ||
				(data.AD_User_Roles?.length &&
					!isEqual(
						(
							data.AD_User_Roles?.flatMap((userRole) =>
								userRole.AD_Role.AD_Role_IncludedList?.map((includedRole) => includedRole.Included_Role.UU),
							) || []
						).sort(),
						formData.AD_RolesToInclude.map((roleToInclude) => roleToInclude.UU).sort(),
					))
			) {
				// Find (or create if it doesn't yet exist) a role that includes all the selected master roles
				const selectedRoleUUsToInclude = formData.AD_RolesToInclude.map((roleToInclude) => roleToInclude.UU);
				const selectedMasterRoles = masterRoles!.data.AD_RoleGet.Results.filter((role) =>
					selectedRoleUUsToInclude.includes(role.UU),
				).sort();
				const filter = DBFilter<RoleDB>()
					.and(
						DBFilter<RoleDB>()
							.nested('ad_role_included.ad_role::included_role_id')
							.property('ad_role_uu')
							.equals(roleUuid.MUST_HAVES)
							.up(),
					)
					.not(
						DBFilter<RoleDB>()
							.nested('ad_role_included.ad_role::included_role_id')
							.property('ad_role_uu')
							.isIn(
								masterRoles!.data.AD_RoleGet.Results.filter(
									(role) => roleUuid.MUST_HAVES !== role.UU && !selectedRoleUUsToInclude.includes(role.UU),
								).map((role) => role.UU),
							)
							.up(),
					);
				selectedMasterRoles.forEach((masterRole) =>
					filter.and(
						DBFilter<RoleDB>()
							.nested('ad_role_included.ad_role::included_role_id')
							.property('ad_role_uu')
							.equals(masterRole.UU)
							.up(),
					),
				);
				// Get a role that has only these included roles
				let roleToUse = (
					await graphqlClient.query({
						query: Ad_RoleLookupForUsersDocument,
						variables: { Page: Paging.ALL.page, Size: Paging.ALL.size, Filter: filter.toString() },
					})
				).data.AD_RoleGet.Results[0];
				let isTheRoleBeingChanged = !roleToUse || roleToUse.UU !== data?.AD_User_Roles?.[0].AD_Role.UU;
				if (!roleToUse) {
					const roleUU = v4();
					let sequenceNumber = 10;
					roleToUse = (
						await graphqlClient.mutate({
							mutation: Ad_RoleWithIncludedRolesSaveDocument,
							variables: {
								AD_Role: {
									UU: roleUU,
									IsMasterRole: false,
									Name: client.name + ' ' + selectedMasterRoles.map((role) => role.Name).join(', '),
									IsActive: true,
								},
								AD_Role_IncludedList: [
									{
										AD_Role: { UU: roleUU },
										Included_Role: { UU: roleUuid.MUST_HAVES },
										SeqNo: 10,
									},
									...selectedMasterRoles.map((masterRole) => ({
										AD_Role: { UU: roleUU },
										Included_Role: { UU: masterRole.UU },
										SeqNo: (sequenceNumber += 10),
									})),
								],
							},
						})
					).data?.AD_RoleSave!;
				}

				// Finally, make sure the user has access to the role
				if (isTheRoleBeingChanged) {
					await Promise.all([
						graphqlClient.mutate({
							mutation: Ad_User_RolesSaveForUsersDocument,
							variables: {
								AD_User_Role: {
									AD_User: { UU: formData.UU },
									AD_Role: { UU: roleToUse.UU },
								},
							},
						}),
						// Since AD_User_Roles can't be updated, delete any existing one that may be there
						data?.AD_User_Roles?.[0].UU
							? graphqlClient.mutate({
									mutation: Ad_User_RolesDeleteForUsersDocument,
									variables: { UUs: [data.AD_User_Roles[0].UU] },
								})
							: undefined,
					]);
				}
			}
			toast.success(t(uiText.manageUsers.toast.UPDATE_SUCCESS));
			onFinish(true, formData.UU);
		} catch (error) {
			exception({ description: `User save error: ${error}` });
			toast.error(t(uiText.manageUsers.error.COULD_NOT_SAVE, { error }));
		}
	};

	useConfirmRefresh(formMethods.formState?.isDirty);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(onHandleSubmit)}>
				<input type="hidden" {...formMethods.register('UU')} />
				<Card className="bh-card">
					<Card.Body>
						<Row className="gy-3">
							<Form.Group as={Fragment} controlId="name">
								<Col xs={2} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.manageUsers.LABELS.USERNAME)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<Form.Control
										aria-label={t(uiText.manageUsers.LABELS.USERNAME)}
										type="text"
										autoComplete="off"
										disabled={!!data}
										placeholder={t(uiText.manageUsers.LABELS.ENTER_USERNAME)}
										{...formMethods.register('Name', { required: true })}
									/>
									{formMethods.formState?.errors?.Name && (
										<span className="text-danger">{t(uiText.manageUsers.validationMessages.REQUIRE_USERNAME)}</span>
									)}
								</Col>
							</Form.Group>
							<Col xs={2} />

							{!data ? (
								/* If this IS a new user, show a textbox where an initial password can be set */
								<Form.Group as={Fragment} controlId="Password">
									<Col xs={2} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.manageUsers.ONE_TIME_PASSWORD)}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<PasswordInput
											autoComplete="new-password"
											placeholder={t(uiText.manageUsers.ENTER_PASSWORD)}
											{...formMethods.register('Password', { required: true })}
										/>
										{formMethods.formState?.errors?.Password && (
											<span className="text-danger">
												{t(uiText.manageUsers.validationMessages.REQUIRE_ONE_TIME_PASSWORD)}
											</span>
										)}
									</Col>
								</Form.Group>
							) : (
								/* If this is NOT a new user, show a read-only field with the last login date */
								<Form.Group as={Fragment}>
									<Col xs={2} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.manageUsers.LABELS.LAST_LOGIN)}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<Form.Control
											aria-label={t(uiText.manageUsers.LABELS.LAST_LOGIN)}
											type="text"
											defaultValue={data?.DateLastLogin ? formatDateAndTime(new Date(data.DateLastLogin)) : ''}
											disabled={true}
										/>
									</Col>
								</Form.Group>
							)}
							<Col xs={2} />

							<Form.Group as={Fragment} controlId={'rolesToInclude'}>
								<Col xs={2} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.manageUsers.LABELS.SELECT_ROLE)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Controller<ManageUserFormFormValues, 'AD_RolesToInclude'>
										name={'AD_RolesToInclude'}
										rules={{ required: true }}
										render={({ field }) => (
											<Typeahead
												{...field}
												id="rolesToIncludeTypeahead"
												multiple
												className="w-100"
												placeholder={t(uiText.manageUsers.LABELS.SELECT_ROLE)}
												options={sortBy(
													masterRoles?.data.AD_RoleGet.Results.filter((role) => role.UU !== roleUuid.MUST_HAVES).map(
														(role) => ({ UU: role.UU }),
													) || [],
													'Name',
												)}
												labelKey={(selected) =>
													graphqlClient.readFragment({
														id: selected.UU,
														fragment: Ad_RoleDisplayForUserFormFragmentDoc,
													})?.Name || ''
												}
												selected={field.value}
												inputProps={{ 'aria-label': t(uiText.manageUsers.LABELS.SELECT_ROLE) }}
											/>
										)}
									/>
									{formMethods.formState?.errors?.AD_RolesToInclude && (
										<span className="text-danger">{t(uiText.manageUsers.validationMessages.REQUIRE_ROLE)}</span>
									)}
								</Col>
							</Form.Group>
							<Col xs={5} />

							<Form.Group as={Fragment} controlId="IsActive">
								<Col xs={2} className="d-flex align-items-center">
									<Form.Label column></Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<Form.Check label={t(uiText.manageUsers.LABELS.ACTIVE)} {...formMethods.register('IsActive')} />
								</Col>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
			<Col xs="auto" className="me-auto">
				<Button type="button" name="cancel" variant="danger" onClick={() => onFinish(true)}>
					{t(uiText.manageUsers.button.CANCEL)}
				</Button>
			</Col>
			<Col xs="auto">
				<Button
					type="submit"
					name="confirm"
					variant="success"
					onClick={() => formMethods.handleSubmit(onHandleSubmit)()}
				>
					{t(uiText.manageUsers.button.SAVE)}
				</Button>
			</Col>
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="bg-white pb-0_5 ps-2_5">
					{inputs}
					{buttons}
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<ManageUserFormProps>({
	loadingLabel: uiText.manageUsers.LOADING,
	getTitle,
})(ManageUserForm);
