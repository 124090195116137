import { useApolloClient } from '@apollo/client';
import { Fragment, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';
import { v4 } from 'uuid';
import { fieldUuid, referenceUuids } from '../../models';
import ObservationDisplay from './ObservationDisplay';
import { ObservationTableFormValues } from './ObservationTable';
import { getObservationFields, isFieldGroup } from './VisitUtil';

type FieldComponentProps = {
	visitUuid: string;
	fieldPrefix?: string;
	displayCondensed?: boolean;
};

const ObservationFormGroup = ({ visitUuid, fieldPrefix, displayCondensed }: FieldComponentProps) => {
	const graphqlClient = useApolloClient();
	const { getValues, register, setValue } = useFormContext<ObservationTableFormValues>();
	const observationFieldsPrefix = `${fieldPrefix ? fieldPrefix + '.' : ''}BH_Observations`;
	const observations = getValues(observationFieldsPrefix as 'vitalsEncounters.0.BH_Observations');
	const controlIdPrefix = useMemo(() => fieldPrefix || v4(), [fieldPrefix]);
	const condensionFactor = displayCondensed ? 2 : 1;

	const observationDisplayList = getObservationFields(graphqlClient, observations);

	// Create a list of the form names
	const formFieldNamesForBmiCalculation = useMemo(() => {
		const bmiIndex = observationDisplayList
			.flatMap((observationDisplay) =>
				isFieldGroup(observationDisplay) ? observationDisplay.observations : observationDisplay,
			)
			.find((observationDisplay) => observationDisplay.field.UU === fieldUuid.BMI)?.index;
		const heightIndex = observationDisplayList
			.flatMap((observationDisplay) =>
				isFieldGroup(observationDisplay) ? observationDisplay.observations : observationDisplay,
			)
			.find((observationDisplay) => observationDisplay.field.UU === fieldUuid.HEIGHT)?.index;
		const weightIndex = observationDisplayList
			.flatMap((observationDisplay) =>
				isFieldGroup(observationDisplay) ? observationDisplay.observations : observationDisplay,
			)
			.find((observationDisplay) => observationDisplay.field.UU === fieldUuid.WEIGHT)?.index;
		return {
			bmi: bmiIndex !== undefined ? `${observationFieldsPrefix}.${bmiIndex}.BH_Value` : '',
			height: heightIndex !== undefined ? `${observationFieldsPrefix}.${heightIndex}.BH_Value` : '',
			weight: weightIndex !== undefined ? `${observationFieldsPrefix}.${weightIndex}.BH_Value` : '',
		};
	}, [observationDisplayList, observationFieldsPrefix]);
	// For calculated observations, get a list of fields that we need to watch
	const heightAndWeightWatchers = useWatch({
		name: [formFieldNamesForBmiCalculation.height, formFieldNamesForBmiCalculation.weight],
	}) as string[];
	// Any time any of these form values change, any that are calculated should also update
	useDeepCompareEffect(() => {
		const height = parseFloat(
			getValues(formFieldNamesForBmiCalculation.height as 'vitalsEncounters.0.BH_Observations.0.BH_Value') || '0',
		);
		const weight = parseFloat(
			getValues(formFieldNamesForBmiCalculation.weight as 'vitalsEncounters.0.BH_Observations.0.BH_Value') || '0',
		);
		if (!isNaN(height) && !isNaN(weight) && height > 0) {
			setValue(
				formFieldNamesForBmiCalculation.bmi as 'vitalsEncounters.0.BH_Observations.0.BH_Value',
				(Math.round((weight * 100) / Math.pow(height / 100, 2)) / 100).toString(),
			);
		} else if (formFieldNamesForBmiCalculation.bmi) {
			setValue(formFieldNamesForBmiCalculation.bmi as 'vitalsEncounters.0.BH_Observations.0.BH_Value', '');
		}
	}, [heightAndWeightWatchers, formFieldNamesForBmiCalculation, setValue]);

	return (
		<>
			{observationDisplayList?.map((observationDisplay) => (
				<Col
					xs={
						!isFieldGroup(observationDisplay) &&
						observationDisplay.field.AD_Column.AD_Reference.UU === referenceUuids.TEXT_LONG
							? 12
							: 4
					}
					key={observationDisplay.index}
				>
					<Row className="h-100">
						<Form.Group as={Fragment} controlId={`${controlIdPrefix}${observationDisplay.index}${visitUuid}`}>
							{/* The first column is the label */}
							<Col
								xs={
									!isFieldGroup(observationDisplay) &&
									observationDisplay.field.AD_Column.AD_Reference.UU === referenceUuids.TEXT_LONG
										? 1 * condensionFactor
										: 3 * condensionFactor
								}
								className={`d-flex ${
									!isFieldGroup(observationDisplay) &&
									observationDisplay.field.AD_Column.AD_Reference.UU !== referenceUuids.TEXT_LONG
										? 'align-items-center'
										: ''
								}`}
							>
								<Form.Label column>
									{isFieldGroup(observationDisplay)
										? observationDisplay.observations[0].field.AD_FieldGroup?.Name
										: observationDisplay.field.Name}
								</Form.Label>
							</Col>

							{/* Value column */}
							<Col
								xs={
									!isFieldGroup(observationDisplay) &&
									observationDisplay.field.AD_Column.AD_Reference.UU === referenceUuids.TEXT_LONG
										? 12 - 1 * condensionFactor
										: 12 - 3 * condensionFactor
								}
								className="d-flex align-items-center"
							>
								{isFieldGroup(observationDisplay) ? (
									<>
										{observationDisplay.observations.map(
											(groupedObservationDisplay, groupedObservationDisplayIndex) => (
												<Fragment key={groupedObservationDisplay.observation.UU}>
													<input
														type="hidden"
														{...register(
															`${observationFieldsPrefix}.${groupedObservationDisplay.index}.AD_Field.UU` as unknown as 'vitalsEncounters.0.BH_Observations.0.AD_Field.UU',
														)}
													/>
													{groupedObservationDisplayIndex > 0 && <span className="mx-1">/</span>}
													<ObservationDisplay
														name={`${observationFieldsPrefix}.${groupedObservationDisplay.index}.BH_Value`}
														observation={groupedObservationDisplay.observation}
													/>
												</Fragment>
											),
										)}
									</>
								) : (
									<>
										<input
											type="hidden"
											{...register(
												`${observationFieldsPrefix}.${observationDisplay.index}.AD_Field.UU` as unknown as 'vitalsEncounters.0.BH_Observations.0.AD_Field.UU',
											)}
										/>
										<ObservationDisplay
											name={`${observationFieldsPrefix}.${observationDisplay.index}.BH_Value`}
											observation={observationDisplay.observation}
										/>
									</>
								)}
							</Col>
						</Form.Group>
					</Row>
				</Col>
			))}
		</>
	);
};

export default ObservationFormGroup;
