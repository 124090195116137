import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import { useContext, useState } from 'react';
import { Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import {
	C_TaxCategoryForProductsDocument,
	C_UomGetDefaultDocument,
	M_PriceList_VersionGetForServicesDocument,
	M_ProductForServiceEditingDocument,
	M_ProductForServiceEditingQuery,
	M_ProductServiceAndPricesSaveDocument,
	M_ProductCategoriesForServicesDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	C_TaxCategoryDB,
	DBFilter,
	PriceListVersionDB,
	ProductCategoryDB,
	ProductCategoryType,
	referenceListUuids,
} from '../../models';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';

type ServiceFormProps = EntityFormProperties;

type ServiceFormFields = {
	UU: string;
	BH_SellPrice: number | null;
	Description: string | null;
	IsActive: boolean;
	Name: string;
	submitEvent: 'saveAndNew' | '';
};

const getTitle = (uuid?: string) => (uuid ? uiText.service.title.UPDATE : uiText.service.title.NEW);
const convertToFormFields: (initialData?: M_ProductForServiceEditingQuery['M_Product']) => ServiceFormFields = (
	initialData,
) => {
	if (!initialData) {
		return {
			UU: v4(),
			BH_SellPrice: null,
			Description: null,
			IsActive: true,
			Name: '',
			submitEvent: '',
		};
	}
	return {
		UU: initialData.UU,
		BH_SellPrice: initialData.BH_SellPrice || null,
		Description: initialData.Description || null,
		IsActive: initialData.IsActive,
		Name: initialData.Name,
		submitEvent: '',
	};
};

const ServiceForm = ({ uuid, onFinish, renderAsModal, canSaveMany }: ServiceFormProps) => {
	const graphqlClient = useApolloClient();
	const {
		data: [
			data,
			defaultTaxCategory,
			productCategories,
			defaultUnitOfMeasure,
			salesPriceListVersion,
			purchasePriceListVersion,
		] = [],
	} = useSuspenseAsync(uuid || 'add-service', async () =>
		Promise.all([
			uuid
				? graphqlClient
						.query({
							query: M_ProductForServiceEditingDocument,
							variables: { UU: uuid },
							fetchPolicy: 'network-only',
						})
						.then((response) => response.data.M_Product)
				: undefined,
			graphqlClient
				.query({
					query: C_TaxCategoryForProductsDocument,
					variables: { Size: 1, Filter: DBFilter<C_TaxCategoryDB>().property('isdefault').equals(true).toString() },
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.C_TaxCategoryGet.Results[0]),
			graphqlClient
				.query({
					query: M_ProductCategoriesForServicesDocument,
					variables: {
						Filter: DBFilter<ProductCategoryDB>()
							.property('bh_product_category_type')
							.equals(ProductCategoryType.VALUE_SERVICE)
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_Product_CategoryGet.Results[0]),
			graphqlClient
				.query({
					query: C_UomGetDefaultDocument,
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.C_UOMGetDefault),
			graphqlClient
				.query({
					query: M_PriceList_VersionGetForServicesDocument,
					variables: {
						Size: 1,
						Sort: JSON.stringify([['validfrom', 'desc']]),
						Filter: DBFilter<PriceListVersionDB>()
							.property('validfrom')
							.isLessThanOrEqualTo(startOfDay(new Date()))
							.nested('m_pricelist')
							.property('issopricelist')
							.equals(true)
							.property('isdefault')
							.equals(true)
							.property('isactive')
							.equals(true)
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_PriceList_VersionGet.Results[0]),
			graphqlClient
				.query({
					query: M_PriceList_VersionGetForServicesDocument,
					variables: {
						Size: 1,
						Sort: JSON.stringify([['validfrom', 'desc']]),
						Filter: DBFilter<PriceListVersionDB>()
							.property('validfrom')
							.isLessThanOrEqualTo(startOfDay(new Date()))
							.nested('m_pricelist')
							.property('issopricelist')
							.equals(false)
							.property('isdefault')
							.equals(true)
							.property('isactive')
							.equals(true)
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_PriceList_VersionGet.Results[0]),
		]),
	);

	const { t } = useTranslation();
	const { disableWrite, disableDeactivate } = useActionPrivileges(pageUuid.SERVICES);
	const { toggleNavBlocking } = useContext(NavBlockingContext);

	const [dataToUse, setDataToUse] = useState(data);
	const title = getTitle(dataToUse ? dataToUse.UU : undefined);

	const {
		register,
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { errors, isDirty },
	} = useForm<ServiceFormFields>({ defaultValues: convertToFormFields(dataToUse) });

	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	const onSubmit: SubmitHandler<ServiceFormFields> = async (formData) => {
		try {
			const savedServiceResult = await graphqlClient.mutate({
				mutation: M_ProductServiceAndPricesSaveDocument,
				variables: {
					M_Product: {
						UU: formData.UU,
						BH_SellPrice: formData.BH_SellPrice || 0,
						C_TaxCategory: defaultTaxCategory?.UU ? { UU: defaultTaxCategory.UU } : undefined,
						C_UOM: defaultUnitOfMeasure?.UU ? { UU: defaultUnitOfMeasure.UU } : undefined,
						Description: formData.Description || null,
						IsActive: formData.IsActive,
						M_Product_Category: productCategories?.UU ? { UU: productCategories.UU } : undefined,
						Name: formData.Name,
						ProductType: { UU: referenceListUuids.productType.SERVICE },
					},
					M_ProductPrices: [
						{
							UU:
								dataToUse?.M_ProductPrices?.find(
									(productPrice) => productPrice.M_PriceList_Version.UU === purchasePriceListVersion?.UU,
								)?.UU || v4(),
							M_PriceList_Version: { UU: purchasePriceListVersion?.UU! },
							M_Product: { UU: formData.UU },
							PriceLimit: 0,
							PriceList: 0,
							PriceStd: 0,
						},
						{
							UU:
								dataToUse?.M_ProductPrices?.find(
									(productPrice) => productPrice.M_PriceList_Version.UU === salesPriceListVersion?.UU,
								)?.UU || v4(),
							M_PriceList_Version: { UU: salesPriceListVersion?.UU! },
							M_Product: { UU: formData.UU },
							PriceLimit: formData.BH_SellPrice || 0,
							PriceList: formData.BH_SellPrice || 0,
							PriceStd: formData.BH_SellPrice || 0,
						},
					],
				},
			});
			if (!savedServiceResult.data) {
				throw savedServiceResult.errors;
			}
			toast.success(t(uiText.service.save.SUCCESS));
			toggleNavBlocking(false);
			const shouldResetForm = formData.submitEvent === 'saveAndNew';
			if (shouldResetForm) {
				const newService = convertToFormFields();
				reset(newService);
				setDataToUse(undefined);
				dataWasSaved(savedServiceResult.data.M_ProductSave.UU);
			} else {
				onFinish(true, savedServiceResult.data.M_ProductSave.UU);
			}
		} catch (error) {
			console.error(t(uiText.service.error.SAVE_UPDATE, { error }));
		}
	};

	useConfirmRefresh(isDirty);

	const inputs = (
		<Form onSubmit={handleSubmit(onSubmit)} className="px-0">
			<fieldset disabled={disableWrite}>
				<Form.Group as={Row} className="mb-3" controlId="Name">
					<Form.Label column xs={1} className="d-flex align-items-center">
						{t(uiText.service.name.LABEL)}
					</Form.Label>
					<Col xs={8} className="d-flex align-items-center">
						<Form.Control placeholder={t(uiText.service.name.PLACEHOLDER)} {...register('Name', { required: true })} />
						{errors.Name && <span className="text-danger">{t(uiText.service.name.validation.REQUIRED)}</span>}
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3" controlId="BH_SellPrice">
					<Form.Label column xs={1} className="d-flex align-items-center">
						{t(uiText.service.sellingPrice.LABEL)}
					</Form.Label>
					<Col xs={8} className="d-flex align-items-center">
						<Controller
							control={control}
							name="BH_SellPrice"
							render={({ field }) => (
								<FormatNumberInput placeholder={t(uiText.service.sellingPrice.PLACEHOLDER)} {...field} />
							)}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3" controlId="Description">
					<Form.Label column xs={1} className="d-flex align-items-center">
						{t(uiText.service.description.LABEL)}
					</Form.Label>
					<Col xs={8} className="d-flex align-items-center">
						<Form.Control
							as="textarea"
							rows={3}
							placeholder={t(uiText.service.description.PLACEHOLDER)}
							{...register('Description')}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3" controlId="IsActive">
					<Col sm={{ span: 8, offset: 1 }} className="d-flex align-items-center">
						<Form.Check
							disabled={disableDeactivate}
							{...register('IsActive')}
							label={t(uiText.service.button.ACTIVE)}
						/>
					</Col>
				</Form.Group>
			</fieldset>
		</Form>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'mb-3 mt-2'}`}>
			{disableWrite ? (
				<Col xs="auto">
					<BasicButton
						name={uiText.product.button.BACK}
						text={t(uiText.product.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto" className="me-auto">
						<BasicButton
							type="button"
							variant="danger"
							name={uiText.supplier.button.CANCEL}
							text={t(uiText.supplier.button.CANCEL)}
							active={true}
							icon="times"
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						/>
					</Col>
					<Col xs="auto">
						<input type="hidden" {...register('submitEvent')} defaultValue={''} />
						{canSaveMany === false ? (
							<BasicButton
								type="button"
								variant="success"
								name={uiText.service.button.SAVE_AND_CLOSE}
								text={t(uiText.service.button.SAVE_AND_CLOSE)}
								active
								icon="save"
								onClick={(e) => {
									setValue('submitEvent', 'saveAndNew');
									handleSubmit(onSubmit)(e);
								}}
							/>
						) : (
							<BHDropdownButton title={t(uiText.service.button.SAVE)} variant="success" icon="check">
								<Dropdown.Item onClick={handleSubmit(onSubmit)}>
									<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
									{t(uiText.service.button.SAVE_AND_CLOSE)}
								</Dropdown.Item>
								<Dropdown.Item
									onClick={(e) => {
										setValue('submitEvent', 'saveAndNew');
										handleSubmit(onSubmit)(e);
									}}
								>
									<FontAwesomeIcon icon="paste" className="me-2 fa-fw" />
									{t(uiText.service.button.SAVE_AND_NEW_SERVICE)}
								</Dropdown.Item>
							</BHDropdownButton>
						)}
					</Col>
					{!renderAsModal && <Col xs={3} />}
				</>
			)}
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="me-n2_5 pb-0_5 bg-white">
					<Card className="bh-card">
						<Card.Body>
							{inputs}
							{buttons}
						</Card.Body>
					</Card>
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<ServiceFormProps>({
	loadingLabel: uiText.service.loading.LOADING,
	getTitle,
})(ServiceForm);
