import { sortBy } from 'lodash';
import { Form } from 'react-bootstrap';
import { UseFormRegisterReturn } from 'react-hook-form';
import { REFERENCE_LIST_UUID_DATA_TYPE_TEXT } from '../../models/ReferenceList';

type PayerInformationInputProps = {
	id?: string;
	payerInformationField: {
		BH_Payer_Info_Fld_ValList?: Array<{ UU: string; Line: number; Name: string }> | null;
		BH_PayerInfoFieldDataType: { UU: string | null };
	};
	label?: string;
	inputPlaceholder: string;
	selectPlaceholder: string;
	registerReturn: UseFormRegisterReturn;
};

function PayerInformationInput({
	id,
	payerInformationField,
	label,
	inputPlaceholder,
	selectPlaceholder,
	registerReturn,
}: PayerInformationInputProps) {
	return !payerInformationField.BH_PayerInfoFieldDataType.UU ||
		payerInformationField.BH_PayerInfoFieldDataType.UU === REFERENCE_LIST_UUID_DATA_TYPE_TEXT ? (
		<Form.Control
			id={id}
			aria-label={label}
			className="keep-border"
			placeholder={inputPlaceholder}
			{...registerReturn}
		/>
	) : (
		<Form.Select id={id} aria-label={label} className="keep-border" {...registerReturn}>
			<option value="">{selectPlaceholder}</option>
			{sortBy(payerInformationField.BH_Payer_Info_Fld_ValList || [], 'Line').map(
				(businessPartnerPayerInformationFieldValue) => (
					<option
						key={businessPartnerPayerInformationFieldValue.UU}
						value={businessPartnerPayerInformationFieldValue.Name}
					>
						{businessPartnerPayerInformationFieldValue.Name}
					</option>
				),
			)}
		</Form.Select>
	);
}

export default PayerInformationInput;
